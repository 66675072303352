var productName = 'scheduler';import Base from '../../Common/Base.js';
import TemplateHelper from '../../Common/helper/TemplateHelper.js';
import DomHelper from '../../Common/helper/DomHelper.js';

let tpl = TemplateHelper.tpl;

/**
 * @module Scheduler/view/HorizontalTimeAxis
 */

/**
 * A visual representation of the time axis described in the {@link Scheduler.preset.ViewPreset#config-headerConfig headerConfig}.
 * Normally you should not interact with this class directly.
 *
 * @mixes Common/mixin/Events
 * @private
 */
export default class HorizontalTimeAxis extends Base {
    //region Config

    static get defaultConfig() {
        return {
            /**
             * The minimum width for a bottom row header cell to be considered 'compact', which adds a special CSS class
             * to the row (for special styling).
             * @config {Number}
             * @default
             */
            compactCellWidthThreshold : 15
        };
    }

    //endregion

    //region Init

    headerHtmlRowTpl(data) {
        return tpl`${data.levels.map(level => tpl`<div class="b-sch-header-row b-sch-header-row-${level.position}" headerPosition="${level.position}">
                ${level.cells.map((cell, i) => `<div class="b-sch-header-timeaxis-cell ${cell.headerCellCls}  ${cell.align ? ('b-align-' + cell.align) : ''}">${cell.value}</div>
                `)}
            </div>`)}`;
    }

    //Needed to be CSP compliant
    fixHtmlHeaderStyles(element, levels) {
        levels.forEach(level => {
            const nodes = DomHelper.children(element, '[headerPosition=' + level.position + '] .b-sch-header-timeaxis-cell');

            nodes.forEach((node, index) => {
                node.style.width = node.style.minWidth = `${level.cells[index].width}px`;
            });
        });
    }

    //endregion

    //region Html & rendering

    // Produces the tables and cells based on the header row config in the active viewPreset
    renderTo(element) {
        const me           = this,
            columnConfig = me.model.columnConfig,
            levels       = [];

        if (columnConfig.top) {
            levels.push({
                position : 'top',
                cells    : columnConfig.top
            });
        }

        if (columnConfig.middle) {
            levels.push({
                position : 'middle',
                cells    : columnConfig.middle,
                cls      : !columnConfig.bottom && me.model.tickSize <= me.compactCellWidthThreshold ? 'b-sch-header-row-compact' : ''
            });
        }

        if (columnConfig.bottom) {
            levels.push({
                position : 'bottom',
                cells    : columnConfig.bottom,
                cls      : me.model.tickSize <= me.compactCellWidthThreshold ? 'b-sch-header-row-compact' : ''
            });
        }

        element.innerHTML = me.headerHtmlRowTpl({ levels });

        // TODO clean up when this is fixed: https://app.assembla.com/spaces/bryntum/tickets/8413-horizontaltimeaxis-should-not-completely-overwrite-contents-of-column-el/details#
        const parentEl = element.parentElement;

        [1, 2, 3].forEach(i => parentEl.classList.remove(`b-sch-timeaxiscolumn-levels-${i}`));

        parentEl.classList.add(`b-sch-timeaxiscolumn-levels-${levels.length}`);

        me.fixHtmlHeaderStyles(element, levels);
    }
    //endregion
}
HorizontalTimeAxis._$name = 'HorizontalTimeAxis';