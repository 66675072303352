var productName = 'scheduler';import Base from '../../../Common/Base.js';
import DateHelper from '../../../Common/helper/DateHelper.js';
import PresetManager from '../../preset/PresetManager.js';
import EventHelper from '../../../Common/helper/EventHelper.js';

/**
 * @module Scheduler/view/mixin/TimelineZoomable
 */

/**
 * Mixin providing "zooming" functionality.
 *
 * The zooming feature works by reconfiguring the time axis with the current zoom level values selected from the {@link #config-zoomLevels} array.
 * Zoom levels can be added and removed from the array to change the amount of available steps. Range of zooming in/out can be also
 * modified with {@link #config-maxZoomLevel} / {@link #config-minZoomLevel} properties.
 *
 * This mixin adds additional methods to the column : {@link #property-maxZoomLevel}, {@link #property-minZoomLevel}, {@link #function-zoomToLevel}, {@link #function-zoomIn},
 * {@link #function-zoomOut}, {@link #function-zoomInFull}, {@link #function-zoomOutFull}.
 *
 * **Notice**: Zooming doesn't work properly when `forceFit` option is set to true for the column or for filtered timeaxis.
 *
 * @mixin
 */
export default Target => class TimelineZoomable extends (Target || Base) {
    static get defaultConfig() {
        return {
            /**
             * If true, you can zoom in and out on the the time axis using CTRL-key + mouse wheel.
             * @config {Boolean}
             * @default
             * @category Zoom
             */
            zoomOnMouseWheel : true,

            /**
             * True to zoom to time span when double clicking a time axis cell.
             * @config {Boolean}
             * @category Zoom
             */
            zoomOnTimeAxisDoubleClick : true,

            preventScrollZoom : false,

            /**
             * Predefined map of zoom levels for each preset in the ascending order. Zoom level is basically a
             * {@link Scheduler.preset.ViewPreset view preset}, which is based on another preset, with some values
             * overridden.
             *
             * Each element is an {Object} with the following parameters :
             *
             * - `preset` (String)      - {@link Scheduler.preset.ViewPreset} to be used for this zoom level. This must be a valid preset name registered in {@link Scheduler.preset.PresetManager preset manager}.
             * - `width` (Int)          - {@link Scheduler.preset.ViewPreset#config-tickWidth tickWidth} time column width value from the preset
             * - `increment` (Int)      - {@link Scheduler.preset.ViewPresetHeaderRow#config-increment increment} value from the bottom header row of the preset
             * - `resolution` (Int)     - {@link Scheduler.preset.ViewPreset#config-timeResolution increment} part of the `timeResolution` object in the preset
             * - `resolutionUnit` (String) (Optional) - {@link Scheduler.preset.ViewPreset#config-timeResolution unit} part of the `timeResolution` object in the preset
             *
             *  The `zoomLevels` config can be set in the scheduler like this:
             * ```javascript
             * let scheduler = new Scheduler({
             *   resourceStore : resourceStore,
             *   eventStore    : eventStore,
             *   viewPreset    : 'hourAndDay',
             *   zoomLevels: [
             *       { width: 50,    increment: 4,   resolution: 60, preset: 'hourAndDay', resolutionUnit: 'minute' },
             *       { width: 60,    increment: 3,   resolution: 60, preset: 'hourAndDay', resolutionUnit: 'minute' },
             *       { width: 80,    increment: 2,   resolution: 30, preset: 'hourAndDay', resolutionUnit: 'minute' },
             *       { width: 100,   increment: 1,   resolution: 15, preset: 'hourAndDay', resolutionUnit: 'minute' }
             *   ]
             * });
             * ```
             * In the case above:
             *
             * - The `width` specifies the amount of space in pixels for the bottom cell.
             * - The `increment` specifies the number of hours between each bottom cell.
             * - The `resolution` specifies the size of the slots in the bottom cell accordingly to the defined `resolutionUnit`.
             *
             * In the case above we have four zoomlevel steps within the `hourAndDay` preset. When zooming in we go up
             * in the zoomlevel array, when zooming out we go down in the zoomlevel array. In this case the zoomlevel
             * with `increment` set to 1 and `width` set to 100 is the most detailed level, the max level. While the
             * first item in the array is the minimal zoomlevel. In a higher zoomlevel the `resolution` can be set lower
             * to make the granularity of the cell smaller. That means smaller slots for the events to fit in.
             * @config {Object[]}
             * @category Zoom
             */
            zoomLevels : [
                //YEAR
                { width : 40, increment : 1, resolution : 1, preset : 'manyYears', resolutionUnit : 'YEAR' },
                { width : 80, increment : 1, resolution : 1, preset : 'manyYears', resolutionUnit : 'YEAR' },

                { width : 30, increment : 1, resolution : 1, preset : 'year', resolutionUnit : 'MONTH' },
                { width : 50, increment : 1, resolution : 1, preset : 'year', resolutionUnit : 'MONTH' },
                { width : 100, increment : 1, resolution : 1, preset : 'year', resolutionUnit : 'MONTH' },
                { width : 200, increment : 1, resolution : 1, preset : 'year', resolutionUnit : 'MONTH' },

                //MONTH
                { width : 100, increment : 1, resolution : 7, preset : 'monthAndYear', resolutionUnit : 'DAY' },
                { width : 30, increment : 1, resolution : 1, preset : 'weekDateAndMonth', resolutionUnit : 'DAY' },

                //WEEK
                { width : 50, increment : 1, resolution : 1, preset : 'weekAndMonth', resolutionUnit : 'DAY' },
                { width : 20, increment : 1, resolution : 1, preset : 'weekAndDayLetter' },

                //DAY
                { width : 54, increment : 1, resolution : 1, preset : 'weekAndDay', resolutionUnit : 'HOUR' },
                { width : 100, increment : 1, resolution : 1, preset : 'weekAndDay', resolutionUnit : 'HOUR' },

                //HOUR
                { width : 64, increment : 6, resolution : 30, preset : 'hourAndDay', resolutionUnit : 'MINUTE' },
                { width : 100, increment : 6, resolution : 30, preset : 'hourAndDay', resolutionUnit : 'MINUTE' },
                { width : 64, increment : 2, resolution : 30, preset : 'hourAndDay', resolutionUnit : 'MINUTE' },
                { width : 64, increment : 1, resolution : 30, preset : 'hourAndDay', resolutionUnit : 'MINUTE' },

                //MINUTE
                { width : 30, increment : 15, resolution : 5, preset : 'minuteAndHour' },
                { width : 60, increment : 15, resolution : 5, preset : 'minuteAndHour' },
                { width : 130, increment : 15, resolution : 5, preset : 'minuteAndHour' },
                { width : 60, increment : 5, resolution : 5, preset : 'minuteAndHour' },
                { width : 100, increment : 5, resolution : 5, preset : 'minuteAndHour' },

                //SECOND
                { width : 30, increment : 10, resolution : 5, preset : 'secondAndMinute' },
                { width : 60, increment : 10, resolution : 5, preset : 'secondAndMinute' },
                { width : 130, increment : 5, resolution : 5, preset : 'secondAndMinute' }
            ],

            /**
             * Minimal zoom level to which {@link #function-zoomOut} will work
             * @config {Number}
             * @category Zoom
             */
            minZoomLevel : true,

            /**
             * Maximal zoom level to which {@link #function-zoomIn} will work
             * @config {Number}
             * @category Zoom
             */
            maxZoomLevel : true,

            /**
             * Integer number indicating the size of timespan during zooming. When zooming, the timespan is adjusted to make the scrolling area `visibleZoomFactor` times
             * wider than the timeline area itself. Used in {@link #function-zoomToSpan} and {@link #function-zoomToLevel} functions.
             * @config {Number}
             * @default
             * @category Zoom
             */
            visibleZoomFactor : 5,

            /**
             * Whether the originally rendered timespan should be preserved while zooming. By default it is set to `false`,
             * meaning the timeline panel will adjust the currently rendered timespan to limit the amount of HTML content to render. When setting this option
             * to `true`, be careful not to allow to zoom a big timespan in seconds resolution for example. That will cause **a lot** of HTML content
             * to be rendered and affect performance. You can use {@link #config-minZoomLevel} and {@link #config-maxZoomLevel} config options for that.
             * @config {Boolean}
             * @default
             * @category Zoom
             */
            zoomKeepsOriginalTimespan : false
        };
    }

    construct(config) {
        const me = this;

        super.construct(config);

        if (me.zoomOnMouseWheel) {
            EventHelper.on({
                element : me.timeAxisSubGridElement,
                wheel   : 'onWheel',
                thisObj : me,
                capture : true,
                passive : false
            });
        }

        if (me.zoomOnTimeAxisDoubleClick) {
            me.on('timeaxisheaderdblclick', ({ startDate, endDate }) => {
                if (!me.isVertical) {
                    me.zoomToSpan({
                        startDate,
                        endDate
                    });
                }
            });
        }
    }

    getZoomLevelUnit(zoomLevel) {
        return PresetManager.getPreset(zoomLevel.preset).bottomHeader.unit;
    }

    get maxZoomLevel() {
        return this._maxZoomLevel;
    }

    /**
     * Get/set the {@link #config-maxZoomLevel} value
     * @property {Number}
     * @category Zoom
     */
    set maxZoomLevel(level) {
        if (typeof level !== 'number') {
            level = this.zoomLevels.length - 1;
        }

        if (level < 0 || level >= this.zoomLevels.length) {
            throw new Error('Invalid range for `setMinZoomLevel`');
        }

        this._maxZoomLevel = level;
    }

    get minZoomLevel() {
        return this._minZoomLevel;
    }

    /**
     * Sets the {@link #config-minZoomLevel} value
     * @property {Number}
     * @category Zoom
     */
    set minZoomLevel(level) {
        if (typeof level !== 'number') {
            level = 0;
        }

        if (level < 0 || level >= this.zoomLevels.length) {
            throw new Error('Invalid range for `minZoomLevel`');
        }

        this._minZoomLevel = level;
    }

    presetToZoomLevel(presetName) {
        const preset = PresetManager.getPreset(presetName);

        return {
            preset         : presetName,
            increment      : preset.bottomHeader.increment || 1,
            resolution     : preset.timeResolution.increment,
            resolutionUnit : preset.timeResolution.unit,
            width          : preset.tickWidth
        };
    }

    calculateCurrentZoomLevel() {
        let me          = this,
            zoomLevel   = me.presetToZoomLevel(me.viewPreset.name),
            min         = Number.MAX_VALUE,
            viewModel   = me.timeAxisViewModel,
            actualWidth = viewModel.tickSize;

        zoomLevel.width = actualWidth;
        zoomLevel.increment = viewModel.bottomHeader.increment || 1;

        // when calculating current zoom level we should use tick width from defined zoomLevels
        // otherwise levels might be skipped
        for (let i = 0, l = me.zoomLevels.length; i < l; i++) {
            const curentLevel = me.zoomLevels[i];

            // search for a zoom level having the same preset...
            if (curentLevel.preset !== zoomLevel.preset) continue;

            // and the most close column width to the actual one
            const delta = Math.abs(curentLevel.width - actualWidth);
            if (delta < min) {
                min = delta;
                zoomLevel.actualWidth = curentLevel.actualWidth;
                zoomLevel.width = curentLevel.width;
            }
        }

        return zoomLevel;
    }

    /**
     * Get/set current zoom level
     * @property {Number}
     * @category Zoom
     */
    get zoomLevel() {
        const me               = this,
            currentZoomLevel = me.calculateCurrentZoomLevel(),
            currentFactor    = me.getMilliSecondsPerPixelForZoomLevel(currentZoomLevel),
            zoomLevels       = me.zoomLevels;

        for (let i = 0; i < zoomLevels.length; i++) {
            const zoomLevelFactor = me.getMilliSecondsPerPixelForZoomLevel(zoomLevels[i]);

            if (zoomLevelFactor === currentFactor) return i;

            // current zoom level is outside of pre-defined zoom levels
            if (i === 0 && currentFactor > zoomLevelFactor) return -0.5;
            if (i === zoomLevels.length - 1 && currentFactor < zoomLevelFactor) return zoomLevels.length - 1 + 0.5;

            const nextLevelFactor = me.getMilliSecondsPerPixelForZoomLevel(zoomLevels[i + 1]);

            if (zoomLevelFactor > currentFactor && currentFactor > nextLevelFactor) return i + 0.5;
        }

        throw new Error("Can't find current zoom level index");
    }

    // noinspection JSAnnotator
    set zoomLevel(level) {
        this.zoomToLevel(level);
    }

    /*
     * @private
     * Returns number of milliseconds per pixel.
     * @param {Object} level Element from array of {@link #config-zoomLevels}.
     * @param {Boolean} ignoreActualWidth If true, then density will be calculated using default zoom level settings.
     * Otherwise density will be calculated for actual tick width.
     * @return {Number} Return number of milliseconds per pixel.
     */
    getMilliSecondsPerPixelForZoomLevel(level, ignoreActualWidth) {
        // trying to convert the unit + increment to a number of milliseconds
        // this number is not fixed (month can be 28, 30 or 31 day), but at least this conversion
        // will be consistent (should be no DST changes at year 1)
        return Math.round(
            (DateHelper.add(new Date(1, 0, 1), level.increment, this.getZoomLevelUnit(level)) - new Date(1, 0, 1)) /
            // `actualWidth` is a column width after view adjustments applied to it (see `calculateTickWidth`)
            // we use it if available to return the precise index value from `getCurrentZoomLevelIndex`
            (ignoreActualWidth ? level.width : level.actualWidth || level.width)
        );
    }

    /**
     * Zooms to passed view preset, saving center date. Method accepts config object as a first argument, which can be
     * reduced to primitive type (string,number) when no additional options required. e.g.:
     * ```
     * // zooming to preset
     * scheduler.zoomTo({ preset : 'hourAndDay' })
     * // shorthand
     * scheduler.zoomTo('hourAndDay')
     *
     * // zooming to level
     * scheduler.zoomTo({ level : 0 })
     * // shorthand
     * scheduler.zoomTo(0)
     * ```
     *
     * It is also possible to zoom to a time span by omitting `preset` and `level` configs, in which case scheduler sets
     * the time frame to a specified range and applies zoom level which allows to fit all columns to this range. The
     * given time span will be centered in the scheduling view (unless `centerDate` config provided). In the same time,
     * the start/end date of the whole time axis will be extended to allow scrolling for user.
     * ```
     * // zooming to time span
     * scheduler.zoomTo({ startDate : new Date(..), endDate : new Date(...) })
     *
     * ```
     *
     * @param {Object|String|Number} config Config object, preset name or zoom level index.
     * @param {String} config.preset Preset name to zoom to. Ignores level config in this case
     * @param {Number} config.level Zoom level to zoom to. Is ignored, if preset config is provided
     * @param {Date} config.startDate New time frame start. If provided along with end, view will be centered in this time
     * interval (unless `centerDate` is present)
     * @param {Date} config.endDate New time frame end
     * @param {Date} config.centerDate Date that should be kept in the center. Has priority over start and end params
     * @param {Number} config.width Lowest tick width. Might be increased automatically
     * @param {Number} [config.leftMargin] Amount of pixels to extend span start on (used, when zooming to span)
     * @param {Number} [config.rightMargin] Amount of pixels to extend span end on (used, when zooming to span)
     * @param {Number} [config.adjustStart] Amount of units to extend span start on (used, when zooming to span)
     * @param {Number} [config.adjustEnd] Amount of units to extend span end on (used, when zooming to span)
     * @category Zoom
     */
    zoomTo(config) {
        const me = this;

        if (typeof config === 'object') {
            if (config.preset) {
                const zoomLevel = me.presetToZoomLevel(config.preset);
                this.internalZoomToLevel(zoomLevel, config);
            }
            else if (config.level != null) {
                me.zoomToLevel(config.level, config);
            }
            else {
                me.zoomToSpan(config);
            }
        }
        else if (typeof config === 'number') {
            me.zoomToLevel(config);
        }
        else {
            const zoomLevel = me.presetToZoomLevel(config);
            this.internalZoomToLevel(zoomLevel);
        }
    }

    /**
     * Allows zooming to certain level of {@link #config-zoomLevels} array. Automatically limits zooming between {@link #config-maxZoomLevel}
     * and {@link #config-minZoomLevel}. Can also set time axis timespan to the supplied start and end dates.
     *
     * @param {Number} level Level to zoom to.
     * @param {Object} [options] Object, containing options for this method
     * @param {Date} options.startDate New time frame start. If provided along with end, view will be centered in this time
     * interval, ignoring centerDate config.
     * @param {Date} options.endDate New time frame end.
     * @param {Date} options.centerDate Date that should be kept in center. Is ignored when start and end are provided.
     * @param {Number} options.width Lowest tick width. Might be increased automatically
     * @return {Number} level Current zoom level or null if it hasn't changed.
     * @category Zoom
     * @internal
     */
    zoomToLevel(level, options = {}) {
        const me = this;

        level = Math.min(Math.max(level, me.minZoomLevel), me.maxZoomLevel);

        const
            currentZoomLevel = me.calculateCurrentZoomLevel(),
            currentFactor    = me.getMilliSecondsPerPixelForZoomLevel(currentZoomLevel),
            nextZoomLevel    = me.zoomLevels[level],
            nextFactor       = me.getMilliSecondsPerPixelForZoomLevel(nextZoomLevel);

        if (currentFactor === nextFactor && !(options.startDate || options.endDate)) {
            // already at requested zoom level
            return null;
        }

        me.internalZoomToLevel(nextZoomLevel, options);

        return level;
    }

    /**
     * @param {Object} level Zoom level configuration
     * @param {String} level.preset View preset to zoom to
     * @param {Number} [level.width] Tick width for preset
     * @param {Number} [level.increment] Preset increment
     * @param {String} [level.resolutionUnit] Preset resolution unit
     * @param {Number} [level.resolution] Preset resolution unit increment
     * @param {Object} [options] Additional options
     * @param {Date} options.startDate New time frame start. If provided along with end, view will be centered in this time
     * interval (unless centerDate is present)
     * @param {Date} options.endDate New time frame end.
     * @param {Date} options.centerDate Date that should be kept in center. Has priority over start and end params.
     * @param {Number} options.width Lowest tick width. Might be increased automatically
     * @private
     */
    internalZoomToLevel(level, options = {}) {
        const me = this;

        // this event is used to prevent sync suspend during zooming
        me.trigger('beforeZoomChange', { level });

        let isVertical   = me.isVertical,
            startDate    = options.startDate,
            endDate      = options.endDate,
            span         = startDate && endDate ? { startDate, endDate } : null,
            centerDate   = options.centerDate || (span ? new Date((startDate.getTime() + endDate.getTime()) / 2) : me.viewportCenterDateCached),
            // // eslint-disable-next-line no-undef
            panelSize    = me.timeAxisSubGrid.width,
            presetCopy   = PresetManager.getPreset(level.preset).clone(),
            bottomHeader = presetCopy.bottomHeader;

        span = span || me.calculateOptimalDateRange(centerDate, panelSize, level);

        // clone doesn't copy the preset name
        presetCopy.name = level.preset;

        presetCopy[isVertical ? 'tickHeight' : 'tickWidth'] = options.width || level.width;

        bottomHeader.increment = level.increment;

        me.isZooming = true;

        presetCopy.increment = level.increment;
        presetCopy.timeResolution.unit = DateHelper.getUnitByName(level.resolutionUnit || presetCopy.timeResolution.unit || bottomHeader.unit);
        presetCopy.timeResolution.increment = level.resolution;

        me.setViewPreset(presetCopy, span.startDate || me.startDate, span.endDate || me.endDate, false, { centerDate : centerDate });

        // after switching the view preset the `width` config of the zoom level may change, because of adjustments
        // we will save the real value in the `actualWidth` property, so that `getCurrentZoomLevelIndex` method
        // will return the exact level index after zooming
        level.actualWidth = me.timeAxisViewModel.tickSize;

        me.isZooming = false;

        /**
         * Fires after zoom level has been changed
         * @event zoomchange
         * @param {Scheduler.column.TimeAxisColumn} column The TimeAxisColumn object
         * @param {Number} level The index of the new zoom level
         */
        me.trigger('zoomChange', { level });
    }

    /**
     * Changes the range of the scheduling chart to fit all the events in its event store.
     * @param {Object} [options] Options object for the zooming operation.
     * @param {Number} [options.leftMargin] Defines margin in pixel between the first event start date and first visible date
     * @param {Number} [options.rightMargin] Defines margin in pixel between the last event end date and last visible date
     */
    zoomToFit(options) {
        const eventStore = this.eventStore,
            span       = eventStore.getTotalTimeSpan();

        options = Object.assign({
            leftMargin  : 0,
            rightMargin : 0
        }, options, span);

        // Make sure we received a time span, event store might be empty
        if (options.startDate && options.endDate) {
            this.zoomToSpan(options);
        }
    }

    /**
     * Sets time frame to specified range and applies zoom level which allows to fit all columns to this range.
     *
     * The given time span will be centered in the scheduling view, in the same time, the start/end date of the whole time axis
     * will be extended in the same way as {@link #function-zoomToLevel} method does, to allow scrolling for user.
     *
     * @param {Object} config The time frame.
     * @param {Date} config.startDate The time frame start.
     * @param {Date} config.endDate The time frame end.
     * @param {Date} [config.centerDate] Date that should be kept in the center. Has priority over start and end params
     * @param {Number} [config.leftMargin] Amount of pixels to extend span start on
     * @param {Number} [config.rightMargin] Amount of pixels to extend span end on
     * @param {Number} [config.adjustStart] Amount of units to extend span start on
     * @param {Number} [config.adjustEnd] Amount of units to extend span end on
     *
     * @return {Number} level Current zoom level or null if it hasn't changed.
     * @category Zoom
     * @internal
     */
    zoomToSpan(config = {}) {
        if (config.leftMargin || config.rightMargin) {
            config.adjustStart = 0;
            config.adjustEnd = 0;
        }

        if (!config.leftMargin) config.leftMargin = 0;
        if (!config.rightMargin) config.rightMargin = 0;

        if (!config.startDate || !config.endDate) throw new Error('zoomToSpan: must provide startDate + endDate dates');

        let me           = this,
            startDate    = config.startDate,
            endDate      = config.endDate,
            // this config enables old zoomToSpan behavior which we want to use for zoomToFit in Gantt
            needToAdjust = config.adjustStart >= 0 || config.adjustEnd >= 0;

        if (needToAdjust) {
            startDate = DateHelper.add(startDate, -config.adjustStart, me.timeAxis.mainUnit);
            endDate   = DateHelper.add(endDate, config.adjustEnd, me.timeAxis.mainUnit);
        }

        if (startDate <= endDate) {
            // get scheduling view width
            const { availableSpace } = me.timeAxisViewModel;

            // if potential width of col is less than col width provided by zoom level
            //   - we'll zoom out panel until col width fit into width from zoom level
            // and if width of column is more than width from zoom level
            //   - we'll zoom in until col width fit won't fit into width from zoom level

            let currLevel = Math.floor(me.zoomLevel);

            // if we zoomed out even more than the highest zoom level - limit it to the highest zoom level
            if (currLevel === -1) currLevel = 0;

            const zoomLevels = me.zoomLevels;

            let diffMS                 = endDate - startDate || 1,
                msPerPixel             = me.getMilliSecondsPerPixelForZoomLevel(zoomLevels[currLevel], true),
                // increment to get next zoom level:
                // -1 means that given timespan won't fit the available width in the current zoom level, we need to zoom out,
                // so that more content will "fit" into 1 px
                //
                // +1 mean that given timespan will already fit into available width in the current zoom level, but,
                // perhaps if we'll zoom in a bit more, the fitting will be better
                inc                    = diffMS / msPerPixel + config.leftMargin + config.rightMargin > availableSpace ? -1 : 1,
                candidateLevel         = currLevel + inc,
                zoomLevel, levelToZoom = null;

            // loop over zoom levels
            while (candidateLevel >= 0 && candidateLevel <= zoomLevels.length - 1) {
                // get zoom level
                zoomLevel = zoomLevels[candidateLevel];

                msPerPixel = me.getMilliSecondsPerPixelForZoomLevel(zoomLevel, true);
                const spanWidth = diffMS / msPerPixel + config.leftMargin + config.rightMargin;

                // if zooming out
                if (inc === -1) {
                    // if columns fit into available space, then all is fine, we've found appropriate zoom level
                    if (spanWidth <= availableSpace) {
                        levelToZoom = candidateLevel;
                        // stop searching
                        break;
                    }
                    // if zooming in
                }
                else {
                    // if columns still fits into available space, we need to remember the candidate zoom level as a potential
                    // resulting zoom level, the indication that we've found correct zoom level will be that timespan won't fit
                    // into available view
                    if (spanWidth <= availableSpace) {
                        // if it's not currently active level
                        if (currLevel !== candidateLevel - inc) {
                            // remember this level as applicable
                            levelToZoom = candidateLevel;
                        }
                    }
                    else {
                        // Sanity check to find the following case:
                        // If we're already zoomed in at the appropriate level, but the current zoomLevel is "too small" to fit and had to be expanded,
                        // there is an edge case where we should actually just stop and use the currently selected zoomLevel
                        break;
                    }
                }

                candidateLevel += inc;
            }

            // If we didn't find a large/small enough zoom level, use the lowest/highest level
            levelToZoom = levelToZoom != null ? levelToZoom : candidateLevel - inc;

            zoomLevel = zoomLevels[levelToZoom];

            const unitToZoom = PresetManager.getPreset(zoomLevel.preset).bottomHeader.unit;

            if (config.leftMargin || config.rightMargin) {
                // time axis doesn't yet know about new view preset (zoom level) so it cannot round/ceil date correctly
                startDate = new Date(startDate.getTime() - msPerPixel * config.leftMargin);
                endDate   = new Date(endDate.getTime() + msPerPixel * config.rightMargin);
            }

            const tickCount = DateHelper.getDurationInUnit(startDate, endDate, unitToZoom, true) / zoomLevel.increment;

            if (tickCount === 0) {
                return null;
            }

            let customWidth = Math.floor(availableSpace / tickCount),
                centerDate  = config.centerDate || new Date((startDate.getTime() + endDate.getTime()) / 2),
                range;

            if (needToAdjust) {
                range = {
                    startDate,
                    endDate
                };
            }
            else {
                range = me.calculateOptimalDateRange(centerDate, availableSpace, zoomLevel);
            }

            return me.zoomToLevel(levelToZoom,
                Object.assign(range, {
                    width : customWidth,
                    centerDate
                })
            );
        }

        return null;
    }

    /**
     * Zooms in the timeline according to the array of zoom levels. If the amount of levels to zoom is given, the view will zoom in by this value.
     * Otherwise a value of `1` will be used.
     *
     * @param {Number} levels (optional) amount of levels to zoom in
     *
     * @return {Number} currentLevel New zoom level of the panel or null if level hasn't changed.
     * @category Zoom
     */
    zoomIn(levels = 1) {
        const currentZoomLevelIndex = this.zoomLevel;

        if (currentZoomLevelIndex >= this.zoomLevels.length - 1) return null;

        return this.zoomToLevel(Math.floor(currentZoomLevelIndex) + levels);
    }

    /**
     * Zooms out the timeline according to the array of zoom levels. If the amount of levels to zoom is given, the view will zoom out by this value.
     * Otherwise a value of `1` will be used.
     *
     * @param {Number} levels (optional) amount of levels to zoom out
     *
     * @return {Number} currentLevel New zoom level of the panel or null if level hasn't changed.
     * @category Zoom
     */
    zoomOut(levels = 1) {
        const currentZoomLevelIndex = this.zoomLevel;

        if (currentZoomLevelIndex <= 0) return null;

        return this.zoomToLevel(Math.ceil(currentZoomLevelIndex) - levels);
    }

    /**
     * Zooms in the timeline to the {@link #config-maxZoomLevel} according to the array of zoom levels.
     *
     * @return {Number} currentLevel New zoom level of the panel or null if level hasn't changed.
     * @category Zoom
     */
    zoomInFull() {
        return this.zoomToLevel(this.maxZoomLevel);
    }

    /**
     * Zooms out the timeline to the {@link #config-minZoomLevel} according to the array of zoom levels.
     *
     * @return {Number} currentLevel New zoom level of the panel or null if level hasn't changed.
     * @category Zoom
     */
    zoomOutFull() {
        return this.zoomToLevel(this.minZoomLevel);
    }

    /*
     * Adjusts the timespan of the panel to the new zoom level. Used for performance reasons,
     * as rendering too many columns takes noticeable amount of time so their number is limited.
     * @category Zoom
     * @private
     */
    calculateOptimalDateRange(centerDate, panelSize, zoomLevel, userProvidedSpan) {
        // this line allows us to always use the `calculateOptimalDateRange` method when calculating date range for zooming
        // (even in case when user has provided own interval)
        // other methods may override/hook into `calculateOptimalDateRange` to insert own processing
        // (infinite scrolling feature does)
        if (userProvidedSpan) return userProvidedSpan;

        const timeAxis = this.timeAxis;

        if (this.zoomKeepsOriginalTimespan) {
            return {
                startDate : timeAxis.startDate,
                endDate   : timeAxis.endDate
            };
        }

        const unit       = this.getZoomLevelUnit(zoomLevel),
            difference = Math.ceil(panelSize / zoomLevel.width * zoomLevel.increment * this.visibleZoomFactor / 2),
            startDate  = DateHelper.add(centerDate, -difference, unit),
            endDate    = DateHelper.add(centerDate, difference, unit);

        return {
            startDate : timeAxis.floorDate(startDate, false, unit, zoomLevel.increment),
            endDate   : timeAxis.ceilDate(endDate, false, unit, zoomLevel.increment)
        };
    }

    onWheel(event) {
        const me = this;

        if (event.ctrlKey) {
            event.preventDefault();

            if (!me.preventScrollZoom) {
                if (event.deltaY > 0) {
                    me.zoomOut();
                }
                else if (event.deltaY < 0) {
                    me.zoomIn();
                }
                me.preventScrollZoom = true;
                me.setTimeout(() => me.preventScrollZoom = false, 30);
            }
        }
    }

    /**
     * Changes the time axis timespan to the supplied start and end dates.
     * @param {Date} startDate The new start date
     * @param {Date} endDate The new end date. If not supplied, the {@link Scheduler.preset.ViewPreset#config-defaultSpan} property of the current view preset will be used to calculate the new end date.
     */
    setTimeSpan(startDate, endDate) {
        this.timeAxis.setTimeSpan(startDate, endDate);
    }

    /**
     * Moves the time axis by the passed amount and unit.
     *
     * NOTE: If using a filtered time axis, see {@link Scheduler.data.TimeAxis#function-shift} for more information.
     *
     * @param {Number} amount The number of units to jump
     * @param {String} [unit] The unit (Day, Week etc)
     */
    shift(amount, unit) {
        this.timeAxis.shift(amount, unit);
    }

    /**
     * Moves the time axis forward in time in units specified by the view preset `shiftUnit`, and by the amount specified by the `shiftIncrement`
     * config of the current view preset.
     *
     * NOTE: If using a filtered time axis, see {@link Scheduler.data.TimeAxis#function-shiftNext} for more information.
     *
     * @param {Number} [amount] The number of units to jump forward
     */
    shiftNext(amount) {
        this.timeAxis.shiftNext(amount);
    }

    /**
     * Moves the time axis backward in time in units specified by the view preset `shiftUnit`, and by the amount specified by the `shiftIncrement` config of the current view preset.
     *
     * NOTE: If using a filtered time axis, see {@link Scheduler.data.TimeAxis#function-shiftPrevious} for more information.
     *
     * @param {Number} [amount] The number of units to jump backward
     */
    shiftPrevious(amount) {
        this.timeAxis.shiftPrevious(amount);
    }

    // This does not need a className on Widgets.
    // Each *Class* which doesn't need 'b-' + constructor.name.toLowerCase() automatically adding
    // to the Widget it's mixed in to should implement thus.
    get widgetClass() {}
};
