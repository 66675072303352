var productName = 'scheduler';//TODO: Expand function?
//TODO: Collapse function?
//TODO: Sorting breaks grouping if some groups are collapsed
//TODO: Grouping via context menu doesn't work

import DomDataStore from '../../Common/data/DomDataStore.js';
import DomHelper from '../../Common/helper/DomHelper.js';
import InstancePlugin from '../../Common/mixin/InstancePlugin.js';
import GridFeatureManager from '../feature/GridFeatureManager.js';

/**
 * @module Grid/feature/Group
 */

/**
 * Enables rendering and handling of row groups. The actual grouping is done in the store, but triggered by [shift] +
 * clicking headers or by using two finger tap (one on header, one anywhere on grid). Groups can be expanded/collapsed
 * by clicking on the group row or pressing [space] when group row is selected.
 *
 * This feature is <strong>enabled</strong> by default.
 *
 * @example
 * // grouping turned on but no default grouper
 * let grid = new Grid({
 *   features: {
 *     group: true
 *   }
 * });
 *
 * // default grouper, group by field 'name'
 * let grid = new Grid({
 *   features: {
 *     group: 'name'
 *   }
 * });
 *
 * // default grouper and custom renderer, which will be applied to each cell except the "group" cell
 * let grid = new Grid({
 *   features: {
 *     group: {
 *       field: 'name',
 *       ascending: false,
 *       renderer: () => 'Text'
 *     }
 *   }
 * });
 *
 * @extends Common/mixin/InstancePlugin
 *
 * @demo grouping
 * @classtype group
 * @externalexample feature/Group.js
 */
export default class Group extends InstancePlugin {
    static get defaultConfig() {
        return {
            /**
             * The name of the record field to group by.
             * @config {String}
             * @default
             */
            field : null,

            /**
             * A function which yields the HTML for a group header when passed a data block.
             * The function is called in the context of this Group feature object.
             * The data block passed contains the following properties:
             *  - `groupRowFor` The value of the {@link #config-field} for the group.
             *  - `groupColumn` Optionally, the column under which the {@link #config-field} is shown.
             *  - `record` The group record reresenting the group, containing a `children` property which contains the child records for the group.
             *
             * The default renderer displays the `groupRowFor` and `record.meta.childCount`
             * @config {Function}
             * @default
             */
            renderer : null
        };
    }
    //region Init

    construct(grid, config) {
        const me = this;

        if (grid.features.tree) return;

        // groupSummary feature needs to be initialized first, if it is used
        me._thisIsAUsedExpression(grid.features.groupSummary);

        // process initial config into an actual config object
        config = me.processConfig(config);

        Object.assign(me, {
            grid       : grid,
            rowManager : grid.rowManager,
            store      : grid.store
        });

        super.construct(grid, config);

        me.store.on({
            group       : me.onStoreGroup,
            groupingoff : me.onStoreGroupingOff,
            thisObj     : me
        });

        me.rowManager.on({
            rendercell : me.renderCell,
            thisObj    : me
        });
    }

    // Group feature handles special config cases, where user can supply a string or a group config object
    // instead of a normal config object
    processConfig(config) {
        if (typeof config === 'string') {
            return {
                field     : config,
                ascending : null
            };
        }

        return config;
    }

    // override setConfig to process config before applying it (used mainly from ReactGrid)
    setConfig(config) {
        const me = this;

        if (config === null) {
            me.store.clearGroupers();
        }
        else {
            super.setConfig(me.processConfig(config));
        }
    }

    set renderer(renderer) {
        this.groupRenderer = renderer;
    }

    set field(field) {
        this._field = field;
        this.store.group(field, this.ascending);
    }

    get field() {
        return this._field;
    }

    doDestroy() {
        super.doDestroy();
    }

    //endregion

    //region Plugin config

    // Plugin configuration. This plugin chains some of the functions in Grid.
    static get pluginConfig() {
        return {
            assign : ['collapseAll', 'expandAll'],
            chain  : ['renderHeader', 'getHeaderMenuItems',
                'getColumnDragToolbarItems', 'onElementTouchStart',
                'onElementClick', 'onElementKeyDown'],
            override : [
                'onStoreDataChange'
            ]
        };
    }

    //endregion

    //region Expand/collapse

    /**
     * Collapses or expands a group depending on its current state
     * @param recordOrId Record or records id for a group row to collapse or expand
     * @param collapse Force collapse (true) or expand (true)
     * @fires togglegroup
     */
    toggleCollapse(recordOrId, collapse) {
        this.internalToggleCollapse(recordOrId, collapse);
    }

    /**
     * Collapses or expands a group depending on its current state
     * @param recordOrId Record or records id for a group row to collapse or expand
     * @param collapse Force collapse (true) or expand (true)
     * @param skipRender True to not render rows
     * @internal
     * @fires togglegroup
     */
    internalToggleCollapse(recordOrId, collapse, skipRender = false) {
        

        const me          = this,
            store       = me.store,
            groupRecord = store.getById(recordOrId),
            meta        = groupRecord.meta;

        if (meta.groupRowFor === undefined) return;

        collapse = collapse === undefined ? !meta.collapsed : collapse;

        if (collapse) {
            store.excludeGroupRecords(groupRecord);
        }
        else {
            store.includeGroupRecords(groupRecord);
        }

        // note to self, all this could be done in collapsed setter on GroupModel if desired...
        meta.collapsed = collapse;

        /**
         * Group expanded or collapsed
         * @event toggleGroup
         * @param groupRecord Group record
         * @param {Boolean} collapse Collapsed (true) or expanded (false)
         */
        me.grid.trigger('toggleGroup', { groupRecord, collapse });

        if (!skipRender) {
            // render from grouprecord and down, no need to touch those above
            me.rowManager.renderFromRecord(groupRecord);
        }
    }

    /**
     * Collapse all groups. This function is exposed on Grid and can thus be called as `grid.collapseAll()`
     */
    collapseAll() {
        const me = this;
        if (me.store.isGrouped) {
            me.store.groupRecords.forEach(r => me.internalToggleCollapse(r, true, true));
            me.grid.refreshRows(true);
        }
    }

    /**
     * Expand all groups. This function is exposed on Grid and can thus be called as `grid.expandAll()`
     */
    expandAll() {
        const me = this;
        if (me.store.isGrouped) {
            me.store.groupRecords.forEach(r => me.internalToggleCollapse(r, false, true));
            me.grid.refreshRows();
        }
    }

    //endregion

    //region Rendering

    /**
     * Called when a cell is rendered, styles the group rows first cell.
     * @private
     */
    renderCell(renderData) {
        const me = this;

        // no need to run the code below if not grouping
        if (!me.store.isGrouped) return;

        let {
                cellElement,
                rowElement,
                column
            }           = renderData,
            grid        = me.grid,
            meta        = renderData.record.meta,
            firstColumn = grid.columns.visibleColumns[0],
            subGrid     = grid.getSubGridFromColumn(column);

        if (meta.hasOwnProperty('groupRowFor')) {
            // Let column clear the cell, in case it needs to do some cleanup
            column.clearCell(cellElement);

            // this is a group row, add css classes

            // IE11 doesnt support this
            //rowElement.classList.toggle('b-grid-group-collapsed', meta.collapsed === undefined ? false : meta.collapsed);
            if (meta.collapsed) {
                rowElement.classList.add('b-grid-group-collapsed');
            }
            else {
                rowElement.classList.remove('b-grid-group-collapsed');
            }

            rowElement.classList.add('b-group-row');

            if (
                column.region === grid.regions[0] &&
                (
                    (firstColumn.type === 'rownumber' && column === subGrid.columns.visibleColumns[1]) ||
                    (firstColumn.type !== 'rownumber' && column === subGrid.columns.visibleColumns[0])
                )
            ) {
                cellElement.classList.add('b-group-title');
            }

            me.buildGroupHeader(renderData);
        }
        else {
            // not a group row, remove css classes
            // TODO: use dom query to remove before render instead?
            if (cellElement === rowElement.firstElementChild) {
                // IE11 doesnt support this
                //rowElement.classList.remove('b-group-row', 'b-grid-group-collapsed');
                rowElement.classList.remove('b-group-row');
                rowElement.classList.remove('b-grid-group-collapsed');
                cellElement.classList.remove('b-group-title');
            }
        }
    }

    // renderData.cellElement is required
    buildGroupHeader(renderData) {
        const me = this,
            {
                record,
                cellElement,
                rowElement,
                column,
                persist
            }           = renderData,
            grid        = me.grid,
            meta        = record.meta,
            firstColumn = grid.columns.visibleColumns[0],
            subGrid     = column && grid.getSubGridFromColumn(column);

        let html = null;

        if (
            persist ||
            column &&
            (firstColumn.type === 'rownumber' && column === subGrid.columns.visibleColumns[1]) ||
            (firstColumn.type !== 'rownumber' && column === subGrid.columns.visibleColumns[0])
        ) {
            // build group header no matter what cell it is or
            // build group value in first cell of first subgrid
            if (persist || column.region === grid.regions[0]) {
                const groupColumn = grid.columns.get(meta.groupField);
                // First try using columns groupRenderer (might not even have a column if grouping programmatically)
                if (groupColumn && groupColumn.groupRenderer) {
                    // groupRenderer could return nothing and just apply changes directly to DOM element
                    html = groupColumn.groupRenderer({
                        cellElement,
                        groupRowFor : meta.groupRowFor,
                        record,
                        rowElement,
                        groupColumn
                    });
                }
                // Secondly use features groupRenderer, if configured with one
                else if (me.groupRenderer) {
                    // groupRenderer could return nothing and just apply changes directly to DOM element
                    html = me.groupRenderer(Object.assign({}, renderData, {
                        groupRowFor : meta.groupRowFor,
                        groupColumn
                    }));
                }
                // Third, just display unformatted value and child count
                else {
                    // Need to adjust count if group summary is used
                    // TODO remove this when grouping has been refactored to not store group headers/footers in the Store
                    const count = meta.childCount - (grid.features.groupSummary ? 1 : 0);

                    html = `${meta.groupRowFor == null ? '' : meta.groupRowFor} (${count})`;
                }
            }
        }
        else if (me.groupRenderer) {
            // groupRenderer could return nothing and just apply changes directly to DOM element
            html = me.groupRenderer(renderData);
        }

        // Renderers could return nothing and just apply changes directly to DOM element
        if (typeof html === 'string') {
            cellElement.innerHTML = html;
        }

        return cellElement.innerHTML;
    }

    /**
     * Called when an header is rendered, adds grouping icon if grouped by that column.
     * @private
     * @param headerContainerElement
     */
    renderHeader(headerContainerElement) {
        let me       = this,
            grid     = me.grid,
            groupers = me.store.groupers;

        // Sorted from start, reflect in rendering
        for (let groupInfo of groupers) {
            // Might be grouping by field without column, which is valid
            const column = grid.columns.get(groupInfo.field),
                header = column && grid.getHeaderElement(column.id);
            // IE11 doesnt support this
            //header && header.classList.add('b-group', groupInfo.ascending ? 'b-asc' : 'b-desc');
            if (header) {
                header.classList.add('b-group');
                header.classList.add(groupInfo.ascending ? 'b-asc' : 'b-desc');
            }
        }
    }

    //endregion

    //region Context menu

    /**
     * Supply items for headers context menu.
     * @private
     * @param column Header for this column
     * @param items Add items to this array
     * @returns {Object[]} Modified items array
     */
    getHeaderMenuItems(column, items) {
        const me    = this,
            store = me.store;

        if (column.groupable !== false) {
            items.push({
                text   : me.L('groupAscending'),
                icon   : 'b-fw-icon b-icon-group-asc',
                name   : 'groupAsc',
                cls    : 'b-separator',
                weight : 110,
                onItem : ({ item : { column } }) => store.group(column.field, true)
            }, {
                text   : me.L('groupDescending'),
                icon   : 'b-fw-icon b-icon-group-desc',
                name   : 'groupDesc',
                weight : 110,
                onItem : ({ item : { column } }) => store.group(column.field, false)
            });
        }

        if (store.isGrouped) {
            items.push({
                text   : me.L('stopGrouping'),
                icon   : 'b-fw-icon b-icon-clear',
                name   : 'groupRemove',
                cls    : column.groupable ? '' : 'b-separator',
                weight : 110,
                onItem : ({ item : { column } }) => store.clearGroupers()
            });
        }

    }

    /**
     * Supply items to ColumnDragToolbar
     * @private
     */
    getColumnDragToolbarItems(column, items) {
        const me    = this,
            store = me.store;

        items.push({
            text   : me.L('groupAscendingShort'),
            group  : me.L('Group'),
            icon   : 'b-icon b-icon-group-asc',
            name   : 'groupAsc',
            cls    : 'b-separator',
            weight : 110,
            onDrop : ({ column }) => store.group(column.field, true)
        });

        items.push({
            text   : me.L('groupDescendingShort'),
            group  : me.L('Group'),
            icon   : 'b-icon b-icon-group-desc',
            name   : 'groupDesc',
            weight : 110,
            onDrop : ({ column }) => store.group(column.field, false)
        });

        let grouped = store.groupers && store.groupers.find(col => col.field === column.field);
        items.push({
            text     : me.L('stopGroupingShort'),
            group    : me.L('Group'),
            icon     : 'b-icon b-icon-clear',
            name     : 'groupRemove',
            disabled : !grouped,
            weight   : 110,
            onDrop   : ({ column }) => store.removeGrouper(column.field)
        });

        return items;
    }

    //endregion

    //region Events - Store

    /**
     * Called when store grouping changes. Reflects on header and rerenders rows.
     * @private
     */
    onStoreGroup({ groupers }) {
        const grid            = this.grid,
            element         = grid.element,
            curGroupHeaders = element && DomHelper.children(element, '.b-grid-header.b-group');

        if (element) {
            for (let header of curGroupHeaders) {
                // IE11 doesnt support this
                //header.classList.remove('b-group', 'b-asc', 'b-desc');
                header.classList.remove('b-group');
                header.classList.remove('b-asc');
                header.classList.remove('b-desc');
            }

            for (let groupInfo of groupers) {
                let header = grid.getHeaderElementByField(groupInfo.field);
                // IE11 doesnt support this
                //header && header.classList.add('b-group', groupInfo.ascending ? 'b-asc' : 'b-desc');
                if (header) {
                    header.classList.add('b-group');
                    header.classList.add(groupInfo.ascending ? 'b-asc' : 'b-desc');
                }
            }
        }
    }

    /**
     * Called when grouping is turned of in the store. Updates headers and rows.
     * @private
     * @param store
     */
    onStoreGroupingOff() {
        const me              = this,
            grid            = me.grid,
            curGroupHeaders = DomHelper.children(grid.element, '.b-grid-header.b-group');

        for (let header of curGroupHeaders) {
            // IE11 doesnt support this
            //header.classList.remove('b-group', 'b-asc', 'b-desc');
            header.classList.remove('b-group');
            header.classList.remove('b-asc');
            header.classList.remove('b-desc');
        }

        grid.refreshRows();
    }

    //endregion

    //region Events - Grid

    /**
     * Store touches when user touches header, used in onElementTouchEnd.
     * @private
     */
    onElementTouchStart(event) {
        const me     = this,
            target = event.target,
            header = DomHelper.up(target, '.b-grid-header'),
            column = header && me.grid.getColumnFromElement(header);

        // If it's a multi touch, group.
        if (event.touches.length > 1 && column && column.groupable !== false) {
            me.store.group(column.field);
        }
    }

    /**
     * React to click on headers (to group by that column if [alt] is pressed) and on group rows (expand/collapse).
     * @private
     * @param event
     * @returns {Boolean}
     */
    onElementClick(event) {
        const me     = this,
            store  = me.store,
            target = event.target,
            row    = DomHelper.up(target, '.b-group-row'),
            header = DomHelper.up(target, '.b-grid-header'),
            field  = header && header.dataset.column;

        if (target.classList.contains('b-resizer')) return;

        // Header
        if (header && field) {
            const columnGrouper = store.groupers.find(g => g.field === field);

            // Store has a grouper for this column's field; flip grouper order
            if (columnGrouper && !event.shiftKey) {
                store.group(field, !columnGrouper.ascending);
                return false;
            }
            // Group or ungroup
            else if (event.shiftKey) {
                const column = me.grid.columns.get(field);

                if (column.groupable !== false) {
                    if (event.altKey) {
                        store.removeGrouper(field);
                    }
                    else {
                        store.group(field);
                    }
                }
            }
            event.preventDefault();
        }

        // Anywhere on group-row
        if (row) {
            me.internalToggleCollapse(DomDataStore.get(row).id);
            return false;
        }
    }

    /**
     * Toggle groups with [space].
     * @private
     * @param event
     * @returns {*}
     */
    onElementKeyDown(event) {
        const me = this;

        // only catch space on grid element, not in editors etc...
        if (me.store.isGrouped && event.target === me.client.element && event.key === ' ' && me.grid.focusedCell) {
            event.preventDefault();

            me.internalToggleCollapse(me.grid.focusedCell.id);
        }
    }

    //endregion

    //region Grid overrides

    onStoreDataChange(data) {
        const store = data.source;

        // Grouping mixin needs to process data which then makes sure UI is refeshed
        if (store.isGrouped && store.count > 0) return;

        this.overridden.onStoreDataChange(data);
    }

    //endregion
}

Group.featureClass = 'b-group';

Group._$name = 'Group'; GridFeatureManager.registerFeature(Group, true, ['Grid', 'Scheduler']);
