var productName = 'scheduler';/**
 * @module Common/helper/BrowserHelper
 */

/**
 * Static helper class that does browser/feature detection.
 * @internal
 */
export default class BrowserHelper {
    static cacheFlags() {
        const me = this;

        // os
        me._isLinux = !!navigator.platform.match(/Linux/);
        me._isMac = !!navigator.platform.match(/Mac/);
        me._isWindows = !!navigator.platform.match(/Win32/);

        // browser
        me._isIE11 = !!navigator.userAgent.match(/rv:11/);
        me._isEdge = !!navigator.userAgent.match(/Edge\//);
        // Edge user agent contain webkit too
        me._isWebkit = !!navigator.userAgent.match(/WebKit/) && !me.isEdge;
        me._isFirefox = !!navigator.userAgent.match(/Firefox/) && !me.isEdge;
        me._isChrome = !!navigator.userAgent.match(/Chrome/) && !me.isEdge;
        me._isSafari = !!navigator.userAgent.match(/Safari/) && !me.isChrome && !me.isEdge;
        me._isMobileSafari = navigator.userAgent.match(/Mobile.*Safari/);

        try {
            document.querySelector(':scope');
            // Scoped queries are not supported for custom element polyfill in firefox
            // https://app.assembla.com/spaces/bryntum/tickets/6781
            me.supportsQueryScope = !this.isFirefox;
        }
        catch (e) {
            me.supportsQueryScope = false;
        }

        me._supportsPassive = false;
        try {
            // If the browser asks the options object to yield its passive
            // property, we know it supports the object form options object
            // and passive listeners.
            document.addEventListener('__notvalid__', null, {
                get passive() {
                    me._supportsPassive = true;
                }
            });
        }
        catch (e) {}

        //document.addEventListener("DOMContentLoaded", me.onDocumentReady);
    }

    // NOTE: Not allowed with CSP, moved to Grid#render()
    // /**
    //  * Feature and browser detection which requires the document to be loaded.
    //  * @private
    //  */
    // static onDocumentReady() {
    //     // Firefox includes a part of the Y scroller jutting up which is not needed.
    //     // Also need to "mitre" the horizontal scrollbar to leave the gap at the end.
    //     if (BrowserHelper.isFirefox) {
    //         CSSHelper.insertRule(`.b-virtual-scroller {height: ${DomHelper.scrollBarWidth}px;}`);
    //         CSSHelper.insertRule(`.b-virtual-scrollers {padding-right: ${DomHelper.scrollBarWidth}px;}`);
    //     }
    // }

    /**
     * Tries to determine if the user is using a touch device
     * @returns {Boolean}
     * @readonly
     */
    static get isTouchDevice() {
        if ('_isTouchDevice' in this) return this._isTouchDevice;

        return (('ontouchstart' in window) ||
            // edge tends to always mave this with a value 2
            (!this.isEdge && navigator.maxTouchPoints > 0) ||
            // but if env is actually touchable, then window has this class present
            (this.isEdge && window.TouchEvent) ||
            (navigator.msMaxTouchPoints > 0));
    }

    // Since touch screen detection is unreliable we should allow client to configure it, or detect first touch
    static set isTouchDevice(value) {
        this._isTouchDevice = value;
    }

    /**
     * Checks if platform is mac
     * @returns {Boolean}
     * @readonly
     */
    static get isMac() {
        return BrowserHelper._isMac;
    }

    /**
     * Checks if platform is windows
     * @returns {Boolean}
     * @readonly
     */
    static get isWindows() {
        return BrowserHelper._isWindows;
    }

    /**
     * Checks if platform is linux
     * @returns {Boolean}
     * @readonly
     */
    static get isLinux() {
        return BrowserHelper._isLinux;
    }

    /**
     * Checks if browser is IE11
     * @returns {Boolean}
     * @readonly
     */
    static get isIE11() {
        return BrowserHelper._isIE11;
    }

    /**
     * Checks if browser is Edge
     * @returns {Boolean}
     * @readonly
     */
    static get isEdge() {
        return BrowserHelper._isEdge;
    }

    /**
     * Checks if browser is Webkit
     * @returns {Boolean}
     * @readonly
     */
    static get isWebkit() {
        return BrowserHelper._isWebKit;
    }

    /**
     * Checks if browser is Chrome
     * @returns {Boolean}
     * @readonly
     */
    static get isChrome() {
        return this._isChrome;
    }

    /**
     * Find the major Chrome version
     * @returns {Number}
     * @readonly
     */
    static get chromeVersion() {
        const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

        return raw ? parseInt(raw[2], 10) : 0;
    }

    /**
     * Checks if browser is Firefox
     * @returns {Boolean}
     * @readonly
     */
    static get isFirefox() {
        return this._isFirefox;
    }

    /**
     * Checks if browser is Safari
     * @returns {Boolean}
     * @readonly
     */
    static get isSafari() {
        return this._isSafari;
    }

    /**
     * Checks if browser is mobile Safari
     * @returns {Boolean}
     * @readonly
     */
    static get isMobileSafari() {
        return this._isMobileSafari;
    }

    /**
     * Returns `true` if the browser supports passive event listeners.
     */
    static get supportsPassive() {
        return this._supportsPassive;
    }

    // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
    static get storageAvailable() {
        let storage, x;

        try {
            storage = localStorage;
            x = '__storage_test__';

            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        }
        catch (e) {
            return e instanceof DOMException && (
                // everything except Firefox
                e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === 'QuotaExceededError' ||
                // Firefox
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
                // acknowledge QuotaExceededError only if there's something already stored
                storage.length !== 0;
        }
    }

    static setLocalStorageItem(key, value) {
        this.storageAvailable && localStorage.setItem(key, value);
    }

    static getLocalStorageItem(key) {
        return this.storageAvailable && localStorage.getItem(key);
    }

    static removeLocalStorageItem(key) {
        this.storageAvailable && localStorage.removeItem(key);
    }
}

BrowserHelper.cacheFlags();
BrowserHelper._$name = 'BrowserHelper';