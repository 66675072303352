var productName = 'scheduler';/**
 * @module Common/data/stm/action/UpdateAction
 */
import ActionBase from './ActionBase.js';
import Model from '../../Model.js';

const MODEL_PROP    = Symbol('MODEL_PROP');
const NEW_DATA_PROP = Symbol('NEW_DATA_PROP');
const OLD_DATA_PROP = Symbol('OLD_DATA_PROP');

/**
 * Action to record the fact that a model has been updated.
 */
export default class UpdateAction extends ActionBase {

    static get defaultConfig() {
        return {
            /**
             * Reference to a model which has been updated.
             *
             * @config {Common.data.Model}
             * @default
             */
            model : undefined,

            /**
             * Map of updated properties with new values.
             *
             * @config {Object}
             * @default
             */
            newData : undefined,

            /**
             * Map of updated properties with old values.
             *
             * @config {Object}
             * @default
             */
            oldData : undefined
        };
    }

    get type() {
        return 'UpdateAction';
    }

    

    get model() {
        return this[MODEL_PROP];
    }

    set model(value) {
        
        this[MODEL_PROP] = value;
    }

    get newData() {
        return this[NEW_DATA_PROP];
    }

    set newData(value) {
        
        this[NEW_DATA_PROP] = Object.assign({}, value);
    }

    get oldData() {
        return this[OLD_DATA_PROP];
    }

    set oldData(value) {
        
        this[OLD_DATA_PROP] = Object.assign({}, value);
    }

    undo() {
        this.model.set(this.oldData);
    }

    redo() {
        this.model.set(this.newData);
    }
}
UpdateAction._$name = 'UpdateAction';