var productName = 'scheduler';/* eslint-disable */
// leave in, comment removed in bryntum-loader. also needs to go at top since polyfill is used by other code
//import "nodelist-foreach-polyfill";

if (!window.bryntum) window.bryntum = {};

import 'whatwg-fetch';
import 'abortcontroller-polyfill';

//region Common

// adapter
import BryntumWidgetAdapter from './lib/Common/adapter/widget/BryntumWidgetAdapter.js';
import BryntumWidgetAdapterRegister from './lib/Common/adapter/widget/util/BryntumWidgetAdapterRegister.js';

// data
import AjaxStore from './lib/Common/data/AjaxStore.js';
import Model from './lib/Common/data/Model.js';
import Store from './lib/Common/data/Store.js';
import StateTrackingManager from './lib/Common/data/stm/StateTrackingManager.js';
import Transaction from './lib/Common/data/stm/Transaction.js';

// helpers
import AjaxHelper from './lib/Common/helper/AjaxHelper.js';
import ArrayHelper from './lib/Common/helper/ArrayHelper.js';
import BrowserHelper from './lib/Common/helper/BrowserHelper.js';
import CSSHelper from './lib/Common/helper/CSSHelper.js';
import DateHelper from './lib/Common/helper/DateHelper.js';
import DragHelper from './lib/Common/helper/DragHelper.js';
import DomHelper from './lib/Common/helper/DomHelper.js';
import DomClassList from './lib/Common/helper/util/DomClassList.js';
import EventHelper from './lib/Common/helper/EventHelper.js';
import FunctionHelper from './lib/Common/helper/FunctionHelper.js';
import IdHelper from './lib/Common/helper/IdHelper.js';
import ObjectHelper from './lib/Common/helper/ObjectHelper.js';
import ResizeHelper from './lib/Common/helper/ResizeHelper.js';
import StringHelper from './lib/Common/helper/StringHelper.js';
import TemplateHelper from './lib/Common/helper/TemplateHelper.js';
import VersionHelper from './lib/Common/helper/VersionHelper.js';
import WidgetHelper from './lib/Common/helper/WidgetHelper.js';

import DataGenerator from './lib/Common/helper/util/DataGenerator.js';
import RandomGenerator from './lib/Common/helper/util/RandomGenerator.js';
import DemoBot from './lib/Common/helper/util/DemoBot.js';
import Fullscreen from './lib/Common/helper/util/Fullscreen.js';
import Scroller from './lib/Common/helper/util/Scroller.js';

import Point from './lib/Common/helper/util/Point.js';
import Rectangle from './lib/Common/helper/util/Rectangle.js';
import ResizeMonitor from './lib/Common/helper/ResizeMonitor.js';
import GlobalEvents from './lib/Common/GlobalEvents.js';

// localization
import Localizable from './lib/Common/localization/Localizable.js';
import LocaleManager from './lib/Common/localization/LocaleManager.js';

// mixin
import Delayable from './lib/Common/mixin/Delayable.js';
import Events from './lib/Common/mixin/Events.js';
import InstancePlugin from './lib/Common/mixin/InstancePlugin.js';
import Override from './lib/Common/mixin/Override.js';
import Pluggable from './lib/Common/mixin/Pluggable.js';
import State from './lib/Common/mixin/State.js';

// util
import CollectionFilter from './lib/Common/util/CollectionFilter.js';
import CollectionSorter from './lib/Common/util/CollectionSorter.js';
import Collection from './lib/Common/util/Collection.js';

// widget
import BooleanCombo from './lib/Common/widget/BooleanCombo.js';
import Button from './lib/Common/widget/Button.js';
import ButtonGroup from './lib/Common/widget/ButtonGroup.js';
import CalendarPanel from './lib/Common/widget/CalendarPanel.js';
import Checkbox from './lib/Common/widget/Checkbox.js';
import Combo from './lib/Common/widget/Combo.js';
import Container from './lib/Common/widget/Container.js';
import Field from './lib/Common/widget/Field.js';
import FileField from './lib/Common/widget/FileField.js';
import FilePicker from './lib/Common/widget/FilePicker.js';
import FlagField from './lib/Common/widget/FlagField.js';
import DateField from './lib/Common/widget/DateField.js';
import DatePicker from './lib/Common/widget/DatePicker.js';
import DisplayField from './lib/Common/widget/DisplayField.js';
import DurationField from './lib/Common/widget/DurationField.js';
import List from './lib/Common/widget/List.js';
import ChipView from './lib/Common/widget/ChipView.js';
import Mask from './lib/Common/widget/Mask.js';
import Menu from './lib/Common/widget/Menu.js';
import MenuItem from './lib/Common/widget/MenuItem.js';
import NumberField from './lib/Common/widget/NumberField.js';
import Tool from './lib/Common/widget/Tool.js';
import Panel from './lib/Common/widget/Panel.js';
import Popup from './lib/Common/widget/Popup.js';
import Ripple from './lib/Common/widget/Ripple.js';
import Slider from './lib/Common/widget/Slider.js';
import TabPanel from './lib/Common/widget/TabPanel.js';
import TextAreaField from './lib/Common/widget/TextAreaField.js';
import TextField from './lib/Common/widget/TextField.js';
import TimeField from './lib/Common/widget/TimeField.js';
import TimePicker from './lib/Common/widget/TimePicker.js';
import Toast from './lib/Common/widget/Toast.js';
import Tooltip from './lib/Common/widget/Tooltip.js';
import Widget from './lib/Common/widget/Widget.js';

//endregion

//region Grid

// columns
import Column from './lib/Grid/column/Column.js';
import CheckColumn from './lib/Grid/column/CheckColumn.js';
import DateColumn from './lib/Grid/column/DateColumn.js';
import PercentColumn from './lib/Grid/column/PercentColumn.js';
import RatingColumn from './lib/Grid/column/RatingColumn.js';
import RowNumberColumn from './lib/Grid/column/RowNumberColumn.js';
import TemplateColumn from './lib/Grid/column/TemplateColumn.js';
import TimeColumn from './lib/Grid/column/TimeColumn.js';
import TreeColumn from './lib/Grid/column/TreeColumn.js';
import WidgetColumn from './lib/Grid/column/WidgetColumn.js';

//import GridTag from './lib/Grid/customElements/GridTag.js';

// data
import ColumnStore from './lib/Grid/data/ColumnStore.js';
import GridRowModel from './lib/Grid/data/GridRowModel.js';

// features
import GridFeatureManager from './lib/Grid/feature/GridFeatureManager.js';
import CellEdit from './lib/Grid/feature/CellEdit.js';
import ColumnDragToolbar from './lib/Grid/feature/ColumnDragToolbar.js';
import ColumnPicker from './lib/Grid/feature/ColumnPicker.js';
import ColumnReorder from './lib/Grid/feature/ColumnReorder.js';
import ColumnResize from './lib/Grid/feature/ColumnResize.js';
import ContextMenu from './lib/Grid/feature/ContextMenu.js';
import CellTooltip from './lib/Grid/feature/CellTooltip.js';
import Filter from './lib/Grid/feature/Filter.js';
import FilterBar from './lib/Grid/feature/FilterBar.js';
import Group from './lib/Grid/feature/Group.js';
import QuickFind from './lib/Grid/feature/QuickFind.js';
import Search from './lib/Grid/feature/Search.js';
import Sort from './lib/Grid/feature/Sort.js';
import RegionResize from './lib/Grid/feature/RegionResize.js';
import RowReorder from './lib/Grid/feature/RowReorder.js';
import Stripe from './lib/Grid/feature/Stripe.js';
import Tree from './lib/Grid/feature/Tree.js';

// util
import ScrollManager from './lib/Grid/util/ScrollManager.js';

// view
import RowManager from './lib/Grid/row/RowManager.js'
import Footer from './lib/Grid/view/Footer.js';
import Grid from './lib/Grid/view/Grid.js';
import Header from './lib/Grid/view/Header.js';
import TreeGrid from './lib/Grid/view/TreeGrid.js';

//endregion

//region Scheduler

// crud
import AjaxTransport from './lib/Scheduler/crud/transport/AjaxTransport.js';
import AbstractCrudManager from './lib/Scheduler/crud/AbstractCrudManager.js';

// data
import AssignmentStore from './lib/Scheduler/data/AssignmentStore.js';
import Calendar from './lib/Scheduler/data/Calendar.js';
import CrudManager from './lib/Scheduler/data/CrudManager.js';
import DependencyStore from './lib/Scheduler/data/DependencyStore.js';
import EventStore from './lib/Scheduler/data/EventStore.js';
import ResourceStore from './lib/Scheduler/data/ResourceStore.js';
import ResourceTimeRangeStore from './lib/Scheduler/data/ResourceTimeRangeStore.js';
import TimeAxis from './lib/Scheduler/data/TimeAxis.js';

// features
import ColumnLines from './lib/Scheduler/feature/ColumnLines.js';
import Dependencies from './lib/Scheduler/feature/Dependencies.js';
import DependencyEdit from './lib/Scheduler/feature/DependencyEdit.js';
import EventContextMenu from './lib/Scheduler/feature/EventContextMenu.js';
import EventDrag from './lib/Scheduler/feature/EventDrag.js';
import EventDragCreate from './lib/Scheduler/feature/EventDragCreate.js';
import EventDragSelect from './lib/Scheduler/feature/EventDragSelect.js';
import EventEdit from './lib/Scheduler/feature/EventEdit.js';
import EventFilter from './lib/Scheduler/feature/EventFilter.js';
import EventResize from './lib/Scheduler/feature/EventResize.js';
import EventTooltip from './lib/Scheduler/feature/EventTooltip.js';
import GroupSummary from './lib/Scheduler/feature/GroupSummary.js';
import HeaderContextMenu from './lib/Scheduler/feature/HeaderContextMenu.js';
import HeaderZoom from './lib/Scheduler/feature/HeaderZoom.js';
import Labels from './lib/Scheduler/feature/Labels.js';
import NonWorkingTime from './lib/Scheduler/feature/NonWorkingTime.js';
import Pan from './lib/Scheduler/feature/Pan.js';
import ResourceTimeRanges from './lib/Scheduler/feature/ResourceTimeRanges.js';
import ScheduleTooltip from './lib/Scheduler/feature/ScheduleTooltip.js';
import SimpleEventEdit from './lib/Scheduler/feature/SimpleEventEdit.js';
import Summary from './lib/Scheduler/feature/Summary.js';
import TimeRanges from './lib/Scheduler/feature/TimeRanges.js';

// columns
import TimeAxisColumn from './lib/Scheduler/column/TimeAxisColumn.js';
import ResourceInfoColumn from './lib/Scheduler/column/ResourceInfoColumn.js';

// model
import AssignmentModel from './lib/Scheduler/model/AssignmentModel.js';
import CalendarDayModel from './lib/Scheduler/model/CalendarDayModel.js';
import DependencyBaseModel from './lib/Scheduler/model/DependencyBaseModel.js';
import DependencyModel from './lib/Scheduler/model/DependencyModel.js';
import EventModel from './lib/Scheduler/model/EventModel.js';
import ResourceModel from './lib/Scheduler/model/ResourceModel.js';
import ResourceTimeRangeModel from './lib/Scheduler/model/ResourceTimeRangeModel.js';
import TimeSpan from './lib/Scheduler/model/TimeSpan.js';

// preset
import PresetManager from './lib/Scheduler/preset/PresetManager.js';
import ViewPreset from './lib/Scheduler/preset/ViewPreset.js';

// util
import RectangularPathFinder from './lib/Scheduler/util/RectangularPathFinder.js';

//view
import TimeAxisViewModel from './lib/Scheduler/view/model/TimeAxisViewModel.js';
import HorizontalTimeAxis from './lib/Scheduler/view/HorizontalTimeAxis.js';
import Scheduler from './lib/Scheduler/view/Scheduler.js';

// IMPORTANT NOTE: Tag should be imported last to be last in the rolled up bundle. We rely on its position to embed
import SchedulerTag from './lib/Scheduler/customElements/SchedulerTag.js'

//endregion

export {
    //region Common

    // localization
    Localizable,
    LocaleManager,

    // helpers
    AjaxHelper,
    ArrayHelper,
    BrowserHelper,
    CSSHelper,
    DateHelper,
    DomHelper,
    DomClassList,
    DragHelper,
    EventHelper,
    FunctionHelper,
    IdHelper,
    ObjectHelper,
    ResizeHelper,
    StringHelper,
    TemplateHelper,
    VersionHelper,
    WidgetHelper,

    Point,
    Rectangle,
    ResizeMonitor,
    GlobalEvents,

    DataGenerator,
    RandomGenerator,
    DemoBot,
    Fullscreen,
    Scroller,

    // mixin
    Delayable,
    Events,
    InstancePlugin,
    Override,
    Pluggable,
    State,

    // widgets
    BooleanCombo,
    Button,
    ButtonGroup,
    CalendarPanel,
    Checkbox,
    Combo,
    Container,
    Field,
    FileField,
    FilePicker,
    FlagField,
    DateField,
    DatePicker,
    DisplayField,
    DurationField,
    List,
    ChipView,
    Mask,
    Menu,
    MenuItem,
    NumberField,
    Tool,
    Panel,
    Popup,
    Ripple,
    Slider,
    TabPanel,
    TextField,
    TextAreaField,
    TimeField,
    TimePicker,
    Toast,
    Tooltip,
    Widget,

    //endregion

    //region Grid
    BryntumWidgetAdapter,
    BryntumWidgetAdapterRegister,
    AjaxStore,
    Model,
    Store,
    StateTrackingManager,
    Transaction,

    // columns
    Column,
    CheckColumn,
    DateColumn,
    PercentColumn,
    RatingColumn,
    RowNumberColumn,
    TemplateColumn,
    TimeColumn,
    TreeColumn,
    WidgetColumn,
    TimeAxisColumn,
    ResourceInfoColumn,

    //GridTag,

    // features
    GridFeatureManager,
    CellEdit,
    CellTooltip,
    ColumnPicker,
    ColumnReorder,
    ColumnResize,
    ContextMenu,
    Filter,
    FilterBar,
    Group,
    QuickFind,
    Search,
    Sort,
    RegionResize,
    RowReorder,
    Stripe,

    // view
    Tree,
    Grid,
    TreeGrid,

    //endregion

    //region Scheduler

    // crud
    AjaxTransport,
    AbstractCrudManager,

    // data
    ColumnStore,
    AssignmentStore,
    Calendar,
    CrudManager,
    DependencyStore,
    EventStore,
    ResourceStore,
    ResourceTimeRangeStore,
    TimeAxis,

    // features
    ColumnLines,
    Dependencies,
    DependencyEdit,
    EventContextMenu,
    EventDrag,
    EventDragCreate,
    EventDragSelect,
    EventEdit,
    EventFilter,
    EventResize,
    EventTooltip,
    GroupSummary,
    HeaderContextMenu,
    HeaderZoom,
    Labels,
    NonWorkingTime,
    Pan,
    ResourceTimeRanges,
    ScheduleTooltip,
    SimpleEventEdit,
    Summary,
    TimeRanges,

    // model
    AssignmentModel,
    CalendarDayModel,
    DependencyBaseModel,
    DependencyModel,
    EventModel,
    ResourceModel,
    ResourceTimeRangeModel,
    TimeSpan,

    // preset
    PresetManager,
    ViewPreset,

    // util
    RectangularPathFinder,

    // view
    TimeAxisViewModel,
    HorizontalTimeAxis,
    Scheduler,

    RowManager,

    // IMPORTANT!!!
    // DO NOT CHANGE anything below this line. We rely on SchedulerTag variable to embed trial limit code
    SchedulerTag
    //endregion
}
