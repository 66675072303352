var productName = 'scheduler';import Store from '../../Common/data/Store.js';
import { default as DH } from '../../Common/helper/DateHelper.js';
import TimeSpan from '../model/TimeSpan.js';
import PresetManager from '../preset/PresetManager.js';

/**
 * @module Scheduler/data/TimeAxis
 */

/**
 * A class representing the time axis of the scheduler. The scheduler timescale is based on the ticks generated by this class.
 * This is a pure "data" (model) representation of the time axis and has no UI elements.
 *
 * The time axis can be {@link #config-continuous} or not. In continuous mode, each timespan starts where the previous ended, and in non-continuous mode
 * there can be gaps between the ticks.
 * A non-continuous time axis can be used when want to filter out certain periods of time (like weekends) from the time axis.
 *
 * To create a non-continuos time axis you have 2 options. First, you can create a time axis containing only the time spans of interest.
 * To do that, subclass this class and override the {@link #function-generateTicks} method.
 *
 * The other alternative is to call the {@link #function-filterBy} method, passing a function to it which should return `false` if the time tick should be filtered out.
 * Calling {@link Common.data.mixin.StoreFilter#function-clearFilters} will return you to a full time axis.
 *
 * @extends Common/data/Store
 */
export default class TimeAxis extends Store {
    //region Events

    /**
     * Fires before the timeaxis is about to be reconfigured (e.g. new start/end date or unit/increment). Return false to abort the operation.
     * @event beforereconfigure
     * @param {Scheduler.data.TimeAxis} source The time axis instance
     * @param {Date} startDate The new time axis start date
     * @param {Date} endDate The new time axis end date
     */

    /**
     * Event that is triggered when we end reconfiguring and everything UI-related should be done
     * @event endreconfigure
     * @private
     */

    /**
     * Fires when the timeaxis has been reconfigured (e.g. new start/end date or unit/increment)
     * @event reconfigure
     * @param {Scheduler.data.TimeAxis} source The time axis instance
     */

    /**
     * Fires if all the ticks in the timeaxis are filtered out. After firing the filter is cleared to return the time
     * axis to a valid state.
     * @event invalidFilter
     * @param {Scheduler.data.TimeAxis} source The time axis instance
     */

    //endregion

    //region Default config

    static get defaultConfig() {
        return {
            modelClass : TimeSpan,

            /**
             * Set to false if the timeline is not continuous, e.g. the next timespan does not start where the previous ended (for example skipping weekends etc).
             * @config {Boolean}
             * @default
             */
            continuous : true,

            originalContinuous : null,

            /**
             * Include only certain hours or days in the time axis (makes it `continuous : false`). Accepts and object
             * with `day` and `hour` properties:
             * ```
             * const scheduler = new Scheduler({
             *     timeAxis : {
             *         include : {
             *              // Do not display hours after 17 or before 9 (only display 9 - 17). The `to´ value is not
             *              // included in the time axis
             *              hour : {
             *                  from : 9,
             *                  to   : 17
             *              },
             *              // Do not display sunday or saturday
             *              day : [0, 6]
             *         }
             *     }
             * }
             * ```
             * In most cases we recommend that you use Scheduler's workingTime config instead. It is easier to use and
             * makes sure all parts of the Scheduler gets updated.
             * @config {Object}
             */
            include : null,

            /**
             * Automatically adjust the timespan when generating ticks with {@link #function-generateTicks} according to
             * the `viewPreset` configuration. Setting this to false may lead to shifting time/date of ticks.
             * @config {Boolean}
             * @default
             */
            autoAdjust : true,

            unit                : null,
            increment           : null,
            resolutionUnit      : null,
            resolutionIncrement : null,

            weekStartDay : null,

            mainUnit  : null,
            shiftUnit : null,

            shiftIncrement : 1,

            //isConfigured : false,

            // in case of `autoAdjust : false`, the 1st and last ticks can be truncated, containing only part of the normal tick
            // these dates will contain adjusted start/end (like if the tick has not been truncated)
            adjustedStart    : null,
            adjustedEnd      : null,
            // the visible position in the first tick, can actually be > 1 because the adjustment is done by the `mainUnit`
            visibleTickStart : null,
            // the visible position in the first tick, is always ticks count - 1 < value <= ticks count, in case of autoAdjust, always = ticks count
            visibleTickEnd   : null,

            // name of the current preset
            //_presetName : null,
            defaultSpan : 1,

            tickCache : {},

            viewPreset : null
        };
    }

    //endregion

    //region Init

    // private
    construct(config) {
        const me = this;

        // TODO: maybe not needed
        me.generateTicksValidatorFn = () => true;

        super.construct(config);

        me.originalContinuous = me.continuous;

        me.on({
            change : ({ action }) => {
                // If the change was due to filtering, there will be a refresh event
                // arriving next, so do not reconfigure
                if (action !== 'filter') {
                    me.trigger('reconfigure', { supressRefresh : false });
                }
            },
            refresh        : () => me.trigger('reconfigure', { supressRefresh : false }),
            endreconfigure : event => me.trigger('reconfigure', event)
        });

        if (me.startDate) {
            me.internalOnReconfigure();
            me.trigger('reconfigure');
        }
        else if (me.viewPreset) {
            const range = me.getAdjustedDates(new Date());
            me.startDate = range.startDate;
            me.endDate = range.endDate;
        }
    }

    //endregion

    //region Configuration (reconfigure & consumePreset)

    /**
     * Reconfigures the time axis based on the config object supplied and generates the new 'ticks'.
     * @param {Object} config
     * @param {Boolean} [suppressRefresh]
     * @private
     */
    reconfigure(config, suppressRefresh = false, preventThrow = false) {
        const me         = this,
            normalized = me.getAdjustedDates(config.startDate, config.endDate);

        if (me.trigger('beforeReconfigure', { startDate : normalized.startDate, endDate : normalized.endDate, config }) !== false) {
            me.trigger('beginReconfigure');

            me._configuredStartDate = config.startDate;
            me._configuredEndDate = config.endDate;

            Object.assign(me, config);

            if (me.internalOnReconfigure(preventThrow) === false) {
                return false;
            }

            me.trigger('endReconfigure', { suppressRefresh, config });
        }
    }

    internalOnReconfigure(preventThrow = false) {
        const me = this;

        me.isConfigured = true;

        const adjusted = me.getAdjustedDates(me.startDate, me.endDate, true),
            normalized = me.getAdjustedDates(me.startDate, me.endDate),
            start      = normalized.startDate,
            end        = normalized.endDate;

        if (start >= end) {
            throw new Error(`Invalid start/end dates. Start date must less than end date. Start date: ${start}. End date: ${end}.`);
        }

        const unit     = me.unit,
            increment  = me.increment || 1,
            ticks      = me.generateTicks(start, end, unit, increment);

        // Suspending to be able to detect an invalid filter
        me.suspendEvents();
        me.data = ticks;

        const { count } = me;

        if (count === 0) {
            if (preventThrow) {
                me.resumeEvents();
                return false;
            }
            throw new Error('Invalid time axis configuration or filter, please check your input data.');
        }

        // start date is cached, update it to fill after generated ticks
        me.startDate = me.first.startDate;

        me.resumeEvents();

        let checkEnd = me.last.endDate;

        if (me.isContinuous) {
            me.adjustedStart = adjusted.startDate;
            me.adjustedEnd = DH.getNext(count > 1 ? ticks[count - 1].startDate : adjusted.startDate, unit, increment, me.weekStartDay);
        }
        else {
            me.adjustedStart = me.startDate;
            me.adjustedEnd = checkEnd;
        }

        // if visibleTickStart > 1 this means some tick is fully outside of the view - we are not interested in it and want to
        // drop it and adjust "adjustedStart" accordingly
        do {
            // TODO this has to use more sophisticated formula to take into account that months for example can be expressed in ms consistenly
            me.visibleTickStart = (me.startDate - me.adjustedStart) / (DH.asMilliseconds(unit) * increment);

            // TODO: Changed from round to floor which seems to work, but this is not needed in ExtScheduler. Need to step and see what is different
            if (me.autoAdjust) me.visibleTickStart = Math.floor(me.visibleTickStart);

            if (me.visibleTickStart >= 1) me.adjustedStart = DH.getNext(me.adjustedStart, unit, increment, me.weekStartDay);
        } while (me.visibleTickStart >= 1);

        do {
            me.visibleTickEnd = count - (me.adjustedEnd - checkEnd) / (DH.asMilliseconds(unit) * increment);

            if (count - me.visibleTickEnd >= 1) me.adjustedEnd = DH.getNext(me.adjustedEnd, unit, -1, me.weekStartDay);
        } while (count - me.visibleTickEnd >= 1);

        me.updateTickCache(true);
    }

    /**
     * Get/set currently used preset
     * @property {Scheduler.preset.ViewPreset}
     */
    get viewPreset() {
        return this._viewPreset;
    }

    set viewPreset(viewPreset) {
        const me     = this,
            preset = PresetManager.getPreset(viewPreset);

        me._viewPreset = preset;

        Object.assign(me, {
            unit      : preset.bottomHeader.unit,
            increment : preset.bottomHeader.increment || 1,

            resolutionUnit      : preset.timeResolution.unit,
            resolutionIncrement : preset.timeResolution.increment,

            mainUnit       : preset.mainHeader.unit,
            shiftUnit      : preset.shiftUnit,
            shiftIncrement : preset.shiftIncrement || 1,

            defaultSpan : preset.defaultSpan || 1,
            presetName  : preset.name,

            // Weekview columns are updated upon 'datachanged' event on this object.
            // We have to pass headerConfig in order to render them correctly (timeAxisViewModel is incorrect in required time)
            headerConfig : preset.headerConfig
        });
    }

    //endregion

    //region Getters & setters

    // private
    get resolution() {
        return {
            unit      : this.resolutionUnit,
            increment : this.resolutionIncrement
        };
    }

    // private
    set resolution(resolution) {
        this.resolutionUnit = resolution.unit;
        this.resolutionIncrement = resolution.increment;
    }

    get resolutionUnit() {
        return this._resolutionUnit;
    }

    set resolutionUnit(resolutionUnit) {
        this._resolutionUnit = resolutionUnit;
    }

    get resolutionIncrement() {
        return this._resolutionIncrement;
    }

    set resolutionIncrement(resolutionIncrement) {
        this._resolutionIncrement = resolutionIncrement || 1;
    }

    set mainUnit(mainUnit) {
        this._mainUnit = mainUnit;
    }

    get mainUnit() {
        return this._mainUnit;
    }

    set shiftUnit(shiftUnit) {
        this._shiftUnit = shiftUnit;
    }

    // private
    get shiftUnit() {
        return this._shiftUnit || this._mainUnit;
    }

    set shiftIncrement(shiftIncrement) {
        this._shiftIncrement = shiftIncrement;
    }

    // private
    get shiftIncrement() {
        return this._shiftIncrement || 1;
    }

    set unit(unit) {
        this._unit = unit;
    }

    // private
    get unit() {
        return this._unit;
    }

    set increment(increment) {
        this._increment = increment;
    }

    // private
    get increment() {
        return this._increment;
    }

    get defaultSpan() {
        return this._defaultSpan;
    }

    set defaultSpan(defaultSpan) {
        this._defaultSpan = defaultSpan;
    }

    //endregion

    //region Timespan & resolution

    /**
     * Changes the time axis timespan to the supplied start and end dates.
     * @param {Date} newStartDate The new start date
     * @param {Date} newEndDate The new end date
     */
    setTimeSpan(newStartDate, newEndDate, preventThrow = false) {
        const me             = this,
            { startDate, endDate } = me.getAdjustedDates(newStartDate, newEndDate);

        if (me.startDate - startDate !== 0 || me.endDate - endDate !== 0) {
            return me.reconfigure({
                startDate,
                endDate
            }, false, preventThrow);
        }
    }

    /**
     * Moves the time axis by the passed amount and unit.
     *
     * NOTE: When using a filtered TimeAxis the result of `shift()` cannot be guaranteed, it might shift into a
     * filtered out span. It tries to be smart about it by shifting from unfiltered start and end dates.
     * If that solution does not work for your filtering setup, please call {@link #function-setTimeSpan} directly
     * instead.
     *
     * @param {Number} amount The number of units to jump
     * @param {String} [unit] The unit (Day, Week etc)
     */
    shift(amount, unit = this.shiftUnit) {
        const me = this;

        let { startDate, endDate } = me;

        // Use unfiltered start and end dates when shifting a filtered time axis, to lessen risk of messing it up.
        // Still not guaranteed to work though
        if (me.isFiltered) {
            startDate = me.allRecords[0].startDate;
            endDate = me.allRecords[me.allCount - 1].endDate;
        }

        // Hack for filtered time axis, for example if weekend is filtered out and you shiftPrev() day from monday
        let tries = 0;
        do {
            startDate = DH.add(startDate, amount, unit);
            endDate = DH.add(endDate, amount, unit);
        } while (tries++ < 100 && me.setTimeSpan(startDate, endDate, true) === false);
    }

    /**
     * Moves the time axis forward in time in units specified by the view preset `shiftUnit`, and by the amount specified by the `shiftIncrement`
     * config of the current view preset.
     *
     * NOTE: When using a filtered TimeAxis the result of `shiftNext()` cannot be guaranteed, it might shift into a
     * filtered out span. It tries to be smart about it by shifting from unfiltered start and end dates.
     * If that solution does not work for your filtering setup, please call {@link #function-setTimeSpan} directly
     * instead.

     *
     * @param {Number} [amount] The number of units to jump forward
     */
    shiftNext(amount = this.shiftIncrement) {
        this.shift(amount);
    }

    /**
     * Moves the time axis backward in time in units specified by the view preset `shiftUnit`, and by the amount specified by the `shiftIncrement` config of the current view preset.
     *
     * NOTE: When using a filtered TimeAxis the result of `shiftPrev()` cannot be guaranteed, it might shift into a
     * filtered out span. It tries to be smart about it by shifting from unfiltered start and end dates.
     * If that solution does not work for your filtering setup, please call {@link #function-setTimeSpan} directly
     * instead.

     *
     * @param {Number} [amount] The number of units to jump backward
     */
    shiftPrevious(amount = this.shiftIncrement) {
        this.shift(-amount);
    }

    //endregion

    //region Filter & continous

    /**
     * Filter the time axis by a function. The passed function will be called with each tick in time axis.
     * If the function returns true, the 'tick' is included otherwise it is filtered. If all ticks are filtered out
     * the time axis is considered invalid, triggering `invalidFilter` and then removing the filter.
     * @param {Function} fn The function to be called, it will receive an object with startDate/endDate properties, and 'index' of the tick.
     * @param {Object} [thisObj] `this` reference for the function
     */
    filterBy(fn, thisObj = this) {
        const me = this;

        me.filters.clear();

        super.filterBy((tick, index) => fn.call(thisObj, tick.data, index));

        if (me.count === 0) {
            me.trigger('invalidFilter');
            me.clearFilters();
        }
    }

    triggerFilterEvent(event) {
        const me = this;

        if (!event.filters.count) {
            me.continuous = me.originalContinuous;
        }
        else {
            me.continuous = false;
        }

        // Filters has been applied (or cleared) but listeners are not informed yet, update tick cache to have start and
        // end dates correct when later redrawing events & header
        me.updateTickCache();

        super.triggerFilterEvent(event);
    }

    /**
     * Returns `true` if the time axis is continuous (will return `false` when filtered)
     * @return {Boolean}
     */
    get isContinuous() {
        return this.continuous !== false && !this.filtered;
    }

    //endregion

    //region Dates

    getAdjustedDates(startDate, endDate, forceAdjust = false) {
        const me = this;

        startDate = startDate || me.startDate;
        endDate = endDate || DH.add(startDate, me.defaultSpan, me.mainUnit);

        return me.autoAdjust || forceAdjust ? {
            startDate : me.floorDate(startDate, false, me.autoAdjust ? me.mainUnit : me.unit, 1),
            endDate   : me.ceilDate(endDate, false, me.autoAdjust ? me.mainUnit : me.unit, 1)
        } : {
            startDate : startDate,
            endDate   : endDate
        };
    }

    /**
     * Method to get the current start date of the time axis.
     * @property {Date}
     */
    get startDate() {
        // TODO: added _start as caching, might mess something up when reconfiguring? change here if tests fail
        return this._start || (this.first ? new Date(this.first.startDate) : null);
    }

    set startDate(start) {
        this._start = DH.parse(start);
    }

    /**
     * Method to get a the current end date of the time axis
     * @property {Date}
     */
    get endDate() {
        return this._end || (this.last ? new Date(this.last.endDate) : null);
    }

    set endDate(end) {
        if (end) this._end = DH.parse(end);
    }

    // used in performance critical code for comparisons
    get startMS() {
        return this._startMS;
    }

    // used in performance critical code for comparisons
    get endMS() {
        return this._endMS;
    }

    // Floors a date and optionally snaps it to one of the following resolutions:
    // 1. 'resolutionUnit'. If param 'resolutionUnit' is passed, the date will simply be floored to this unit.
    // 2. If resolutionUnit is not passed: If date should be snapped relative to the timeaxis start date,
    // the resolutionUnit of the timeAxis will be used, or the timeAxis 'mainUnit' will be used to snap the date
    //
    // returns a copy of the original date
    // private
    floorDate(date, relativeToStart, resolutionUnit, incr) {
        relativeToStart = relativeToStart !== false;

        const
            me         = this,
            relativeTo = relativeToStart ? DH.clone(me.startDate) : null,
            increment  = incr || me.resolutionIncrement,
            unit       = resolutionUnit || (relativeToStart ? me.resolutionUnit : me.mainUnit),
            snap       = (value, increment) => Math.floor(value / increment) * increment;

        if (relativeToStart) {
            const snappedDuration = snap(DH.diff(relativeTo, date, unit), increment);
            // TODO: used to be small unit multipled with factor (minute = seconds, minutes * 60)
            return DH.add(relativeTo, snappedDuration, unit);
        }

        let dt = DH.clone(date);

        if (unit === 'week') {
            let day      = dt.getDay() || 7,
                startDay = me.weekStartDay || 7;

            dt = DH.add(DH.startOf(dt, 'day'), day >= startDay ? startDay - day : -(7 - startDay + day), 'day');

            // Watch out for Brazil DST craziness (see test 028_timeaxis_dst.t.js)
            if (dt.getDay() !== startDay && dt.getHours() === 23) {
                dt = DH.add(dt, 1, 'hour');
            }
        }
        else {
            // removes "smaller" units from date (for example minutes; removes seconds and milliseconds)
            dt = DH.startOf(dt, unit);

            // day and year are 1-based so need to make additional adjustments
            let modifier     = ['day', 'year'].includes(unit) ? 1 : 0,
                useUnit      = unit === 'day' ? 'date' : unit,
                snappedValue = snap(DH.get(dt, useUnit) - modifier, increment) + modifier;

            dt = DH.set(dt, useUnit, snappedValue);
        }

        return dt;
    }

    /**
     * Rounds the date to nearest unit increment
     * @private
     */
    roundDate(date, relativeTo) {
        let me        = this,
            dt        = DH.clone(date),
            increment = me.resolutionIncrement || 1;

        relativeTo = DH.clone(relativeTo || me.startDate);

        switch (me.resolutionUnit) {
            case 'week':
                DH.startOf(dt, 'day');

                let distanceToWeekStartDay = dt.getDay() - me.weekStartDay,
                    toAdd;

                if (distanceToWeekStartDay < 0) {
                    distanceToWeekStartDay = 7 + distanceToWeekStartDay;
                }

                if (Math.round(distanceToWeekStartDay / 7) === 1) {
                    toAdd = 7 - distanceToWeekStartDay;
                }
                else {
                    toAdd = -distanceToWeekStartDay;
                }

                return DH.add(dt, toAdd, 'day');

            case 'month':
                let nbrMonths     = DH.as('month', DH.diff(relativeTo, dt)) + (dt.getDay() / DH.daysInMonth(dt)),
                    snappedMonths = Math.round(nbrMonths / increment) * increment;
                return DH.add(relativeTo, snappedMonths, 'month');

            case 'quarter':
                DH.startOf(dt, 'month');
                return DH.add(dt, 'month', 3 - (dt.getMonth() % 3));

            default:
                const duration        = DH.as(me.resolutionUnit, DH.diff(relativeTo, dt)),
                    // Need to find the difference of timezone offsets between relativeTo and original dates. 0 if timezone offsets are the same.
                    offset          = DH.as(me.resolutionUnit, relativeTo.getTimezoneOffset() - dt.getTimezoneOffset(), 'minute'),
                    // Need to add the offset to the whole duration, so the divided value will take DST into account
                    snappedDuration = Math.round((duration + offset) / increment) * increment;

                // TODO: used to add one res unit lower * factor, minutes = add seconds, minutes * 60
                // Now when the round is done, we need to subtract the offset, so the result also will take DST into account
                return DH.add(relativeTo, snappedDuration - offset, me.resolutionUnit);
        }
    }

    // private
    ceilDate(date, relativeToStart, resolutionUnit, increment) {
        const me = this;

        relativeToStart = relativeToStart !== false;
        increment = increment || (relativeToStart ? this.resolutionIncrement : 1);

        let dt     = DH.clone(date),
            doCall = false,
            unit   = resolutionUnit || (relativeToStart ? me.resolutionUnit : me.mainUnit);

        switch (unit) {
            case 'minute':
                doCall = !DH.isStartOf(dt, 'minute');
                break;
            case 'hour':
                doCall = !DH.isStartOf(dt, 'hour');
                break;

            case 'day':
            case 'date':
                doCall = !DH.isStartOf(dt, 'day');
                break;

            case 'week':
                dt = DH.startOf(dt, 'day');
                doCall = (dt.getDay() !== me.weekStartDay || !DH.isEqual(dt, date));
                break;

            case 'month':
                dt = DH.startOf(dt, 'day');
                doCall = (dt.getDate() !== 1 || !DH.isEqual(dt, date));
                break;

            case 'quarter':
                dt = DH.startOf(dt, 'day');
                doCall = (dt.getMonth() % 3 !== 0 || dt.getDate() !== 1 || !DH.isEqual(dt, date));
                break;

            case 'year':
                dt = DH.startOf(dt, 'day');
                doCall = (dt.getMonth() !== 0 || dt.getDate() !== 1 || !DH.isEqual(dt, date));
                break;
        }

        if (doCall) return DH.getNext(dt, unit, increment, me.weekStartDay);

        return dt;
    }

    //endregion

    //region Ticks

    get include() {
        return this._include;
    }

    set include(include) {
        const me = this;

        me._include = include;
        me.continuous = !include;

        if (!me.isConfiguring) {
            me.startDate = me._configuredStartDate;
            me.endDate = me._configuredEndDate;
            me.internalOnReconfigure();
            me.trigger('includeChange');
        }
    }

    // Check if a certain date is included based on timeAxis.include rules
    processExclusion(startDate, endDate, unit) {
        const { include } = this;

        if (include) {
            return Object.entries(include).some(([includeUnit, rule]) => {

                if (!rule) {
                    return false;
                }

                const { from, to } = rule;

                // Including the closest smaller unit with a { from, to} rule should affect start & end of the
                // generated tick. Currently only works for days or smaller.
                if (DH.compareUnits('day', unit) >= 0 && DH.getLargerUnit(includeUnit) === unit) {
                    if (from) {
                        DH.set(startDate, includeUnit, from);
                    }

                    if (to) {
                        let stepUnit = unit;
                        // Stepping back base on date, not day
                        if (unit === 'day') {
                            stepUnit = 'date';
                        }
                        // Since endDate is not inclusive it points to the next day etc.
                        // Turns for example 2019-01-10T00:00 -> 2019-01-09T18:00
                        DH.set(endDate, {
                            [stepUnit]    : DH.get(endDate, stepUnit) - 1,
                            [includeUnit] : to
                        });
                    }
                }

                // "Greater" unit being included? Then we need to care about it
                // (for example excluding day will also affect hour, minute etc)
                if (DH.compareUnits(includeUnit, unit) >= 0) {
                    const datePart = (includeUnit === 'day' ? startDate.getDay() : DH.get(startDate, includeUnit));

                    if ((from && datePart < from) || (to && datePart >= to)) {
                        return true;
                    }
                }
            });
        }

        return false;
    }

    // Calculate constants used for exclusion when scaling within larger ticks
    initExclusion() {
        Object.entries(this.include).forEach(([unit, rule]) => {
            if (rule) {
                const { from, to } = rule;

                // For example for hour:
                // 1. Get the next bigger unit -> day, get ratio -> 24
                // 2. to 20 - from 8 = 12 hours visible each day. lengthFactor 24 / 12 = 2 means that each hour used
                // needs to represent 2 hours when drawn (to stretch)
                // |    ████    | -> |  ████████  |
                rule.lengthFactor = DH.getUnitToBaseUnitRatio(unit, DH.getLargerUnit(unit)) / (to - from);
                // TODO: Since `to` is exclusive this should be the correct one... but cannot get it to work throughout
                rule.lengthFactorExcl = DH.getUnitToBaseUnitRatio(unit, DH.getLargerUnit(unit)) / (to - from - 1);

                // Calculate weighted center to stretch around |   ██x█ |
                rule.center = from + from / (rule.lengthFactor - 1);
            }
        });
    }

    /**
     * Method generating the ticks for this time axis. Should return an array of ticks. Each tick is an object of the following structure:
     * ```
     * {
     *    startDate : ..., // start date
     *    endDate   : ...  // end date
     * }
     * ```
     * Take notice, that this function either has to be called with `start`/`end` parameters, or create those variables.
     *
     * @param {Date} axisStartDate The start date of the interval
     * @param {Date} axisEndDate The end date of the interval
     * @param {String} unit The unit of the time axis
     * @param {Number} increment The increment for the unit specified.
     * @return {Array} ticks The ticks representing the time axis
     */
    generateTicks(axisStartDate, axisEndDate, unit = this.unit, increment = this.increment) {
        const me          = this,
            ticks         = [],
            usesExclusion = Boolean(me.include);

        let intervalEnd,
            tickEnd,
            isExcluded,
            dstDiff        = 0,
            { startDate, endDate } = me.getAdjustedDates(axisStartDate, axisEndDate);

        me.tickCache = {};

        if (usesExclusion) {
            me.initExclusion();
        }

        while (startDate < endDate) {
            intervalEnd = DH.getNext(startDate, unit, increment, me.weekStartDay);

            if (!me.autoAdjust && intervalEnd > endDate) {
                intervalEnd = endDate;
            }

            // Handle hourly increments crossing DST boundaries to keep the timescale looking correct
            // Only do this for HOUR resolution currently, and only handle it once per tick generation.
            if (unit === 'hour' && increment > 1 && ticks.length > 0 && dstDiff === 0) {
                const prev = ticks[ticks.length - 1];

                dstDiff = ((prev.startDate.getHours() + increment) % 24) - prev.endDate.getHours();

                if (dstDiff !== 0) {
                    // A DST boundary was crossed in previous tick, adjust this tick to keep timeaxis "symmetric".
                    intervalEnd = DH.add(intervalEnd, dstDiff, 'hour');
                }
            }

            isExcluded = false;

            if (usesExclusion) {
                tickEnd = new Date(intervalEnd.getTime());
                isExcluded = me.processExclusion(startDate, intervalEnd, unit);
            }
            else {
                tickEnd = intervalEnd;
            }

            if (me.generateTicksValidatorFn(startDate) && !isExcluded) {
                ticks.push({
                    startDate,
                    endDate : intervalEnd
                });
                me.tickCache[startDate.getTime()] = ticks.length - 1;
            }

            startDate = tickEnd;
        }

        return ticks;
    }

    get visibleTickTimeSpan() {
        const me = this;
        return me.isContinuous ? me.visibleTickEnd - me.visibleTickStart : me.count;
    }

    /**
     * Gets a tick "coordinate" representing the date position on the time scale. Returns -1 if the date is not part of the time axis.
     * @param {Date} date the date
     * @return {Number} the tick position on the scale or -1 if the date is not part of the time axis
     */
    getTickFromDate(date) {
        const me = this,
            ticks = me.records;

        let begin = 0,
            end = ticks.length - 1,
            middle, tick, tickStart, tickEnd;

        // Quickly eliminate out of range dates or if we have not been set up with a time range yet
        if (!ticks.length || date < ticks[0].startDate || date > ticks[end].endDate) {
            return -1;
        }
        if (me.isContinuous) {
            // TODO: This is the code from ExtScheduler, it is a calculation without iteration so it should perform better,
            //  we should consider using it...
            // if (date - ticks[0].startDate === 0) return this.visibleTickStart;
            // if (date - ticks[end].endDate === 0) return this.visibleTickEnd;
            //
            // const { adjustedStart, adjustedEnd }     = this;
            //
            // let tickIndex       = Math.floor(ticks.length * (date - adjustedStart) / (adjustedEnd - adjustedStart));
            //
            // // for the date == adjustedEnd case
            // if (tickIndex > end) {
            //     tickIndex = end;
            // }
            //
            // const tickStart           = tickIndex === 0 ? adjustedStart : ticks[tickIndex].startDate;
            // const tickEnd             = tickIndex === end ? adjustedEnd : ticks[tickIndex].endDate;
            //
            // tick                = tickIndex + (date - tickStart) / (tickEnd - tickStart);
            //
            // // in case of `autoAdjust : false` the actual visible timespan starts not from 0 tick coordinate, but
            // // from `visibleTickStart` coordinate, this check generally repeats the "quick bailout" check in the begining of the method,
            // // but still
            // if (tick < this.visibleTickStart || tick > this.visibleTickEnd) {
            //     return -1;
            // }
            //
            // return tick;
            // Chop tick cache in half until we find a match
            while (begin < end) {
                middle = (begin + end + 1) >> 1;
                if (date > ticks[middle].endDate) {
                    begin = middle + 1;
                }
                else if (date < ticks[middle].startDate) {
                    end = middle - 1;
                }
                else {
                    begin = middle;
                }
            }
            tick = ticks[begin];
            tickStart = tick.startDate;
            tickEnd = tick.endDate;

            // Part way though, calculate the fraction
            if (date > tickStart) {
                begin += (date - tickStart) / (tickEnd - tickStart);
            }
            return Math.min(Math.max(begin, me.visibleTickStart), me.visibleTickEnd);
        }
        else {
            for (let i = 0; i <= end; i++) {
                tickEnd         = ticks[i].endDate;

                if (date <= tickEnd) {
                    tickStart   = ticks[i].startDate;

                    // date < tickStart can occur in filtered case
                    tick = i + (date > tickStart ? (date - tickStart) / (tickEnd - tickStart) : 0);

                    return tick;
                }
            }
        }

    }

    /**
     * Gets the time represented by a tick "coordinate".
     * @param {Number} tick the tick "coordinate"
     * @param {String} [roundingMethod] The rounding method to use
     * @return {Date} The date to represented by the tick "coordinate", or null if invalid.
     */
    getDateFromTick(tick, roundingMethod) {
        const me = this;

        if (tick === me.visibleTickEnd) {
            return me.endDate;
        }

        const wholeTick = Math.floor(tick),
            fraction  = tick - wholeTick,
            t         = me.getAt(wholeTick);

        if (!t) {
            return null;
        }

        let start = wholeTick === 0 ? me.adjustedStart : t.startDate,
            // if we've filtered timeaxis using filterBy, then we cannot trust to adjustedEnd property and should use tick end
            end   = (wholeTick === me.count - 1) && me.isContinuous ? me.adjustedEnd : t.endDate,
            date  = DH.add(start, fraction * (end - start), 'millisecond');

        if (roundingMethod) {
            date = me[roundingMethod + 'Date'](date);
        }

        return date;
    }

    /**
     * Returns the ticks of the timeaxis in an array of objects with a "start" and "end" date.
     * @return {Object[]} the ticks on the scale
     */
    get ticks() {
        return this.records;
    }

    /**
     * Caches ticks and start/end dates for faster processing during rendering of events.
     * @private
     */
    updateTickCache(onlyStartEnd = false) {
        const me = this;

        if (me.count) {
            me._start = me.first.startDate;
            me._end = me.last.endDate;
            me._startMS = me.startDate.getTime();
            me._endMS = me.endDate.getTime();
        }
        else {
            me._start = me._end = me._startMs = me._endMS = null;
        }

        // onlyStartEnd is true prior to clearing filters, to get start and end dates correctly during that process.
        // No point in filling tickCache yet in that case, it will be done after the filters are cleared
        if (!onlyStartEnd) {
            me.tickCache = {};
            me.forEach((tick, i) => me.tickCache[tick.startDate.getTime()] = i);
        }
    }

    //endregion

    //region Axis

    /**
     * Returns true if the passed date is inside the span of the current time axis.
     * @param {Date} date The date to query for
     * @return {Boolean} true if the date is part of the timeaxis
     */
    dateInAxis(date, inclusiveEnd = false) {
        const me        = this,
            axisStart = me.startDate,
            axisEnd   = me.endDate;

        // Date is between axis start/end and axis is not continuous - need to perform better lookup
        if (me.isContinuous) {
            return inclusiveEnd ? DH.betweenLesserEqual(date, axisStart, axisEnd) : DH.betweenLesser(date, axisStart, axisEnd);
        }
        else {
            let length = me.getCount(), tickStart, tickEnd, tick;

            for (let i = 0; i < length; i++) {
                tick = me.getAt(i);
                tickStart = tick.startDate;
                tickEnd = tick.endDate;

                if ((inclusiveEnd && date <= tickEnd) || (!inclusiveEnd && date < tickEnd)) {
                    return date >= tickStart;
                }
            }
        }

        return false;
    }

    /**
     * Returns true if the passed timespan is part of the current time axis (in whole or partially).
     * @param {Date} start The start date
     * @param {Date} end The end date
     * @return {Boolean} true if the timespan is part of the timeaxis
     */
    timeSpanInAxis(start, end) {
        const me = this;

        if (end.getTime() === start.getTime()) {
            return this.dateInAxis(start, true);
        }

        if (me.isContinuous) {
            return DH.intersectSpans(start, end, me.startDate, me.endDate);
        }

        return (start < me.startDate && end > me.endDate) || me.getTickFromDate(start) !== me.getTickFromDate(end);
    }

    // Accepts a TimeSpan model (uses its cached MS values to be a bit faster during rendering)
    isTimeSpanInAxis(timeSpan) {
        const me    = this,
            startMS = timeSpan.startDateMS,
            endMS   = timeSpan.endDateMS;

        // only consider fully scheduled ranges
        if (!startMS || !endMS) return false;

        if (endMS === startMS) {
            return this.dateInAxis(timeSpan.startDate, true);
        }

        if (me.isContinuous) {
            return endMS > me.startMS && startMS < me.endMS;
        }

        return (startMS < me.startMS && endMS > me.endMS) || me.getTickFromDate(timeSpan.startDate) !== me.getTickFromDate(timeSpan.endDate);
    }

    //endregion

    //region Iteration

    /**
     * Calls the supplied iterator function once per interval. The function will be called with three parameters, start date and end date and an index.
     * @internal
     * @param {String} unit The unit to use when iterating over the timespan
     * @param {Number} increment The increment to use when iterating over the timespan
     * @param {Function} iteratorFn The function to call
     * @param {Object} [thisObj] `this` reference for the function
     */
    forEachAuxInterval(unit, increment = 1, iteratorFn, thisObj = this) {
        let end = this.endDate,
            dt  = this.startDate,
            i   = 0,
            intervalEnd;

        if (dt > end) throw new Error('Invalid time axis configuration');

        while (dt < end) {
            intervalEnd = DH.min(DH.getNext(dt, unit, increment, this.weekStartDay), end);
            iteratorFn.call(thisObj, dt, intervalEnd, i);
            dt = intervalEnd;
            i++;
        }
    }

    //endregion
}
TimeAxis._$name = 'TimeAxis';