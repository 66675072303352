var productName = 'scheduler';import Base from '../../../Common/Base.js';
// TODO: prevent multiple rerenders

/**
 * @module Grid/view/mixin/GridState
 */

/**
 * Mixin for Grid that handles state. It serializes the following grid properties:
 *
 * * rowHeight
 * * readOnly
 * * selectedCell
 * * selectedRecords
 * * columns (order, widths, visibility)
 * * store (sorters, groupers, filters)
 * * scroll position
 *
 * See {@link Common.mixin.State} for more information on state.
 *
 * @demo state
 * @externalexample grid/State.js
 * @mixin
 */
export default Target => class GridState extends (Target || Base) {
    /**
     * Get grids current state for serialization. State includes rowHeight, headerHeight, readOnly, selectedCell,
     * selectedRecordId, column states and store state.
     * @returns {Object} State object to be serialized
     * @private
     */
    getState() {
        const
            me    = this,
            style = me.element.style.cssText,
            state = {
                rowHeight : me.rowHeight,
                readOnly  : me.readOnly
            };

        if (style) {
            state.style = style;
        }

        if (me.selectedCell) {
            // TODO: Create wrapper class to avoid JSON.stringify recursion in state.selectedCell.
            const { id, columnId } = me.selectedCell;
            state.selectedCell = { id, columnId };
        }

        state.selectedRecords = me.selectedRecords.map(entry => entry.id);
        state.columns = me.columns.map(column => column.getState());
        state.store = me.store.state;
        state.scroll = me.storeScroll();
        state.width = {};
        state.collapsed = {};
        me.eachSubGrid(subGrid => {
            if (subGrid.flex == null) {
                state.width[subGrid.region] = subGrid.width;
            }

            state.collapsed[subGrid.region] = subGrid.collapsed;
        });

        return state;
    }

    /**
     * Apply previously stored state.
     * @param {Object} state
     * @private
     */
    applyState(state) {
        const me = this;
        if ('readOnly' in state) {
            me.readOnly = state.readOnly;
        }

        if ('rowHeight' in state) {
            me.rowHeight = state.rowHeight;
        }

        if ('style' in state) {
            me.style = state.style;
        }

        if ('selectedCell' in state) {
            me.selectedCell = state.selectedCell;
        }

        if ('store' in state) {
            me.store.state = state.store;
        }

        if ('selectedRecords' in state) {
            me.selectedRecords = state.selectedRecords;
        }

        if ('columns' in state) {
            // each column triggers rerender at least once...
            state.columns.forEach(columnState => {
                const column = me.columns.getById(columnState.id);
                if (column) {
                    column.applyState(columnState);
                    column.stateSortIndex = columnState.index;
                }
            });

            me.eachSubGrid(subGrid => {
                subGrid.columns.records.sort((a, b) => a.stateSortIndex - b.stateSortIndex);
                subGrid.columns.allRecords.sort((a, b) => a.stateSortIndex - b.stateSortIndex);
            });
            me.columns.sort({
                fn : (a, b) => a.stateSortIndex - b.stateSortIndex
            // always sort ascending
            }, true);

            // manually triggered rerender
            me.rendered ? me.renderContents() : me.render();
        }

        if ('width' in state) {
            me.eachSubGrid(subGrid => {
                if (subGrid.region in state.width) {
                    subGrid.width = state.width[subGrid.region];
                }
            });
        }

        if ('collapsed' in state) {
            me.eachSubGrid(subGrid => {
                subGrid.collapsed = state.collapsed[subGrid.region];
            });
        }

        if ('scroll' in state) {
            me.restoreScroll(state.scroll);
        }
    }

    // This does not need a className on Widgets.
    // Each *Class* which doesn't need 'b-' + constructor.name.toLowerCase() automatically adding
    // to the Widget it's mixed in to should implement thus.
    get widgetClass() {
    }
};
