var productName = 'scheduler';import Base from '../../Common/Base.js';
// eslint-disable-next-line import/no-named-default
import { default as DH } from '../../Common/helper/DateHelper.js';

const clonedConfigs = [
    'rowHeight',
    'tickWidth',
    'tickHeight',
    'displayDateFormat',
    'shiftUnit',
    'shiftIncrement',
    'defaultSpan',
    'timeResolution',
    'columnLinesFor',
    'headerConfig'
];

/**
 * @module Scheduler/preset/ViewPreset
 */

/**
 * Not used directly, but the properties below are rather provided inline as seen in the source of {@link Scheduler.preset.PresetManager}. This class is just provided for documentation purposes.
 *
 * A sample preset looks like:
 * ```javascript
 * hourAndDay : {
 *   tickWidth           : 60,       // Time column width in horizontal mode
 *   tickHeight          : 30,       // Time column height in vertical mode
 *
 *   displayDateFormat   : 'HH:mm',  // Controls how dates will be displayed in tooltips etc
 *
 *   shiftIncrement      : 1,        // Controls how much time to skip when calling shiftNext and shiftPrevious.
 *   shiftUnit           : 'day',    // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
 *   defaultSpan         : 12,       // By default, if no end date is supplied to a view it will show 12 hours
 *
 *   timeResolution      : {         // Dates will be snapped to this resolution
 *     unit        : 'minute',       // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
 *     increment   : 15
 *   },
 *
 *   headerConfig        : {         // This defines your header, you must include a 'middle' object, and top/bottom are optional.
 *     middle : {                    // For each row you can define 'unit', 'increment', 'dateFormat', 'renderer', 'align', and 'thisObj'
 *       unit        : 'hour',
 *       dateFormat  : 'HH:mm'
 *     },
 *     top    : {
 *       unit        : 'day',
 *       dateFormat  : 'ddd DD/MM'
 *     }
 *   },
 *
 *   columnLinesFor      : 'middle'  // Defines header level column lines will be drawn for
 * }
 * ```
 * See the {@link Scheduler.preset.PresetManager} for the list of available presets.
 *
 * ## Duration units
 *
 * There are a number of configs for duration units ({@link #config-shiftUnit} or `unit` in {@link #config-timeResolution}, {@link #config-headerConfig} configs).
 *
 * When registering a preset using {@link Scheduler.preset.PresetManager#function-registerPreset-static} method you can provide their values using short constants names ('day', 'week' etc.):
 * ```javascript
 * PresetManager.registerPreset('hour', {
 *   displayDateFormat : 'HH:mm',
 *   shiftIncrement    : 1,
 *   shiftUnit         : 'day',
 *   tickWidth         : 150,
 *   timeResolution    : {
 *     unit      : 'minute',
 *     increment : 5
 *   },
 *   headerConfig      : {
 *     middle : {
 *       unit       : 'hour',
 *       dateFormat : 'HH:mm'
 *     },
 *     top    : {
 *       unit       : 'day',
 *       dateFormat : 'ddd DD/MM'
 *     }
 *   }
 * });
 * ```
 */
export default class ViewPreset extends Base {
    static get defaultConfig() {
        return {
            /**
             * The name of the preset by which it is registered in the Manager
             * @config {String}
             */
            name : null,

            /**
             * The height of the row in horizontal orientation
             * @config {Number}
             * @default
             */
            rowHeight : 24,

            /**
             * The width of the time tick column in horizontal orientation
             * @config {Number}
             * @default
             */
            tickWidth : 50,

            /**
             * The height of the time tick column in vertical orientation
             * @config {Number}
             * @default
             */
            tickHeight : 50,

            /**
             * Defines how dates will be formatted in tooltips etc
             * @config {String}
             * @default
             */
            displayDateFormat : 'HH:mm',

            /**
             * The unit to shift when calling shiftNext/shiftPrevious to navigate in the chart.
             * Valid values are "millisecond", "second", "minute", "hour", "day", "week", "month", "quarter", "year".
             * @config {String}
             * @default
             */
            shiftUnit : 'hour',

            /**
             * The amount to shift (in shiftUnits)
             * @config {Number}
             * @default
             */
            shiftIncrement : 1,

            /**
             * The amount of time to show by default in a view (in the unit defined by the middle header)
             * @config {Number}
             * @default
             */
            defaultSpan : 12,

            /**
             * An object containing a unit identifier and an increment variable. Example:
             * ```javascript
             * timeResolution : {
             *   unit      : "hour",  //Valid values are "millisecond", "second", "minute", "hour", "day", "week", "month", "quarter", "year".
             *   increment : 1
             * }
             * ```
             * @config {Object}
             */
            timeResolution : null,

            /**
             * An object containing one or more {@link Scheduler.preset.ViewPresetHeaderRow} rows defining how your headers shall be composed.
             * Your 'main' unit should be the middle header unit. This object can contain "bottom", "middle" and "top" header definitions. The 'middle' header is mandatory.
             * @config {Object}
             */
            headerConfig : null,

            /**
             * Defines the header level that the column lines will be drawn for. See {@link Scheduler.feature.ColumnLines}
             * @config {String}
             * @default
             */
            columnLinesFor : 'middle',

            // internal properties
            _headers : null,

            /**
             * The viewPreset to use and extend when the preset is passed as a config object.
             * @config {String}
             */
            ptype : ''
        };
    }

    construct(config) {
        super.construct(config);
        this.normalizeUnits();
    }

    normalizeUnits() {
        const
            me                               = this,
            { timeResolution, headerConfig } = me;

        // Make sure date "unit" constant specified in the preset are resolved
        Object.keys(headerConfig).forEach(o => {
            headerConfig[o].unit = DH.normalizeUnit(headerConfig[o].unit);
            if (headerConfig[o].splitUnit) {
                headerConfig[o].splitUnit = DH.normalizeUnit(headerConfig[o].splitUnit);
            }
        });

        if (timeResolution) {
            timeResolution.unit = DH.normalizeUnit(timeResolution.unit);
        }

        if (me.shiftUnit) {
            me.shiftUnit = DH.normalizeUnit(me.shiftUnit);
        }
    }

    get headers() {
        const me = this;

        if (!me._headers) {
            const
                headers = me._headers   = [],
                { top, middle, bottom } = me.headerConfig;

            top    && headers.push(top);
            middle && headers.push(middle);
            bottom && headers.push(bottom);
        }

        return me._headers;
    }

    get mainHeader() {
        return this.headerConfig.middle;
    }

    get bottomHeader() {
        return this.headers[this.headers.length - 1];
    }

    clone() {
        const config = {};

        clonedConfigs.forEach(name => {
            config[name] = this[name];
        });

        return new ViewPreset(config);
    }

    get isValid() {
        const
            me      = this,
            ownKeys = {};

        let valid = true;

        // Make sure all date "unit" constants are valid
        Object.keys(me.headerConfig).forEach(o => {
            ownKeys[o] = true;
            valid = valid && Boolean(DH.normalizeUnit(me.headerConfig[o].unit));
        });

        if (!(me.columnLinesFor in ownKeys)) {
            me.columnLinesFor = 'middle';
        }

        if (me.timeResolution) {
            valid = valid && DH.normalizeUnit(me.timeResolution.unit);
        }

        if (me.shiftUnit) {
            valid = valid && DH.normalizeUnit(me.shiftUnit);
        }

        return valid;
    }
}
ViewPreset._$name = 'ViewPreset';