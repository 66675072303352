var productName = 'scheduler';import Widget from '../../Common/widget/Widget.js';
import DomHelper from '../../Common/helper/DomHelper.js';
import DomClassList from '../../Common/helper/util/DomClassList.js';

/**
 * @module Scheduler/view/VerticalTimeAxis
 */

function isLastLevel(level, levels) {
    return level === levels.length - 1;
}

function isLastCell(level, cell) {
    return cell === level.cells[level.cells.length - 1];
}

/**
 * Widget that renders a vertical time axis. Only renders ticks in view. Used in vertical mode.
 * @extends Common/widget/Widget
 * @private
 */
export default class VerticalTimeAxis extends Widget {

    static get properties() {
        return {
            topDate    : null,
            bottomDate : null,
            levels     : []
        };
    }

    // Generates element configs for all levels defined by the current ViewPreset
    buildCells() {
        const me = this;

        // noinspection JSSuspiciousNameCombination
        return {
            className : 'b-widget b-verticaltimeaxis',
            style     : {
                height : me._height
            },
            children : me.levels.map((level, i) => (
                {
                    className : new DomClassList({
                        'b-sch-header-row'                     : 1,
                        [`b-sch-header-row-${level.position}`] : 1,
                        'b-lowest'                             : isLastLevel(i, me.levels)
                    }),
                    dataset : {
                        tickIndex      : i,
                        headerPosition : level.position
                    },
                    // Only include cells in view
                    children : level.cells.filter(cell => cell.start < me.bottomDate && cell.end > me.topDate).map(cell => ({
                        className : new DomClassList({
                            'b-sch-header-timeaxis-cell' : 1,
                            [cell.headerCellCls]         : cell.headerCellCls,
                            [`b-align-${cell.align}`]    : cell.align,
                            'b-last'                     : isLastCell(level, cell)
                        }),
                        dataset : {
                            tickIndex : cell.index
                        },
                        style : {
                            // DomHelper appends px to numeric dimensions
                            top       : cell.coord,
                            height    : cell.width,
                            minHeight : cell.width
                        },
                        children : [
                            {
                                className : 'b-sch-header-text',
                                html      : cell.value
                            }
                        ]
                    }))
                }
            ))
        };
    }

    render(targetElement) {
        super.render(targetElement);

        this.refresh(true);
    }

    get height() {
        return this._height;
    }

    // Set visible date range
    set range(range) {
        this.topDate = range.topDate;
        this.bottomDate  = range.bottomDate;
        this.refresh();
    }

    /**
     * Refresh the UI
     * @param {Boolean} [rebuild] Specify `true` to force a rebuild of the underlying header level definitions
     */
    refresh(rebuild = !this.levels.length) {
        const
            me = this,
            { columnConfig } = me.timeAxisViewModel,
            { levels }  = me;

        if (rebuild) {
            levels.length = 0;

            if (columnConfig.top) {
                levels.push({
                    position : 'top',
                    cells    : columnConfig.top
                });
            }

            if (columnConfig.middle) {
                levels.push({
                    position : 'middle',
                    cells    : columnConfig.middle
                });
            }

            if (columnConfig.bottom) {
                levels.push({
                    position : 'bottom',
                    cells    : columnConfig.bottom
                });
            }

            me._height = levels[0].cells.reduce((sum, cell) => sum += cell.width, 0);
        }

        if (!me.topDate || !me.bottomDate) {
            return;
        }

        // Boil down levels to only show what is in view
        DomHelper.sync({
            elementConfig : me.buildCells(),
            targetElement : me.element,
            useSyncId     : 'tickIndex'
        });
    }
}
VerticalTimeAxis._$name = 'VerticalTimeAxis';