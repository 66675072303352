var productName = 'scheduler';/**
 * @module Grid/feature/RowReorder
 */

import GridFeatureManager from './GridFeatureManager.js';
import DragHelper from '../../Common/helper/DragHelper.js';
import InstancePlugin from '../../Common/mixin/InstancePlugin.js';
import DomHelper from '../../Common/helper/DomHelper.js';
import Delayable from '../../Common/mixin/Delayable.js';

/**
 * Allows user to reorder rows by dragging them. To get notified about row reorder listen to `change` event
 * on the grid {@link Common.data.Store store}.
 *
 * This feature is <strong>disabled</strong> by default.
 *
 * If the grid is set to {@link Grid.view.Grid#config-readOnly}, reordering
 * is disabled.
 *
 * @extends Common/mixin/InstancePlugin
 *
 * @classtype rowReorder
 */
export default class RowReorder extends Delayable(InstancePlugin) {
    //region Init

    construct(grid, config) {
        this.grid = grid;

        super.construct(...arguments);
    }

    doDestroy() {
        this.dragHelper && this.dragHelper.destroy();

        super.doDestroy();
    }

    /**
     * Initialize drag & drop (called from render)
     * @private
     */
    init() {
        const me     = this,
            { grid } = me;

        me.dragHelper = new DragHelper({
            name           : 'rowReorder',
            mode           : 'translateXY',
            cloneTarget    : true,
            dragThreshold  : 10,
            targetSelector : '.b-grid-row',
            scrollManager  : grid.scrollManager,
            dragWithin     : grid.verticalScroller,
            outerElement   : grid.verticalScroller,

            createProxy(element) {
                const clone = element.cloneNode(true),
                    container = document.createElement('div');

                clone.removeAttribute('id');
                // The containing element will be positioned instead
                clone.style.transform = '';

                container.appendChild(clone);

                return container;
            },

            listeners : {
                beforedragstart : me.onBeforeDragStart,
                dragstart       : me.onDragStart,
                drag            : me.onDrag,
                drop            : me.onDrop,
                reset           : me.onReset,
                thisObj         : me
            }
        });

        me.relayEvents(me.dragHelper, ['beforeDragStart', 'dragStart', 'drag', 'drop', 'abort'], 'gridRow');
    }

    //endregion

    //region Plugin config

    static get pluginConfig() {
        return {
            after : ['render']
        };
    }

    //endregion

    //region Events (drop)

    onBeforeDragStart({ context }) {
        const grid          = this.grid,
            targetSubGrid = grid.regions[0],
            subGridEl     = grid.subGrids[targetSubGrid].element;

        // Disabled for touch devices until implemented fully. https://app.assembla.com/spaces/bryntum/tickets/8185-fix-row-reorder-for-touch-devices/details#
        // Only dragging enabled in the leftmost grid section
        if (this.disabled || grid.readOnly || DomHelper.isTouchEvent || !subGridEl.contains(context.element)) {
            return false;
        }

        const record = this.grid.getRecordFromElement(context.element);

        return !record.meta.specialRow;
    }

    onDragStart({ context }) {
        const me     = this,
            record = me.grid.getRecordFromElement(context.grabbed);

        if (!record) {
            throw new Error('Failed to find record for dragged element');
        }

        if (me.grid.features.cellEdit) {
            me.grid.readOnly = true;
            me.grid.features.cellEdit.cancelEditing(true);
        }

        if (me.grid.features.contextMenu) {
            me.grid.features.contextMenu.hideContextMenu(false);
        }

        me.grid.element.classList.add('b-row-reordering');

        const focusedCell = context.element.querySelector('.b-focused');
        focusedCell && focusedCell.classList.remove('b-focused');

        DomHelper.removeClasses(context.element.firstElementChild, ['b-selected', 'b-hover']);

        me.record = record;
    }

    onDrag({ context, event }) {
        const me = this,
            { store, rowManager } = me.grid;

        // Ignore if user drags outside grid area
        if (!me.dragHelper.outerElement.contains(event.target) || !event.target.closest('.b-grid-subgrid')) {
            context.valid = false;
            return;
        }

        let valid         = context.valid,
            rowElAtCursor = (event.isScroll || (event.touches && event.touches.length === 1) ? document.elementFromPoint(event.clientX, event.clientY) : event.target).closest('.b-grid-row'),
            index,
            row;

        if (rowElAtCursor) {
            const rowTop    = rowElAtCursor.getBoundingClientRect().top,
                rowHeight = rowElAtCursor.clientHeight,
                middleY   = rowTop + (rowHeight / 2);

            index = Number(rowElAtCursor.dataset.index);

            // Snap to row below if mouse is in bottom half of hovered row
            if (event.clientY > middleY) {
                index++;
            }
        }
        else {
            // We're in the empty area below last row
            index = store.count;
        }

        row = rowManager.getRow(Math.min(index, store.count - 1));

        // The row can be not found if the event fires on the subgrid body
        // erroneously which is sometime can on Chrome, but the pointer is
        // *not* below the last row, and we are not at the end of the grid
        // In this case the row for the last record will not be rendered.
        // Treat this as not a valid drop point.
        if (!row) {
            context.valid = false;
            return;
        }

        me.shouldInsertBefore = index < store.count;

        if (store.tree) {
            // For trees, prevent moving a parent into its own hierarchy
            valid = valid && !me.record.contains(store.getById(row.id));
        }

        if (me.targetRow) {
            me.targetRow.removeCls('b-grid-row-drop-position-before', 'b-grid-row-drop-position-after');
        }

        // Provide visual clue to user of the drop position
        if (me.shouldInsertBefore) {
            row.addCls('b-grid-row-drop-position-before');
        }
        else  {
            // If inserting to last spot in view
            row.addCls('b-grid-row-drop-position-after');
        }

        me.targetRow = row;

        context.valid = valid;
    }

    /**
     * Handle drop
     * @private
     */
    onDrop({ context }) {
        const me   = this,
            grid = me.grid,
            store = grid.store;

        context.asyncCleanup = context.async = true;

        context.element.classList.add('b-dropping');

        me.setTimeout(() => {

            grid.element.classList.remove('b-row-reordering');

            if (context.valid) {
                if (store.tree) {
                    const targetRecord = store.getById(me.targetRow.id);

                    targetRecord.parent.insertChild(me.record, me.shouldInsertBefore ? targetRecord : undefined);
                }
                else {
                    store.insert(me.shouldInsertBefore ? me.targetRow.dataIndex : store.count, me.record);
                }

                store.clearSorters();

                context.finalize();
            }
        }, 300);
    }

    /**
     * Clean up on reset
     * @private
     */
    onReset() {
        const me = this;

        me.grid.element.classList.remove('b-row-reordering');
        me.grid.readOnly = false;

        if (me.targetRow) {
            me.targetRow.removeCls('b-grid-row-drop-position-before', 'b-grid-row-drop-position-after');
        }

        me.targetRow = null;
    }

    //endregion

    //region Render

    /**
     * Updates DragHelper with updated headers when grid contents is rerendered
     * @private
     */
    render() {
        // columns shown, hidden or reordered
        this.init();
    }

    //endregion
}

RowReorder.featureClass = '';

RowReorder._$name = 'RowReorder'; GridFeatureManager.registerFeature(RowReorder, false);
GridFeatureManager.registerFeature(RowReorder, true, 'Gantt');
