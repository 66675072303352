var productName = 'scheduler';import Base from '../../Base.js';
import Model from '../Model.js';

/**
 * @module Common/data/mixin/TreeNode
 */

/**
 * Mixin for Model with tree node related functionality. This class is mixed into the {@link Common/data/Model} class.
 *
 * ## Adding and removing child nodes
 * ```
 * const parent = store.getById(1),
 *
 * firstBorn = parent.insertChild({
 *      name : 'Child node'
 *  }, parent.children[0]); // Insert a child at a specific place in the children array
 *
 * parent.removeChild(parent.children[0]); // Removes a child node
 * parent.appendChild({ name : 'New child node' }); // Appends a child node
 * ```
 *
 * @mixin
 */
export default Target => class TreeNode extends (Target || Base) {

    construct(store) {
        const me = this,
            { constructor } = me,
            { fieldMap } = constructor;

        // null passed to Base construct inhibits config processing.
        super.construct(null);

        if (!constructor.parentIdFieldProcessed) {
            const overriddenParentIdField = me.meta.parentIdField || constructor.parentIdField || (store && store.parentIdField);

            // Same goes for parentIdField.
            if (overriddenParentIdField && overriddenParentIdField !== fieldMap.parentId.dataSource) {
                constructor.createFieldDefinition({
                    name       : 'parentId',
                    dataSource : overriddenParentIdField
                });
            }
            constructor.parentIdFieldProcessed = true;
        }
    }

    ingestChildren(childRecord, stores = this.stores) {
        const store = stores[0],
            MyClass = this.constructor,
            { id, inProcessChildren } = this;

        if (childRecord === true) {
            if (inProcessChildren) {
                return true;
            }
            return [];
        }
        if (childRecord) {
            if (!Array.isArray(childRecord)) {
                childRecord = [childRecord];
            }
            let i = 0,
                len = childRecord.length,
                result = [],
                child;

            for (; i < len; i++) {
                child = childRecord[i];
                child = child instanceof Model ? child : (store ? store.createRecord(child) : new MyClass(child, null, null, true));
                result.push(child);

                if (inProcessChildren) {
                    // set parentId super silently, to not trigger events or be flagged as dirty
                    child.data[MyClass.parentIdField] = id;
                }
            }
            return result;
        }
    }

    /**
     * Child nodes. To allow loading children on demand, specify `children : true` in your data.
     * @member {Common.data.Model[]} children
     * @category Parent & children
     */

    /**
     * Called during creation to also turn any children into Models joined to the same stores as this model
     * @internal
     * @category Parent & children
     */
    processChildren(stores = this.stores) {
        const me = this;

        me.inProcessChildren = true;

        const children = me.ingestChildren(me.data[me.constructor.childrenField], stores);

        if (children) {
            if (children.length) {
                // We are processing a remote load
                if (me.children === true) {
                    me.children = [];
                }
                me.appendChild(children);
            }
            // Flagged for load on demand
            else if (children === true) {
                me.children = true;
            }
        }
        me.inProcessChildren = false;
    }

    /**
     * This property is `true` if this record has all expanded ancestors and is therefore
     * eligible for inclusion in a UI.
     * @property {Boolean}
     * @readonly
     * @category Parent & children
     */
    ancestorsExpanded(store) {
        const { parent } = this;

        return !parent || (parent.isExpanded(store) && parent.ancestorsExpanded(store));
    }

    /**
     * Used by stores to assess the record's collapsed/expanded state in that store.
     * @param {Common.data.Store} store
     * @category Parent & children
     */
    isExpanded(store) {
        const mapMeta = this.instanceMeta(store.id);

        // Default initial expanded/collapsed state when in the store
        // to the record's original expanded property.
        if (!mapMeta.hasOwnProperty('collapsed')) {
            mapMeta.collapsed = !this.expanded;
        }

        return !mapMeta.collapsed;
    }

    // A read-only property. It provides the initial state upon load
    // The UI's expanded/collapsed state is in the store's meta map.
    get expanded() {
        return this.data.expanded;
    }

    /**
     * Depth in the tree at which this node exists. First visual level of nodes are at level 0, their direct children at
     * level 1 and so on.
     * @property {Number}
     * @readonly
     * @category Parent & children
     */
    get childLevel() {
        return this.parent ? this.parent.childLevel + 1 : (this.isRoot ? -1 : 0);
    }

    /**
     * Is a leaf node in a tree structure?
     * @property {Boolean}
     * @readonly
     * @category Parent & children
     */
    get isLeaf() {
        return !this.children || (this.constructor.convertEmptyParentToLeaf && !this.children.length);
    }

    /**
     * Is a parent node in a tree structure?
     * @property {Boolean}
     * @readonly
     * @category Parent & children
     */
    get isParent() {
        return !this.isLeaf;
    }

    /**
     * Returns true for parent nodes with children loaded (there might still be no children)
     * @property {Boolean}
     * @readonly
     * @category Parent & children
     */
    get isLoaded() {
        return this.isParent && Array.isArray(this.children);
    }

    /**
     * Count all children (including sub-children) for a node (in its `firstStore´)
     * @member {Number}
     * @category Parent & children
     */
    get descendantCount() {
        return this.getDescendantCount();
    }

    /**
     * Count visible (expanded) children (including sub-children) for a node (in its `firstStore`)
     * @member {Number}
     * @category Parent & children
     */
    get visibleDescendantCount() {
        return this.getDescendantCount(true);
    }

    /**
     * Count visible (expanded)/all children for this node, optionally specifying for which store.
     * @param {Boolean} [onlyVisible] Specify `true` to only count visible (expanded) children.
     * @param {Common.data.Store} [store] A Store to which this node belongs
     * @returns {Number}
     * @category Parent & children
     */
    getDescendantCount(onlyVisible = false, store = this.firstStore) {
        const children = this.children;

        if (!children || !Array.isArray(children) || (onlyVisible && !this.isExpanded(store))) {
            return 0;
        }

        return children.reduce((count, child) => count + child.getDescendantCount(onlyVisible), children.length);
    }

    /**
     * Retrieve all children (by traversing sub nodes)
     * @returns {Common.data.Model[]}
     * @category Parent & children
     */
    get allChildren() {
        const children = this.children;
        if (!children) return [];

        return children.reduce((all, child) => {
            all.push(child);

            // push.apply is faster than push with array spread:
            // https://jsperf.com/push-apply-vs-push-with-array-spread/1
            all.push.apply(all, child.allChildren);
            return all;
        }, []);
    }

    /**
     * Get the first child of this node
     * @returns {Common.data.Model}
     * @category Parent & children
     */
    get firstChild() {
        const children = this.children;

        return (children && children.length && children[0]) || null;
    }

    /**
     * Get the last child of this node
     * @returns {Common.data.Model}
     * @category Parent & children
     */
    get lastChild() {
        const children = this.children;

        return (children && children.length && children[children.length - 1]) || null;
    }

    /**
     * Returns count of all preceding sibling nodes (including their children).
     * @property {Number}
     * @category Parent & children
     */
    get previousSiblingsTotalCount() {
        let task  = this.previousSibling,
            count = this.parentIndex;

        while (task) {
            count += task.descendantCount;
            task = task.previousSibling;
        }

        return count;
    }

    get root() {
        return this.parent && this.parent.root || this;
    }

    /**
     * Traverses all child nodes recursively calling the passed function
     * on a target node **before** iterating the child nodes.
     * @param fn
     * @category Parent & children
     */
    traverse(fn, skipSelf = false) {
        const me = this;

        if (!skipSelf) {
            fn.call(me, me);
        }
        if (me.isLoaded) me.children.forEach(child => child.traverse(fn));
    }

    /**
     * Traverses all child nodes recursively calling the passed function
     * on child nodes of a target **before** calling it it on the node.
     * @param fn
     * @category Parent & children
     */
    traverseBefore(fn, skipSelf = false) {
        const me = this;

        if (me.isLoaded) {
            me.children.forEach(child => child.traverse(fn));
        }
        if (!skipSelf) {
            fn.call(me, me);
        }
    }

    /**
     * Traverses child nodes recursively while fn returns true
     * @param {Function} fn
     * @category Parent & children
     * @returns {Boolean}
     */
    traverseWhile(fn, skipSelf = false) {
        const me = this;

        let goOn = true;

        if (!skipSelf) {
            goOn = fn.call(me, me) !== false;
        }

        if (goOn && me.isLoaded) {
            goOn = me.children.every(child => child.traverseWhile(fn));
        }

        return goOn;
    }

    /**
     * Bubbles up from this node, calling the specified function with each node.
     *
     * @param {Function} fn
     * @category Parent & children
     */
    bubble(fn, skipSelf = false) {
        let me = this;

        if (!skipSelf) {
            fn.call(me, me);
        }

        while (me.parent) {
            me = me.parent;
            fn.call(me, me);
        }
    }

    /**
     * Bubbles up from this node, calling the specified function with each node,
     * while the function returns true.
     *
     * @param {Function} fn
     * @category Parent & children
     * @return {Boolean}
     */
    bubbleWhile(fn, skipSelf = false) {
        let me = this,
            goOn = true;

        if (!skipSelf) {
            goOn = fn.call(me, me);
        }

        while (goOn && me.parent) {
            me = me.parent;
            goOn = fn.call(me, me);
        }

        return goOn;
    }

    /**
     * Checks if this model contain another model as one of it's descendants
     *
     * @param {Common.data.Model|String|Number} child
     * @category Parent & children
     * @returns {Boolean}
     */
    contains(childId) {
        if (childId && typeof childId === 'object') {
            childId = childId.id;
        }
        return !this.traverseWhile(node => node.id != childId);
    }

    getTopParent(all) {
        let result;

        if (all) {
            result = [];
            this.bubbleWhile((t) => {
                result.push(t);
                return t.parent && !t.parent.isRoot;
            });
        }
        else {
            result = null;
            this.bubbleWhile((t) => {
                if (!t.parent) {
                    result = t;
                }
                return t.parent && !t.parent.isRoot;
            });
        }

        return result;
    }

    /**
     * Append a child record(s) to any current children.
     * @param {Common.data.Model|Common.data.Model[]} childRecord
     * @param {Boolean} [silent] Specify true to suppress events
     * @returns {Common.data.Model|Common.data.Model[]}
     * @category Parent & children
     */
    appendChild(childRecord, silent = false) {
        return this.insertChild(childRecord, null, silent);
    }

    /**
     * Insert a child record(s) before an existing child record.
     * @param {Common.data.Model|Common.data.Model[]} childRecord
     * @param {Common.data.Model} [before]
     * @param {Boolean} [silent] Specify true to suppress events
     * @returns {Common.data.Model|Common.data.Model[]}
     * @category Parent & children
     */
    insertChild(childRecord, before, silent = false) {
        // Handle deprecated signature
        if (typeof childRecord === 'number') {
            const index = childRecord;
            childRecord = before;
            before = this.children[index];
        }

        const
            me          = this,
            wasLeaf     = me.isLeaf,
            returnArray = Array.isArray(childRecord);

        if (!silent) {
            if (!me.stores.every(s => s.trigger('beforeAdd', { records : childRecord, parent : me }) !== false)) {
                return null;
            }
        }

        // This call makes child record an array containing Models
        childRecord = me.ingestChildren(childRecord);

        // NOTE: see comment in Model::set() about before/in/after calls approach.
        const
            index     = before ? before.parentIndex : me.children ? me.children.length : 0,
            preResult = me.beforeInsertChild ? me.beforeInsertChild(childRecord) : undefined,
            inserted  = me.internalAppendInsert(childRecord, before, silent);

        // If we've transitioned to being a branch node, signal a change event
        // so that the UI updates.
        // Not if it's due to root node loading. StoreTree#onNodeAddChild
        // for the rootNode will fire a store refresh.
        if (me.isLeaf !== wasLeaf && !me.root.isLoading && !silent) {
            me.stores.forEach(s => {
                const changes = {
                    isLeaf : false
                };
                s.trigger('update', { record : me, changes });
                s.trigger('change', { action : 'update', record : me, changes });
            });
        }

        me.afterInsertChild && me.afterInsertChild(index, childRecord, preResult, inserted);

        return (returnArray || !inserted) ? inserted : inserted[0];
    }

    internalAppendInsert(newRecords, before, silent) {
        const
            me = this,
            { stores } = me,
            isMove = {};

        let isNoop, start, i, newRecordsCloned;

        // The reference node must be one of our children. If not, fall back to an append.
        if (before && before.parent !== me) {
            before = null;
        }

        // If the records starting at insertAt or (insertAt - 1), are the same sequence
        // that we are being asked to add, this is a no-op.
        if (me.children) {
            const
                children = me.children,
                insertAt = before ? before.parentIndex : children.length;

            if (children[start = insertAt] === newRecords[0] || children[start = insertAt - 1] === newRecords[0]) {
                for (isNoop = true, i = 0; isNoop && i < newRecords.length; i++) {
                    if (newRecords[i] !== children[start + i]) {
                        isNoop = false;
                    }
                }
            }
        }

        // Fulfill the contract of appendChild/insertChild even if we did not have to do anything.
        // Callers must be able to correctly postprocess the returned value as an array.
        if (isNoop) {
            return newRecords;
        }

        // Remove incoming child nodes from any current parent.
        for (i = 0; i < newRecords.length; i++) {
            const newRecord = newRecords[i];

            // Store added should not be modified for adds
            // caused by moving.
            isMove[newRecord.id] = newRecord.root === me.root;

            // Remove from old parent before updating the new node's parentId.
            // This operation may be vetoed by listeners.
            // If it is vetoed, then remove from the newRecords and do not
            // change its parentId
            if (newRecord.parent && newRecord.parent.removeChild(newRecord, isMove[newRecord.id]) === false) {
                if (!newRecordsCloned) {
                    newRecords = newRecords.slice();
                    newRecordsCloned = true;
                }
                newRecords.splice(i--, 1);
            }
            else {
                // If we are in the recursive inclusion of children at construction
                // time, that must not be a data modification.
                if (!me.isAutoRoot) {
                    if (me.inProcessChildren) {
                        newRecord.setData(me.constructor.parentIdField, me.id);
                    }
                    else {
                        newRecord.set(me.constructor.parentIdField, me.id);
                    }
                }
                // Adding to root, set parentId to null
                else {
                    if (me.inProcessChildren || me.isLoading) {
                        newRecord.setData(me.constructor.parentIdField, null);
                    }
                    else {
                        newRecord.set(me.constructor.parentIdField, null);
                    }
                }

                newRecord.parent = me;
            }
        }

        // Still records to insert after beforeRemove listeners may have vetoed some
        if (newRecords.length) {
            const
                children = me.children || (me.children = []),

                // Collect index again after removal from old parent in case it's a move within the
                // same parent.
                insertAt = before ? before.parentIndex : children.length;

            // Insert the new records into the children array
            children.splice(insertAt, 0, ...newRecords);

            // Fix up pointers
            me.fixChildrensParentIndex();

            stores.forEach(store => {
                if (!store.chained) {

                    newRecords.forEach(newRecord => {
                        newRecord.instanceMeta(store.id).collapsed = !newRecord.expanded;
                        newRecord.joinStore(store);
                    });

                    // Add to store (will also add any child records and trigger events)
                    store.onNodeAddChild(me, newRecords, insertAt, isMove, silent);
                }
            });
        }

        return newRecords;
    }

    /**
     * Remove a child record. Only direct children of this node can be removed, others are ignored.
     * @param {Common.data.Model|Common.data.Model[]} childRecords The record(s) to remove
     * @param {Boolean} [isMove] Pass `true` if the record is being moved within the same store.
     * @category Parent & children
     */
    removeChild(childRecords, isMove) {
        const me = this,
            wasLeaf = me.isLeaf,
            { children, stores } = me;

        if (!Array.isArray(childRecords)) {
            childRecords = [childRecords];
        }

        childRecords = childRecords.filter(r => r.parent === me);

        // Allow store listeners to veto the beforeRemove event
        for (const store of stores) {
            if (!store.chained) {
                if (store.trigger('beforeRemove', { parent : me, records : childRecords, isMove }) === false) {
                    return false;
                };
            }
        };

        const preResult = me.beforeRemoveChild ? me.beforeRemoveChild(childRecords, isMove) : undefined;

        for (const childRecord of childRecords) {

            const index = childRecord.parentIndex;

            children.splice(index, 1);
            me.fixChildrensParentIndex();

            stores.forEach(store => {
                if (!store.chained) {
                    store.onNodeRemoveChild(me, [childRecord], index, isMove);
                }
            });

            childRecord.parent = childRecord.parentIndex = childRecord.nextSibling = childRecord.previousSibling = null;
        }

        // If we've transitioned to being a leaf node, signal a change event
        // so that the UI updates
        if (me.isLeaf !== wasLeaf) {
            me.stores.forEach(s => {
                const changes = {
                    isLeaf : true
                };
                s.trigger('update', { record : me, changes });
                s.trigger('change', { action : 'update', record : me, changes });
            });
        }

        me.afterRemoveChild && me.afterRemoveChild(childRecords, preResult, isMove);
    }

    clearChildren() {
        const me = this,
            { children, stores } = me;

        if (children) {
            me.children = [];
            stores.forEach(store => {
                if (!store.chained) {
                    store.onNodeRemoveChild(me, children, 0);
                }
            });
        }
    }

    fixChildrensParentIndex() {
        const { children } = this;

        let previousSibling;
        for (let i = 0; i < children.length; i++) {
            const child = children[i];

            child.parentIndex = i;
            child.previousSibling = previousSibling;
            if (previousSibling) {
                previousSibling.nextSibling = child;
            }
            // Last child never has a nextSibling
            if (i === children.length - 1) {
                child.nextSibling = null;
            }
            previousSibling = child;
        }
    }
};
