var productName = 'scheduler';//region Import

import Base from '../../Common/Base.js';
import BryntumWidgetAdapterRegister from '../../Common/adapter/widget/util/BryntumWidgetAdapterRegister.js';
import AjaxStore from '../../Common/data/AjaxStore.js';
import DomDataStore from '../../Common/data/DomDataStore.js';
import Store from '../../Common/data/Store.js';
import BrowserHelper from '../../Common/helper/BrowserHelper.js';
import DomHelper from '../../Common/helper/DomHelper.js';
import { base } from '../../Common/helper/MixinHelper.js';
import VersionHelper from '../../Common/helper/VersionHelper.js';
import '../../Common/data/Model.js';
import TemplateHelper from '../../Common/helper/TemplateHelper.js';

import LocaleManager from '../../Common/localization/LocaleManager.js';
import Pluggable from '../../Common/mixin/Pluggable.js';
import State from '../../Common/mixin/State.js';
import Widget from '../../Common/widget/Widget.js';
import ColumnStore, { columnResizeEvent } from '../data/ColumnStore.js';
import GridRowModel from '../data/GridRowModel.js';
import RowManager from '../row/RowManager.js';
import ScrollManager from '../util/ScrollManager.js';
import GridScroller from '../util/GridScroller.js';
import Header from './Header.js';
import '../../Common/mixin/Events.js';
import Rectangle from '../../Common/helper/util/Rectangle.js';
import WidgetHelper from '../../Common/helper/WidgetHelper.js';
import GlobalEvents from '../../Common/GlobalEvents.js';

import GridElementEvents from './mixin/GridElementEvents.js';
import GridFeatures from './mixin/GridFeatures.js';
import GridNavigation from './mixin/GridNavigation.js';
import GridResponsive from './mixin/GridResponsive.js';
import GridSelection from './mixin/GridSelection.js';
import GridState from './mixin/GridState.js';
import GridSubGrids from './mixin/GridSubGrids.js';

// import default features (might be able to skip this when draft on dynamic import is implemented)
import '../feature/CellEdit.js';
import '../feature/ColumnDragToolbar.js';
import '../feature/ColumnPicker.js';
import '../feature/ColumnReorder.js';
import '../feature/ColumnResize.js';
import '../feature/ContextMenu.js';
import '../feature/Filter.js';
import '../feature/FilterBar.js';
import '../feature/Group.js';
import '../feature/Sort.js';
import '../feature/Stripe.js';
import EventHelper from '../../Common/helper/EventHelper.js';
import ObjectHelper from '../../Common/helper/ObjectHelper.js';
import Column from '../column/Column.js';

//endregion

/**
 * @module Grid/view/Grid
 */

const resolvedPromise = new Promise(resolve => resolve()),
    defaultScrollOptions = {
        block  : 'nearest',
        inline : 'nearest'
    };

/**
 * The Grid component is a very powerful and performant UI component that shows tabular data (or tree data using the {@link Grid.view.TreeGrid}).
 *
 * <h2>Intro</h2>
 * The Grid widget has a wide range of features and a large API to allow users to work with data efficiently in the browser. The two
 * most important configs are {@link #config-store} and {@link #config-columns}. With the store config, you decide which data to load into the grid.
 * You can work with both in-memory arrays or load data using ajax. See the {@link Common.data.Store} class to learn more about loading data into stores.
 *
 * The columns config accepts an array of {@link Grid.column.Column Column} descriptors defining which fields that will be displayed in the grid.
 * The {@link Grid.column.Column#config-field} property in the column descriptor maps to a field in your dataset. The simplest grid configured with inline data and two columns would
 * look like this:
 *
 *      let grid = new Grid({
 *          appendTo : document.body,
 *
 *          columns: [
 *              { field: 'name', text: 'Name' },
 *              { field: 'job', text: 'Job', renderer: ({value}) => value ? value : 'Unemployed' }
 *          ],
 *
 *          data: [
 *              { name: 'Bill', job: 'Retired' },
 *              { name: 'Elon', job: 'Visionary' },
 *              { name: 'Me' }
 *          ]
 *      });
 *
 * {@inlineexample grid/Grid.js}
 * <h2>Features</h2>
 * To avoid the Grid core being bloated, its main features are implemented in separate ´feature´ classes. These can be turned on and off based
 * on your requirements. To configure (or disable) a feature, use the {@link #config-features} object to provide your desired configuration for the features
 * you want to use. Each feature has an ´id´ that you use as a key in the features object:
 *
 *      let grid = new Grid({
 *          appendTo : document.body,
 *
 *          features : {
 *              cellEdit     : false,
 *              regionResize : true,
 *              cellTooltip  : {
 *                  tooltipRenderer : (data) => {
 *                  }
 *              },
 *              ...
 *          }
 *      });
 *
 * {@region Column configuration options}
 * A grid contains a number of columns that control how your data is rendered. The simplest option is to simply point a Column to a field in your dataset, or define a custom {@link Grid.column.Column#config-renderer}.
 * The renderer function receives one object parameter containing rendering data for the current cell being rendered.
 *
 *      let grid = new Grid({
 *          appendTo : document.body,
 *
 *          columns: [
 *              {
 *                  field: 'task',
 *                  text: 'Task',
 *                  renderer: (renderData) => {
 *                      const record = renderData.record;
 *
 *                      if (record.percentDone === 100) {
 *                          renderData.cellElement.classList.add('taskDone');
 *                          renderData.cellElement.style.background = 'green';
 *                      }
 *
 *                      return renderData.value;
 *                  }
 *              }
 *          ]
 *      });
 *
 * {@endregion}
 * {@region Grid sections (aka "locked" or "frozen" columns)}
 * The grid can be divided horizontally into individually scrollable sections. This is great if you have lots of columns that
 * don't fit the available width of the screen. To enable this feature, simply mark the columns you want to `lock`.
 * Locked columns are then displayed in their own section to the left of the other columns:
 *
 *      let grid = new Grid({
 *          appendTo : document.body,
 *          width    : 500,
 *          subGridConfigs : {
 *              // set a fixed locked section width if desired
 *              locked : { width: 300 }
 *          },
 *          columns : [
 *              { field : 'name', text : 'Name', width : 200, locked : true },
 *              { field : 'firstName', text : 'First name', width : 100, locked : true },
 *              { field : 'surName', text : 'Last name', width : 100, locked : true },
 *              { field : 'city', text : 'City', width : 100 },
 *              { type : 'number', field : 'age', text : 'Age', width : 200 },
 *              { field : 'food', text : 'Food', width : 200 }
 *          ]
 *      });
 *
 * {@inlineexample grid/LockedGrid.js}
 * You can also move columns between sections by using drag and drop, or use the built-in header context menu. If you want to be able to resize the
 * locked grid section, enable the {@link Grid.feature.RegionResize regionResize} feature.
 * {@endregion}
 * {@region Filtering}
 * One important requirement of a good Grid component is the ability to filter large datasets to quickly find what you're looking for. To
 * enable filtering (through the context menu), add the {@link Grid.feature.Filter filter} feature:
 *
 *      let grid = new Grid({
 *          features: {
 *              filter: true
 *          }
 *      });
 *
 * Or activate a default filter at initial rendering:
 *
 *      let grid = new Grid({
 *          features: {
 *              filter: { property : 'city', value : 'New York' }
 *          }
 *      });
 *
 * {@inlineexample feature/Filter.js}
 * {@endregion}
 * {@region Tooltips}
 * If you have a data models with many fields, and you want to show
 * additional data when hovering over a cell, use the {@link Grid.feature.CellTooltip cellTooltip} feature. To show a tooltip for all cells:
 *
 *      let grid = new Grid({
 *          features: {
 *              cellTooltip: ({value}) => value
 *          }
 *      });
 *
 * {@inlineexample feature/CellTooltip.js}
 * {@endregion}
 * {@region Inline Editing (default <strong>on</strong>)}
 * To enable inline cell editing in the grid, simply add the {@link Grid.feature.CellEdit cellEdit} feature:
 *
 *      let grid = new Grid({
 *          appendTo : document.body,
 *
 *          features : {
 *              cellEdit : true
 *          },
 *          columns: [
 *              {
 *                  field: 'task',
 *                  text: 'Task'
 *              }
 *          ]
 *      });
 *
 * {@inlineexample feature/CellEdit.js}
 * {@endregion}
 * {@region Context Menu}
 * Use the {@link Grid.feature.ContextMenu contextMenu} feature if you want your users to be able to interact with the data through the context menu:
 *
 *      let grid = new Grid({
 *          features: {
 *              contextMenu: {
 *                  headerItems: [
 *                      {
 *                          text: 'Show info',
 *                          icon: 'fa fa-info-circle',
 *                          weight: 200,
 *                          onItem : ({ item }) => console.log(item.text)
 *                      }
 *                  ],
 *
 *              cellItems: [
 *                  { text: 'Show options', icon: 'fa fa-gear', weight: 200 }
 *              ]
 *          }
 *      }
 *
 * {@inlineexample feature/ContextMenu.js}
 * {@endregion}
 * {@region Grouping}
 * To group rows by a field in your dataset, use the {@link Grid.feature.Group group} feature.
 * {@inlineexample feature/Group.js}
 * {@endregion}
 * {@region Searching}
 * When working with lots of data, a quick alternative to filtering is the {@link Grid.feature.Search search} feature. It highlights
 * matching values in the grid as you type.
 * {@inlineexample feature/Search.js}
 * {@endregion}
 * {@region Loading and saving data}
 * The grid keeps all its data in a {@link Common.data.Store}, which is essentially an Array of {@link Common.data.Model Model} items.
 * You define your own Model representing your data entities and use the Model API to get and set values.
 *
 *      class Person extends Model {}
 *
 *      let person = new Person({
 *          name: 'Steve',
 *          age: 38
 *      });
 *
 *      person.name = 'Linda'; // person object is now `dirty`
 *
 *      let store = new Store({
 *          data : [
 *              { name : 'Don', age : 40 }
 *          ]
 *      });
 *
 *      store.add(person);
 *
 *      console.log(store.count()); // === 2
 *
 *      store.remove(person); // Remove from store
 *
 * When you update a record in a store, it's considered dirty, until you call {@link Common.data.mixin.StoreCRUD#function-commit commit} on the containing Store. You can also configure your Store to commit automatically (like Google docs).
 * If you use an AjaxStore, it will send changes to your server when commit is called.
 * Any changes you make to the Store or its records are immediately reflected in the Grid, so there is no need to tell it to refresh manually.
 *
 * To learn more about loading and saving data, please refer to [this guide](#guides/data/displayingdata.md).
 * {@endregion}
 * {@region Default configs}
 * There is a myriad of configs and features available for Grid, some of them on by default and some of them requiring
 * extra configuration. The code below tries to illustrate the major things that are used by default:
 *
 * ```javascript
 * let grid = new Grid({
 *    // The following features are enabled by default:
 *    features : {
 *        cellEdit      : true,
 *        columnPicker  : true,
 *        columnReorder : true,
 *        columnResize  : true,
 *        contextMenu   : true,
 *        group         : true,
 *        sort          : true
 *    },
 *
 *    animateRemovingRows       : true,  // Rows will slide out on removal
 *    autoHeight                : false, // Grid needs to have a height supplied through CSS (strongly recommended) or by specifying `height`
 *    columnLines               : true,  // Themes might override it to hide lines anyway
 *    emptyText                 : 'No rows to display',
 *    enableTextSelection       : false, // Not allowed to select text in cells by default,
 *    fillLastColumn            : true,  // By default the last column is stretched to fill the grid
 *    fullRowRefresh            : true,  // Refreshes entire row when a cell value changes
 *    loadMask                  : 'Loading...',
 *    resizeToFitIncludesHeader : true,  // Also measure header when auto resizing columns
 *    responsiveLevels : {
 *      small : 400,
 *      medium : 600,
 *      large : '*'
 *    },
 *    rowHeight                 : null,  // Determined using CSS, it will measure rowHeight
 *    showDirty                 : false, // No indicator for changed cells
 *    showRemoveInContextMenu   : true   // Context menu has "Remove row" item
 * });
 * ```
 * {@endregion}
 * {@region Performance}
 * In general the Grid widget has very good performance and you can try loading any amount of data in the <a target="_blank" href="../examples/bigdataset">bigdataset</a> demo.
 * The overall rendering performance is naturally affected by many other things than
 * the data volume. Other important factors that can impact performance: number of columns, complex cell renderers, locked columns, the number of features enabled
 * and of course the browser (Chrome fastest, IE slowest).
 * {@endregion}
 *
 * @extends Common/widget/Widget
 * @mixes Common/mixin/Events
 * @mixes Common/mixin/Pluggable
 * @mixes Common/mixin/State
 * @mixes Grid/view/mixin/GridElementEvents
 * @mixes Grid/view/mixin/GridFeatures
 * @mixes Grid/view/mixin/GridResponsive
 * @mixes Grid/view/mixin/GridSelection
 * @mixes Grid/view/mixin/GridState
 * @mixes Grid/view/mixin/GridSubGrids
 *
 * @classType grid
 */
export default class Grid extends base(Widget).mixes(
    Pluggable,
    State,
    GridElementEvents,
    GridFeatures,
    GridNavigation,
    GridResponsive,
    GridSelection,
    GridState,
    GridSubGrids
) {
    //region Config

    // Default settings, applied in grids constructor.
    static get defaultConfig() {
        return {
            /**
             * Row height in pixels. When set to null, an empty row will be measured and its height will be used as
             * default row height, enabling it to be controlled using CSS
             * @config {Number}
             * @default null
             * @category Common
             */
            rowHeight : null,

            // used if no rowHeight specified and none found in CSS. not public since our themes have row height
            // specified and this is more of an internal failsafe
            defaultRowHeight : 45,

            /**
             * Text to display when there is no data to display in the grid
             * @config {String}
             * @default
             * @category Common
             */
            emptyText : this.L('noRows'),

            /**
             * Refresh entire row when a cells value changes (true) or only the cell (false).
             * @config {Boolean}
             * @default
             * @category Misc
             */
            fullRowRefresh : true,

            /**
             * Read only or not
             * @config {Boolean}
             * @default false
             * @category Common
             */
            readOnly : null,

            /**
             * True to not create any grid column headers
             * @config {Boolean}
             * @default false
             * @category Misc
             */
            hideHeaders : null,

            /**
             * Show "Remove row" item in context menu (if enabled and grid not read only)
             * @config {Boolean}
             * @default
             * @category Misc
             */
            showRemoveRowInContextMenu : true,

            /**
             * Automatically set grids height to fit all rows (no scrolling in the grid)
             * @config {Boolean}
             * @default false
             * @category Layout
             */
            autoHeight : null,

            /**
             * Store that holds records to display in the grid, or a store config object.
             * A store will be created if none is specified
             * @config {Common.data.Store/Object}
             * @category Common
             */
            store : {},

            /**
             * Data to set in grids store (a Store will be created if none is specified)
             * @config {Object[]}
             * @category Common
             */
            data : null,

            /**
             * Column definitions for the grid, will be used to create Column instances that are added to a ColumnStore.
             * This store can be accessed using {@link #property-columns}
             *
             * ```
             * grid.columns.add({ field : 'column', text : 'New column' });
             * ```
             * @config {Object[]}
             * @category Common
             */
            columns : [],

            /**
             * true to destroy the store when the grid is destroyed
             * @config {Boolean}
             * @default false
             * @category Misc
             */
            destroyStore : null,

            /**
             * Default selection settings
             * @config {Object}
             * @default
             * @category Selection
             */
            selectionMode : {
                row         : true,
                cell        : true,
                multiSelect : true,
                checkbox    : false
            },

            /**
             * Set to true to allow text selection in the grid cells
             * @config {Boolean}
             * @default false
             * @category Selection
             */
            enableTextSelection : null,

            /**
             * A message to be shown when a store is performing a remote operation.
             * @config {String}
             * @default "Loading..."
             * @category Misc
             */
            loadMask : this.L('loadMask'),

            /**
             * Set to `false` to inhibit column lines
             * @config {Boolean}
             * @default
             * @category Misc
             */
            columnLines : true,

            /**
             * Set to `true` to stretch the last column in a grid with all fixed width columns
             * to fill extra available space if the grid's width is wider than the sum of all
             * configured column widths.
             * @config {Boolean}
             * @default
             * @category Layout
             */
            fillLastColumn : true,

            /**
             * Set to `false` to only measure cell contents when double clicking the edge between column headers.
             * @config {Boolean}
             * @default
             * @category Layout
             */
            resizeToFitIncludesHeader : true,

            /**
             * Set to `false` to prevent remove row animation and remove the delay related to that.
             * @config {Boolean}
             * @default
             * @category Misc
             */
            animateRemovingRows : !BrowserHelper.isIE11, // IE11 doesn't have reliable firing of transitionend

            /**
             * Set to `true` to not get a warning when using another base class than GridRowModel for your grid data. If
             * you do, and would like to use the full feature set of the grid then include the fields from GridRowModel
             * in your model definition.
             * @config {Boolean}
             * @default false
             * @category Misc
             */
            disableGridRowModelWarning : null,

            loadMaskErrorIcon : 'b-icon b-icon-warning',

            headerClass : Header,

            testPerformance : false,
            // TODO: break out as strategies
            positionMode    : 'translate', // translate, translate3d, position
            rowScrollMode   : 'move', // move, dom, all

            /**
             * Grid monitors window resize by default.
             * @config {Boolean}
             * @default true
             * @category Misc
             */
            monitorResize : true,

            /**
             * An object containing Feature configuration objects (or `true` if no configuration is required)
             * keyed by the Feature class name in all lowercase.
             * @config {Object}
             * @category Common
             * @typings any
             */
            features : true,

            /**
             * An object containing sub grid configuration objects keyed by a `region` property.
             * By default, grid has a 'locked' region (if configured with locked columns) and a 'normal' region.
             * The 'normal' region defaults to use `flex: 1`.
             *
             * This config can be used to reconfigure the "built in" sub grids or to define your own.
             * ```
             * // Redefining the "built in" regions
             * new Grid({
             *   subGridConfigs : {
             *     locked : { flex : 1 },
             *     normal : { width : 100 }
             *   }
             * });
             *
             * // Defining your own multi region sub grids
             * new Grid({
             *   subGridConfigs : {
             *     left   : { width : 100 },
             *     middle : { flex : 1 },
             *     right  : { width  : 100 }
             *   },
             *
             *   columns : {
             *     { field : 'manufacturer', text: 'Manufacturer', region : 'left' },
             *     { field : 'model', text: 'Model', region : 'middle' },
             *     { field : 'year', text: 'Year', region : 'middle' },
             *     { field : 'sales', text: 'Sales', region : 'right' }
             *   }
             * });
             * ```
             * @config {Object}
             * @category Misc
             */
            subGridConfigs : {
                normal : { flex : 1 }
            },

            /**
             * Configures whether the grid is scrollable in the `Y` axis. This is ued to configure a {@link Grid.util.GridScroller}.
             * See the {@link #config-scrollerClass} config option.
             * @config {Object}
             * @category Scrolling
             */
            scrollable : {
                // Just Y for now until we implement a special grid.view.Scroller subclass
                // Which handles the X scrolling of subgrids.
                overflowY : true
            },

            /**
             * The class to instantiate to use as the {@link #config-scrollable}. Defaults to {@link Grid.util.GridScroller}.
             * @config {Common.helper.util.Scroller}
             * @internal
             * @category Scrolling
             */
            scrollerClass : GridScroller,

            /**
             * Configure as `true` to have the grid show a red "changed" tag in cells who's
             * field value has changed and not yet been committed.
             * @config {Boolean}
             * @default false
             * @category Misc
             */
            showDirty : null,

            loadMaskHideTimeout : 3000,

            refreshSuspended : 0

            // Grid requires a size to be considered visible
            //requireSize : true
        };
    }

    static getLKey() {
        return '%LICENSE%';
    }

    static get properties() {
        return {
            _selectedRecords      : [],
            _verticalScrollHeight : 0,
            virtualScrollHeight   : 0
        };
    }

    //endregion

    //region Init-destroy

    finishConfigure(config) {
        const me = this;

        super.finishConfigure(config);

        me.scrollManager = new ScrollManager({
            grid            : me,
            // Horizontal scrolling might happen on different subgrids, but vertical is always on same element
            verticalElement : me.scrollable.element
        });

        // When locale is applied columns react and change, which triggers `change` event on columns store for each
        // changed column, and every change normally triggers rendering view. This overhead becomes noticeable with
        // larger amount of columns. So we set two listeners to locale events: prioritized listener to be executed first
        // and suspend renderContents method and unprioritized one to resume method and call it immediately.
        LocaleManager.on({
            locale  : 'onBeforeLocaleChange',
            prio    : 1,
            thisObj : me
        });

        LocaleManager.on({
            locale  : 'onLocaleChange',
            prio    : -1,
            thisObj : me
        });

        GlobalEvents.on({
            theme   : 'onThemeChange',
            thisObj : me
        });

        // Access the property getter to ensure that all regions have been initialized
        me._thisIsAUsedExpression(me.regions);

        // Add the extra grid classes to the element
        me.setGridClassList(me.element.classList);
    }

    onBeforeLocaleChange() {
        this._suspendRenderContentsOnColumnsChanged = true;
    }

    onLocaleChange() {
        this._suspendRenderContentsOnColumnsChanged = false;
        this.rendered ? this.renderContents() : this.render();
    }

    finalizeInit() {
        super.finalizeInit();

        if (this.store.isLoading) {
            // Maybe show loadmask if store is already loading when grid is constructed
            this.onStoreLoadStart();
        }
    }

    /**
     * Cleanup
     * @private
     */
    doDestroy() {
        const me = this;

        me.storeDetacher && me.storeDetacher();

        for (let feature of Object.values(me.features)) {
            feature.destroy && feature.destroy();
        }

        if (me.columns) {
            me.columns = null;
        }

        if (me.store) {
            me.store = null;
        }

        super.doDestroy();
    }

    /**
     * Adds extra classes to the Grid element after it's been configured.
     * Also iterates through features, thus ensuring they have been initialized.
     * @private
     */
    setGridClassList(classList) {
        const me = this;

        classList.add(`b-grid-${me.positionMode}`);

        // TODO: enableTextSelection should be a setter, enabling toggling at any time
        if (!me.enableTextSelection) {
            classList.add('b-grid-notextselection');
        }

        if (me.autoHeight) {
            classList.add('b-autoheight');
        }

        if (me.readOnly) {
            classList.add('b-readonly');
        }

        if (me.fillLastColumn) {
            classList.add('b-fill-last-column');
        }

        if (me.showDirty) {
            classList.add('b-show-dirty');
        }

        for (let featureName in me.features) {
            let feature = me.features[featureName],
                featureClass;

            if (feature.constructor.hasOwnProperty('featureClass')) {
                featureClass = feature.constructor.featureClass;
            }
            else {
                featureClass = `b-${(feature instanceof Base ? feature.$name : feature.constructor.name)}`;
            }

            if (featureClass) {
                classList.add(featureClass.toLowerCase());
            }
        }
    }

    //endregion

    //region Functions & events injected by features

    // For documentation & typings purposes

    //region Feature events

    /**
     * *Only when the {@link Grid.feature.Tree} feature is enabled*.
     * <p>Fired before a record toggles its collapsed state.
     * @event beforeToggleNode
     * @param {Common.data.Model} record The record being toggled.
     * @param {Boolean} collapse `true` if the node is being collapsed.
     */
    /**
     * *Only when the {@link Grid.feature.Tree} feature is enabled*.
     * <p>Fired after a record has been collapsed.
     * @event collapseNode
     * @param {Common.data.Model} record The record which has been collapsed.
     */
    /**
     * *Only when the {@link Grid.feature.Tree} feature is enabled*.
     * <p>Fired after a record has been expanded.
     * @event expandNode
     * @param {Common.data.Model} record The record which has been expanded.
     */
    /**
     * *Only when the {@link Grid.feature.Tree} feature is enabled*.
     * <p>Fired after a record toggles its collapsed state.
     * @event toggleNode
     * @param {Common.data.Model} record The record being toggled.
     * @param {Boolean} collapse `true` if the node is being collapsed.
     */

    //endregion

    /**
     * Collapse all groups/parent nodes.
     *
     * *NOTE: Only available when the {@link Grid/feature/Group Group} or the {@link Grid/feature/Tree Tree} feature is enabled.*
     *
     * @function collapseAll
     * @category Feature shortcuts
     */

    /**
     * Expand all groups/parent nodes.
     *
     * *NOTE: Only available when the {@link Grid/feature/Group Group} or the {@link Grid/feature/Tree Tree} feature is enabled.*
     *
     * @function expandAll
     * @category Feature shortcuts
     */

    /**
     * Start editing specified cell. If no cellContext is given it starts with the first cell in the first row.
     *
     * *NOTE: Only available when the {@link Grid/feature/CellEdit CellEdit} feature is enabled.*
     *
     * @function startEditing
     * @param {Object} cellContext Cell specified in format { id: 'x', columnId/column/field: 'xxx' }. See {@link Grid.view.Grid#function-getCell} for details.
     * @returns {Boolean}
     * @category Feature shortcuts
     */

    /**
     * Collapse an expanded node or expand a collapsed. Optionally forcing a certain state.
     *
     * *NOTE: Only available when the {@link Grid/feature/Tree Tree} feature is enabled.*
     *
     * @function toggleCollapse
     * @param {String|Number|Common.data.Model} idOrRecord Record (the node itself) or id of a node to toggle
     * @param {Boolean} [collapse] Force collapse (true) or expand (false)
     * @param {Boolean} [skipRefresh] Set to true to not refresh rows (if calling in batch)
     * @returns {Promise}
     * @category Feature shortcuts
     */

    /**
     * Collapse a single node.
     *
     * *NOTE: Only available when the {@link Grid/feature/Tree Tree} feature is enabled.*
     *
     * @function collapse
     * @param {String|Number|Common.data.Model} idOrRecord Record (the node itself) or id of a node to collapse
     * @returns {Promise}
     * @category Feature shortcuts
     */

    /**
     * Expand a single node.
     *
     * *NOTE: Only available when the {@link Grid/feature/Tree Tree} feature is enabled.*
     *
     * @function expand
     * @param {String|Number|Common.data.Model} idOrRecord Record (the node itself) or id of a node to expand
     * @returns {Promise}
     * @category Feature shortcuts
     */

    /**
     * Expands parent nodes to make this node "visible".
     *
     * *NOTE: Only available when the {@link Grid/feature/Tree Tree} feature is enabled.*
     *
     * @function expandTo
     * @param {String|Number|Common.data.Model} idOrRecord Record (the node itself) or id of a node
     * @returns {Promise}
     * @category Feature shortcuts
     */

    //endregion

    //region Grid template & elements

    template(data) {
        const virtualScrollerStyle = BrowserHelper.isFirefox ? `height:${DomHelper.scrollBarWidth}px` : '';

        // SubGrids are set up first time regions are pulled in
        this._thisIsAUsedExpression(data.regions);

        return TemplateHelper.tpl`
            <div tabindex="-1">
                <header reference="headerContainer" class="b-grid-header-container ${this.hideHeaders ? 'b-hidden' : ''}"></header>
                <div reference="bodyContainer" class="b-grid-body-container" data-empty-text="${data.emptyText}">
                    <div reference="verticalScroller" class="b-grid-vertical-scroller"></div>
                </div>
                <div reference="virtualScrollers" class="b-virtual-scrollers ${DomHelper.scrollBarWidth ? '' : 'b-overlay-scrollbar'}" style="${virtualScrollerStyle}"></div>
                <footer reference="footerContainer" class="b-grid-footer-container b-hidden"></footer>
            </div>
        `;
    }

    get overflowElement() {
        return this.bodyContainer;
    }

    get focusElement() {
        return this.element;
    }

    //endregion

    //region Columns

    set columns(columns) {
        const me = this;

        if (me._columnStore) {
            if (columns) {
                // TODO: @johan: reconfiguring, ie changing whole column set should work.
                // That could mean a total recalculation of subGrids.
                // That's not possible right now, so
                //throw new Error('Cannot reconfigure column set');
                // me._columnStore.clear();
                me._columnStore.data = columns;
            }
            else {
                me._columnStore.destroy();
            }
        }
        else {
            if (columns instanceof ColumnStore) {
                me._columnStore = columns;
            }
            else {
                me._columnStore = new ColumnStore({
                    grid      : me,
                    data      : columns,
                    listeners : {
                        // changes might be triggered when applying state, before grid is rendered
                        // TODO: have this run a lighter weight, non-destructive response.
                        // onColumnsChanged is a start, but lots of machinery is hooked to render.
                        change  : me.onColumnsChanged,
                        thisObj : me
                    }
                });
            }

            me._columnStore.on(columnResizeEvent(me.onColumnsResized, me));

            // Add touch class for touch devices
            if (BrowserHelper.isTouchDevice) {
                me.touch = true;

                // apply touchConfig for columns that defines it
                me._columnStore.forEach(column => {
                    const touchConfig = column.touchConfig;
                    if (touchConfig) {
                        column.applyState(touchConfig);
                    }
                });
            }
        }
    }

    /**
     * Get the {@link Grid.data.ColumnStore ColumnStore} used by this Grid.
     *
     * @property {Grid.data.ColumnStore}
     * @category Common
     * @readonly
     */
    get columns() {
        return this._columnStore;
    }

    onColumnsChanged({ action, changes, record : column }) {
        const me = this;

        if (action === 'update') {
            // Just updating width is already handled in a minimal way.
            if ('width' in changes || 'minWidth' in changes || 'flex' in changes) {
                // Update any leaf columns that want to be repainted on size change
                if (me.rendered) {
                    const region = column.region;

                    me.columns.visibleColumns.forEach((col) => {
                        if (col.region === region && col.repaintOnResize) {
                            me.refreshColumn(col);
                        }
                    });
                }
                return;
            }

            // Column toggled, need to recheck if any visible column has flex
            if ('hidden' in changes) {
                const subGrid = me.getSubGridFromColumn(column.id);
                subGrid.header.fixHeaderWidths();
                if (subGrid.footer) {
                    subGrid.footer.fixFooterWidths();
                }
                subGrid.updateHasFlex();
            }
        }

        // New columns set ("reconfiguring"), or moved to previously not available region
        if (action === 'dataset' || (changes && ('region' in changes) && !me.regions.includes(changes.region.value))) {
            // Create required subgrids (removing existing)
            me.initSubGrids();
            // Render and jump start them
            me.eachSubGrid(subGrid => {
                subGrid.render(me.verticalScroller);
                subGrid.initScroll();
            });
        }

        if (!me._suspendRenderContentsOnColumnsChanged) {
            me.renderContents();
        }
    }

    onColumnsResized({ changes, record : column }) {
        const
            me       = this,
            setWidth = changes.width && column.flex == null,
            setMinWidth = changes.minWidth && column.flex == null,
            setFlex  = changes.flex && column.width == null,
            domWidth = DomHelper.setLength(column.width),
            domMinWidth = DomHelper.setLength(column.minWidth),
            subGrid  = me.getSubGridFromColumn(column.id);

        // Let header and footer fix their own widths
        subGrid.header.fixHeaderWidths();
        if (subGrid.footer) {
            subGrid.footer.fixFooterWidths();
        }
        subGrid.updateHasFlex();

        if (!me.cellEls || column !== me.lastColumnResized) {
            me.cellEls = DomHelper.children(
                me.element,
                `.b-grid-cell[data-column-id=${column.id}]`
            );
            me.lastColumnResized = column;
        }

        for (let cell of me.cellEls) {
            if (setWidth) {
                // https://app.assembla.com/spaces/bryntum/tickets/8041
                // Although header and footer elements must be sized
                // using flex-basis to avoid the busting out problem,
                // grid cells MUST be sized using width since rows are absolutely
                // positioned and will not cause the busting out problem,
                // and rows will not stretch to shrinkwrap the cells
                // unless they are widthed with width.
                cell.style.width = domWidth;
                cell.style.flex = '';

                // IE11 calculates flexbox container width based on min-width rather than actual width. When column
                // has width defined greater than minWidth, row may have incorrect width
                if (BrowserHelper.isIE11) {
                    cell.style.minWidth = domWidth;
                }
            }
            else if (setMinWidth) {
                cell.style.minWidth = domMinWidth;
            }
            else if (setFlex) {
                cell.style.flex = column.flex;
                cell.style.width = '';
            }
            else {
                cell.style.flex = cell.style.width = cell.style.minWidth = '';
            }
        }

        // If we're being driven by the ColumnResizer, it will
        // call afterColumnsResized.
        if (!me.dragResizing) {
            me.afterColumnsResized();
        }
    }

    afterColumnsResized() {
        const me = this;

        me.refreshVirtualScrollbars();
        me.eachSubGrid(subGrid => {
            if (!subGrid.collapsed) {
                subGrid.fixWidths();
                subGrid.fixRowWidthsInSafariEdge();
                subGrid.refreshFakeScroll();
            }
        });
        me.lastColumnResized = me.cellEls = null;
    }

    //endregion

    //region Rows

    /**
     * Get the Row that is currently displayed at top.
     * @member {Grid.row.Row} topRow
     * @readonly
     * @category Rows
     * @private
     */

    /**
     * Get the Row currently displayed furthest down.
     * @member {Grid.row.Row} bottomRow
     * @readonly
     * @category Rows
     * @private
     */

    /**
     * Get Row for specified record id.
     * @function getRowById
     * @param {Common.data.Model|String|Number} recordOrId Record id (or a record)
     * @returns {Grid.row.Row} Found Row or null if record not rendered
     * @category Rows
     * @private
     */

    /**
     * Returns top and bottom for rendered row or estimated coordinates for unrendered.
     * @function getRecordCoords
     * @param {Common.data.Model|string|Number} recordOrId Record or record id
     * @returns {Object} Record bounds with format { top, height, bottom }
     * @category Calculations
     * @private
     */

    /**
     * Get the Row at specified index. "Wraps" index if larger than available rows.
     * @function getRow
     * @param {Number} index
     * @returns {Grid.row.Row}
     * @category Rows
     * @private
     */

    /**
     * Get a Row for either a record, a record id or an HTMLElement
     * @function getRowFor
     * @param {HTMLElement|Common.data.Model|String|Number} recordOrId Record or record id or HTMLElement
     * @returns {Grid.row.Row} Found Row or null if record not rendered
     * @category Rows
     * @private
     */

    /**
     * Get a Row from an HTMLElement
     * @function getRowFromElement
     * @param {HTMLElement} element
     * @returns {Grid.row.Row} Found Row or null if record not rendered
     * @category Rows
     * @private
     */

    get rowManager() {
        const me = this;

        // Use row height from CSS if not specified in config. Did not want to turn this into a getter/setter for
        // rowHeight since RowManager will plug its implementation into Grid when created below, and after initial
        // configuration that is what should be used
        if (!me._isRowMeasured) {
            me.measureRowHeight();
        }

        // RowManager is a plugin, it is configured with its grid as its "client".
        // It uses client.store as its record source.

        return me._rowManager || (me._rowManager = new RowManager({
            grid          : me,
            rowHeight     : me.rowHeight,
            rowScrollMode : me.rowScrollMode || 'move',
            autoHeight    : me.autoHeight,
            listeners     : {
                changetotalheight   : me.onRowManagerChangeTotalHeight,
                requestscrollchange : me.onRowManagerRequestScrollChange,
                thisObj             : me
            }
        }));
    }

    showEmptyText() {
        this.bodyContainer && this.bodyContainer.classList[this.rowManager.rowCount || this.store.isLoading ? 'remove' : 'add']('b-grid-empty');
    }

    //endregion

    //region Store

    /**
     * Hooks up data store listeners
     * @private
     * @category Store
     */
    bindStore(store) {
        const me = this;

        me.storeDetacher = store.on({
            refresh      : me.onStoreDataChange,
            update       : me.onStoreUpdateRecord,
            add          : me.onStoreAdd,
            remove       : me.onStoreRemove,
            move         : me.onStoreMove,
            removeall    : me.onStoreRemoveAll,
            loadstart    : me.onStoreLoadStart,
            afterrequest : me.onStoreAfterRequest,
            clearchanges : me.onStoreDataChange,
            exception    : me.onStoreException
        }, me);
    }

    get store() {
        return this._store;
    }

    /**
     * Get/set the store used by this Grid. The setter accepts Store or a configuration object for a store.
     * If the configuration contains a `readUrl`, an AjaxStore will be created.
     * @property {Common.data.Store|Object}
     * @category Common
     */
    set store(store) {
        const
            me       = this,
            features = me.initialConfig.features;

        if (store !== me._store) {
            if (me.storeDetacher) {
                me.storeDetacher();
                me.storeDetacher = null;
            }

            if (store) {

                if (store instanceof Store) {
                    
                }
                else {
                    const storeCfg = {};
                    if (me.data) {
                        storeCfg.data = me.data;
                    }
                    if (features && features.tree) {
                        storeCfg.tree = true;
                    }
                    // extend GridRowModel to not pollute it with custom fields (if we have multiple grids on page)
                    if (!store.modelClass) {
                        storeCfg.modelClass = class extends GridRowModel {};
                    }

                    store = new (store.readUrl ? AjaxStore : Store)(Object.assign(storeCfg, store));
                }

                me._store = store;

                me.bindStore(store);
            }
            else {
                if (me.destroyStore) {
                    me._store.destroy();
                }
                me._store = null;
            }
        }
    }

    /**
     * Rerenders a cell if a record is updated in the store
     * @private
     * @category Store
     */
    onStoreUpdateRecord({ source : store, record, data }) {
        const me = this;

        if (me.forceFullRefresh) {
            // flagged to need full refresh (probably from using GroupSummary)
            me.rowManager.refresh();

            me.forceFullRefresh = false;
        }
        else {
            let row;
            // Search for old row if id was changed
            if (record.isFieldModified('id')) {
                row = me.getRowFor(record.meta.modified.id);
            }

            row = row || me.getRowFor(record);
            // not rendered, bail out
            if (!row) return;

            if (me.fullRowRefresh) {
                const index = store.indexOf(record);
                if (index !== -1) {
                    row.render(index, record);
                }
            }
            else {
                Object.keys(data).forEach(field => {
                    let cell = row.getCell(field);
                    if (cell) row.renderCell(cell, record);
                });
            }
        }
    }

    refreshFromRowOnStoreAdd(row, context) {
        const
            me             = this,
            { rowManager } = me;

        rowManager.renderFromRow(row);
        rowManager.trigger('changeTotalHeight', { totalHeight : rowManager.totalHeight });

        // First record? Also update fake scrollers
        // TODO: Consider making empty grid scrollable to not have to do this
        if (me.store.count === 1) {
            me.callEachSubGrid('refreshFakeScroll');
        }
    }

    /**
     * Refreshes rows when data is added to the store
     * @private
     * @category Store
     */
    onStoreAdd({ source : store, records, index, oldIndex, isChild, oldParent }) {
        // Do not react if the content has not been rendered
        if (!this.rendered) {
            return;
        }

        // If it's the addition of a child to a collapsed zone, the UI does not change.
        if (isChild && !records[0].ancestorsExpanded(store)) {
            return;
        }

        this.rowManager.calculateRowCount(false, true, true);

        const
            me             = this,
            { rowManager } = me,
            {
                topIndex,
                rows,
                rowCount
            }              = rowManager,
            bottomIndex    = rowManager.topIndex + rowManager.rowCount - 1,
            dataStart      = index,
            dataEnd        = index + records.length - 1,
            atEnd          = bottomIndex >= store.count - records.length - 1;

        // When moving a node within a tree we might need the redraw to include its old parent and its children. Not worth
        // the complexity of trying to do a partial render for this, rerender all rows to be safe.
        // Moving records within a flat store is handled elsewhere, in onStoreMove
        // TODO: Moving within a tree should also trigger 'move' (https://app.assembla.com/spaces/bryntum/tickets/7270)
        if (oldParent || oldIndex > -1) {
            rowManager.refresh();
        }
        // Added block starts in our visible block. Render from there downwards.
        else if (dataStart >= topIndex && dataStart < topIndex + rowCount) {
            me.refreshFromRowOnStoreAdd(rows[dataStart - topIndex], ...arguments);
        }
        // Added block ends in our visible block, render block
        else if (dataEnd >= topIndex && dataEnd < topIndex + rowCount) {
            rowManager.refresh();
        }
        // If added block is outside of the visible area, no visible change
        // but potentially a change in total dataset height.
        else {
            // If we are against the end of the dataset, and have appended records
            // ensure they are rendered below
            if (atEnd && index > bottomIndex) {
                rowManager.fillBelow(me.scrollable.y);
            }
            rowManager.trigger('changeTotalHeight', { totalHeight : rowManager.totalHeight });
        }
    }

    /**
     * Responds to exceptions signalled by the store
     * @private
     * @category Store
     */
    onStoreException(event) {
        const me = this;

        let message;

        switch (event.type) {
            case 'server':
                message = (event.response.message || 'Unspecified failure');
                break;

            case 'exception':
                if (event.exceptionType === 'network') {
                    message = 'Network error';
                }
                else {
                    // Server sent something that couldn't be parsed
                    message =  event.error && event.error.message || 'Failed to parse server response';
                }
                break;

            default:
                message = ((event.response.status + ' - ' + event.response.statusText) || 'Unknown error');
        }

        // eslint-disable-next-line
        const messageHTML = `<div class="b-grid-load-failure">
                <div class="b-grid-load-fail">${me.L('loadFailedMessage')}</div>
                <div class="b-grid-load-fail">${event.response.url ? (event.response.url + ' responded with') : ''}</div>
                <div class="b-grid-load-fail">${message}</div>
            </div>`;

        if (me.activeMask) {
            me.activeMask.icon = me.loadMaskErrorIcon;
            me.activeMask.text = messageHTML;

            me.loadmaskHideTimer = me.setTimeout(() => {
                me.unmaskBody();
            }, me.loadMaskHideTimeout);
        }
    }

    /**
     * Refreshes rows when data is changed in the store
     * @private
     * @category Store
     */
    onStoreDataChange({ action, changes, source : store }) {
        // If the next mixin up the inheritance chain has an implementation, call it
        super.onStoreDataChange && super.onStoreDataChange(...arguments);

        const
            me = this,
            isGroupFieldChange = store.isGrouped && changes && me.groupers.find(grouper => grouper.field in changes);

        // If it's new data, the old calculation is invalidated.
        if (action === 'dataset') {
            me.rowManager.averageRowHeight = null;
        }
        // No need to rerender if it's a change of the value of the group field which
        // will be responded to by StoreGroup
        if (me.rendered && !isGroupFieldChange) {
            // Return to top if setting new data or is filtering
            me.renderRows(null, action === 'dataset' || action === 'filter');
        }

        me.showEmptyText();
    }

    /**
     * Shows a load mask while the connected store is loading
     * @private
     * @category Store
     */
    onStoreLoadStart() {
        if (this.loadMask) {
            this.maskBody(this.loadMask);
        }
    }

    /**
     * Hides load mask after a load request ends either in success or failure
     * @private
     * @category Store
     */
    onStoreAfterRequest(event) {
        if (this.activeMask && !event.exception) {
            this.unmaskBody();
        }
    }

    /**
     * Animates removal of record.
     * @private
     * @category Store
     */
    onStoreRemove({ records, isCollapse, isChild }) {
        // Do not react if the content has not been rendered
        if (!this.rendered) {
            return;
        }

        // GridSelection mixin does its job on records removing
        super.onStoreRemove && super.onStoreRemove(...arguments);

        let topRowIndex = (2 ** 53) - 1;

        const
            me             = this,
            { rowManager } = this,
            // Gather all visible rows which need to be removed.
            rowsToRemove   = records.reduce((result, record) => {
                const row = rowManager.getRowById(record.id);
                if (row) {
                    result.push(row);
                    // Rows are repositioned in the array, it matches visual order. Need to find actual index in it
                    topRowIndex = Math.min(topRowIndex, rowManager.rows.indexOf(row));
                }
                return result;
            }, []);

        if (me.animateRemovingRows && rowsToRemove.length && !isCollapse && !isChild) {
            const topRow = rowsToRemove[0];

            me.isAnimating = true;
            // As soon as first row has disappeared, rerender the view
            EventHelper.on({
                element       : topRow._elementsArray[0],
                transitionend : e => {
                    me.isAnimating = false;
                    // hovering triggers background-color transitions, ignore those
                    if (e.propertyName !== 'background-color' && e.propertyName !== 'width') {
                        rowsToRemove.forEach(row => row.removeCls('b-removing'));
                        rowManager.refresh();
                        // undocumented internal event for scheduler
                        me.trigger('rowRemove');
                    }
                },
                once : true
            });
            rowsToRemove.forEach(row => row.addCls('b-removing'));
        }
        else {
            // Potentially remove rows and change dataset height
            rowManager.calculateRowCount(false, true, true);

            // If there were rows below which have moved up into place
            // then repurpose them with their new records
            if (rowManager.rows[topRowIndex]) {
                rowManager.renderFromRow(rowManager.rows[topRowIndex]);
            }
            // If nothing to render below, just update dataset height
            else {
                rowManager.trigger('changeTotalHeight', { totalHeight : rowManager.totalHeight });
            }
            me.trigger('rowRemove', { isCollapse });
        }
    }

    onStoreMove({ from, to }) {
        const
            { rowManager }       = this,
            {
                topIndex,
                rowCount
            }                    = rowManager,
            [dataStart, dataEnd] = [from, to].sort();

        // Changed block starts in our visible block. Render from there downwards.
        if (dataStart >= topIndex && dataStart < topIndex + rowCount) {
            rowManager.renderFromRow(rowManager.rows[dataStart - topIndex]);
        }
        // Changed block ends in our visible block, render block
        else if (dataEnd >= topIndex && dataEnd < topIndex + rowCount) {
            this.rowManager.refresh();
        }
        // If changed block is outside of the visible area, this is a no-op
    }

    /**
     * Rerenders grid when all records have been removed
     * @private
     * @category Store
     */
    onStoreRemoveAll() {
        // GridSelection mixin does its job on records removing
        super.onStoreRemoveAll && super.onStoreRemoveAll(...arguments);

        if (this.rendered) {
            this.renderRows();
            this.showEmptyText();
        }
    }

    /**
     * Convenience functions for getting/setting data in related store
     * @property {Object[]}
     * @category Common
     */
    get data() {
        if (this._store) {
            return this._store.records;
        }
        else {
            return this._data;
        }
    }

    set data(data) {
        if (this._store) {
            this._store.data = data;
        }
        else {
            this._data = data;
        }
    }

    //endregion

    //region Menu items

    /**
     * Populates the header context menu. Chained in features to add menu items.
     * @param column Column for which the menu will be shown
     * @param items Array of menu items, add to it and return it
     * @category Menu items
     * @internal
     */
    getHeaderMenuItems(column, items) {
        const me       = this,
            { subGrids, regions } = me;

        let first = true;

        Object.entries(subGrids).forEach(([region, subGrid]) => {
            // If SubGrid is configured with a sealed column set, do not allow moving into it
            if (subGrid.sealedColumns) {
                return;
            }

            if (column.draggable &&
                region !== column.region &&
                (!column.parent && subGrids[column.region].columns.count > 1 ||
                    column.parent && column.parent.children.length > 1)
            ) {
                const moveRight = subGrid.element.compareDocumentPosition(subGrids[column.region].element) === document.DOCUMENT_POSITION_PRECEDING,
                    // With 2 regions, use Move left, Move right. With multiple, include region name
                    text = regions.length > 2
                        ? me.L('Move column to ') + region
                        : me.L(moveRight ? 'moveColumnRight' : 'moveColumnLeft');

                items.push({
                    targetSubGrid : region,
                    text,
                    icon          : 'b-fw-icon ' + (moveRight ? 'b-icon-column-move-right' : 'b-icon-column-move-left'),
                    name          : 'moveColumn',
                    cls           : first ? 'b-separator' : '',
                    onItem        : ({ item }) => {
                        const { column } = item;

                        column.traverse(col => col.region = region);

                        // Changing region will move the column to the correct SubGrid, but we want it to go last
                        me.columns.insert(me.columns.indexOf(subGrids[item.targetSubGrid].columns.last) + 1, column);

                        me.scrollColumnIntoView(column);
                    }
                });

                first = false;
            }
        });
    }

    /**
     * Populates the cell context menu. Chained in features to add menu items.
     * @param column {Grid.column.Column} Column for which the menu will be shown
     * @param record {Common.data.Model} Record (row) for which the menu will be shown
     * @param items {Object[]} Array of menu items, add to it and return it
     * @category Menu items
     * @internal
     */
    getCellMenuItems(column, record, items) {
        const me = this;

        if (me.showRemoveRowInContextMenu && !me.readOnly && record && !record.meta.specialRow) {
            if (me.selectedRecords.length > 1) {
                items.push(
                    {
                        text   : me.L('removeRows'),
                        icon   : 'b-fw-icon b-icon-trash',
                        name   : 'removeRows',
                        onItem : () => me.store.remove(me.selectedRecords)
                    }
                );
            }
            else {
                items.push(
                    {
                        text   : me.L('removeRow'),
                        icon   : 'b-fw-icon b-icon-trash',
                        name   : 'removeRow',
                        onItem : () => me.store.remove(record)
                    }
                );
            }
        }
    }

    getColumnDragToolbarItems(column, items) {
        return items;
    }

    //endregion

    //region Getters

    normalizeCellContext(cellContext) {
        const { columns, store } = this;

        // TODO: should clone instead of modify?
        // TODO: The answer is to use the Grid/util/Location class to robustly encapsulate a record/column intersection
        // And have them immutable, so that to change is to clone, as explained by MaximGB,
        // we want to use columnId for precision, but allow user to specify column name for ease of use...
        // modify cellContext to include columnId in those cases
        if (cellContext instanceof store.modelClass) {
            return {
                record   : cellContext,
                id       : cellContext.id,
                columnId : columns.bottomColumns[0].id
            };
        }
        if (!('columnId' in cellContext)) {
            if ('field' in cellContext) {
                const column = columns.get(cellContext.field);
                cellContext.columnId = column && column.id;
            }
            else if ('column' in cellContext) {
                const column = (typeof cellContext.column === 'number') ? columns.bottomColumns[cellContext.column] : cellContext.column;
                cellContext.columnId = column && column.id;
            }

            // Fall back to first leaf column
            if (!('columnId' in cellContext)) {
                cellContext.columnId = columns.bottomColumns[0].id;
            }
        }

        if ('id' in cellContext) {
            // If the context is for an element, but it's stale (for a removed record)
            // then fix it up to refer to the record id at the same index.
            if (cellContext.element && (!store.getById(cellContext.id))) {
                // This uses the data-index property to get the row at that index.
                const newRec = this.getRecordFromElement(cellContext.element);

                // We have a record at the same index.
                if (newRec) {
                    cellContext.id = newRec.id;
                }
            }
        }
        else {
            if ('row' in cellContext) {
                cellContext.id = store.getAt(cellContext.row).id;
            }
            else if ('record' in cellContext) {
                cellContext.id = cellContext.record.id;
            }
        }

        return cellContext;
    }

    // TODO: move to RowManager? Or create a CellManager?
    /**
     * Returns a cell if rendered.
     * @param {Object} cellContext { id: rowId, columnId: columnId [,column: column number, field: column field] }
     * @param {Number} [cellContext.row] The row index of the row to access. Exclusive with `id` and 'record'.
     * @param {String|Number} [cellContext.id] The record id of the row to access. Exclusive with `row` and 'record'.
     * @param {Common.data.Model} [cellContext.record] The record of the row to access. Exclusive with `id` and 'row'.
     * @param {Number} [cellContext.column] The column instance or the index of the cell to access.  Exclusive with `columnId`.
     * @param {String|Number} [cellContext.columnId] The column id of the column to access. Exclusive with `column`.
     * @param {String} [cellContext.field] The field of the column to access. Exclusive with `column`.
     * @returns {HTMLElement}
     * @category Getters
     */
    getCell(cellContext) {
        let row,
            result = null;

        cellContext = this.normalizeCellContext(cellContext);

        if (cellContext.id) {
            row = this.getRowById(cellContext.id);
        }

        if (row && cellContext.columnId) {
            result = row.getCell(cellContext.columnId);
        }

        return result;
    }

    //TODO: Should move to ColumnManager? Or Header?
    /**
     * Returns the header element for the column
     * @param {String|Number|Grid.column.Column} columnId or Column instance
     * @returns {HTMLElement} Header element
     * @category Getters
     */
    getHeaderElement(columnId) {
        if (typeof columnId !== 'string') {
            columnId = columnId.id;
        }

        return this.fromCache(`.b-grid-header[data-column-id="${columnId}"]`);
    }

    getHeaderElementByField(field) {
        const column = this.columns.get(field);

        return column ? this.getHeaderElement(column) : null;
    }

    /**
     * Body height
     * @property {Number}
     * @readonly
     * @category Layout
     */
    get bodyHeight() {
        return this._bodyHeight;
    }

    /**
     * Header height
     * @property {Number}
     * @readonly
     * @category Layout
     */
    get headerHeight() {
        const me = this;
        // measure header if rendered and not stored
        if (me.rendered && !me._headerHeight) {
            me._headerHeight = me.headerContainer.offsetHeight;
        }

        return me._headerHeight;
    }

    /**
     * Searches up from the specified element for a grid row and returns the record associated with that row.
     * @param {HTMLElement} element Element somewhere within a row or the row container element
     * @returns {Common.data.Model} Record for the row
     * @category Getters
     */
    getRecordFromElement(element) {
        const el = element.closest('.b-grid-row');

        if (!el) return null;

        return this.store.getAt(el.dataset.index);
    }

    /**
     * Searches up from specified element for a grid cell or an header and returns the column which the cell belongs to
     * @param {HTMLElement} element Element somewhere in a cell
     * @returns {Grid.column.Column} Column to which the cell belongs
     * @category Getters
     */
    getColumnFromElement(element) {
        const cell = DomHelper.up(element, '.b-grid-cell, .b-grid-header');
        if (!cell) return null;

        if (cell.matches('.b-grid-header')) {
            return this.columns.getById(cell.dataset.columnId);
        }

        const cellData = DomDataStore.get(cell);
        return this.columns.getById(cellData.columnId);
    }

    // Getter and setter for autoHeight only added for type checking, since it seems common to get it wrong in react/angular
    get autoHeight() {
        return this._autoHeight;
    }

    set autoHeight(autoHeight) {
        ObjectHelper.assertBoolean(autoHeight, 'autoHeight');

        this._autoHeight = autoHeight;
    }

    /**
     * Toggle column line visibility. End result might be overruled by/differ between themes.
     * @property {Boolean}
     */
    get columnLines() {
        return this._columnLines;
    }

    set columnLines(columnLines) {
        ObjectHelper.assertBoolean(columnLines, 'columnLines');

        DomHelper.toggleClasses(this.element, 'b-no-column-lines', !columnLines);

        this._columnLines = columnLines;
    }

    //endregion

    //region ReadOnly

    /**
     * Get/set read only mode, which prevents cell editing etc.
     * Exactly what is prevented is up to each feature.
     * @property {Boolean}
     * @fires readonly
     * @category Common
     */
    set readOnly(readOnly) {
        const me = this;
        me._readOnly = readOnly;
        if (me.rendered) {
            /**
             * Fired when grids read only state is toggled
             * @event readOnly
             * @param {Boolean} readOnly Read only or not
             */
            me.trigger('readOnly', { readOnly });

            // IE11 doesnt support this
            //me.element.classList.toggle('b-readonly', readOnly);
            if (readOnly) {
                me.element.classList.add('b-readonly');
            }
            else {
                me.element.classList.remove('b-readonly');
            }
        }
    }

    get readOnly() {
        return this._readOnly;
    }

    //endregion

    //region Fix width & height

    /**
     * Sets widths and heights for headers, rows and other parts of the grid as needed
     * @private
     * @category Width & height
     */
    fixSizes() {
        // subGrid width
        this.callEachSubGrid('fixWidths');
    }

    onRowManagerChangeTotalHeight({ totalHeight }) {
        this.refreshTotalHeight(totalHeight);
    }

    /**
     * Makes height of vertical scroller match estimated total height of grid. Called when scrolling vertically and
     * when showing/hiding rows.
     * @param {Number} height
     * @private
     * @category Width & height
     */
    refreshTotalHeight(height = this.rowManager.totalHeight) {
        // Removed isVisible check here, since rows are rendered on paint now (when grid is visible)
        if (this.renderingRows) {
            return;
        }
        if (this.rowManager.bottomRow) {
            height = Math.max(height, this.rowManager.bottomRow.bottom);
        }
        const
            me           = this,
            scroller     = me.scrollable,
            delta        = Math.abs(me.virtualScrollHeight - height),
            clientHeight = me._bodyRectangle.height,
            newMaxY      = height - clientHeight;

        if (delta) {
            const
                // We must update immediately if we are nearing the end of the scroll range.
                isCritical = (newMaxY - scroller.y < clientHeight * 2) ||
                    // Or if we have scrolled pass visual height
                    (me._verticalScrollHeight && (me._verticalScrollHeight - clientHeight < scroller.y));

            // Update the true scroll range using the scroller. This will not cause a repaint.
            scroller.scrollHeight = me.virtualScrollHeight = height;

            // If we are scrolling, put this off because it causes
            // a full document layout and paint.
            if (me.scrolling && !isCritical) {
                if (!me.virtualScrollHeightDirty) {
                    me.virtualScrollHeightDirty = scroller.on({
                        scrollend : me.fixElementHeights,
                        thisObj   : me,
                        once      : true
                    });
                }
            }
            else {
                me.virtualScrollHeightDirty && me.virtualScrollHeightDirty();
                me.fixElementHeights(height);
            }
        }
    }

    fixElementHeights() {
        const
            me         = this,
            height     = me.virtualScrollHeight,
            heightInPx = `${height}px`;

        me._verticalScrollHeight = height;
        me.verticalScroller.style.height = heightInPx;
        me.virtualScrollHeightDirty = false;

        if (me.autoHeight) {
            me.bodyContainer.style.height = heightInPx;
            me._bodyHeight = height;
            me._bodyRectangle = Rectangle.client(me.bodyContainer);
        }

        me.refreshVirtualScrollbars();
    }

    //endregion

    //region Scroll & virtual rendering

    set scrolling(scrolling) {
        this._scrolling = scrolling;
        this.bodyContainer.classList[scrolling ? 'add' : 'remove']('b-scrolling');
    }

    get scrolling() {
        return this._scrolling;
    }

    /**
     * Responds to request from RowManager to adjust scroll position. Happens when jumping to a scroll position with
     * variable row height.
     * @param {Number} bottomMostRowY
     * @private
     * @category Scrolling
     */
    onRowManagerRequestScrollChange({ bottom }) {
        this.scrollable.y = bottom - this.bodyHeight;
    }

    

    /**
     * Scroll syncing for normal headers & grid + triggers virtual rendering for vertical scroll
     * @private
     * @fires scroll
     * @category Scrolling
     */
    initScroll() {
        const me = this;
        // This method may be called early, before render calls it, so ensure that it's
        // only executed once.
        if (!me.scrollInitialized) {
            let scrollTop,
                onScroll = me.createOnFrame(() => {
                    me._scrollTop = scrollTop = me.scrollable.y;

                    if (!me.scrolling) {
                        me.scrolling = true;
                        me.eachSubGrid(s => s.suspendResizeMonitor = true);
                    }

                    

                    me.rowManager.updateRenderedRows(scrollTop);

                    /**
                     * Grid has scrolled vertically
                     * @event scroll
                     * @param {Grid.view.Grid} grid
                     * @param {Number} scrollTop
                     */
                    me.trigger('scroll', { scrollTop });
                });

            me.scrollInitialized = true;

            me.scrollable.on({
                scroll : onScroll,
                scrollend() {
                    me.scrolling = false;
                    me.eachSubGrid(s => s.suspendResizeMonitor = false);
                }
            });

            me.callEachSubGrid('initScroll');

            
        }
    }

    // TODO: rename to scrollRecordIntoView? Or have an alias?
    /**
     * Scrolls a row into view. If row isn't rendered it tries to calculate position
     * @param {Common.data.Model|String|Number} recordOrId Record or record id
     * @param {Object} [options] How to scroll.
     * @param {String} [options.column] Field name or ID of the column, or the Column instance to scroll to.
     * @param {String} [options.block] How far to scroll the element: `start/end/center/nearest`.
     * @param {Number} [options.edgeOffset] edgeOffset A margin around the element or rectangle to bring into view.
     * @param {Boolean|Number} [options.animate] Set to `true` to animate the scroll, or the number of milliseconds to animate over.
     * @param {Boolean} [options.highlight] Set to `true` to highlight the element when it is in view.
     * @category Scrolling
     * @returns {Promise} A promise which resolves when the specified row has been scrolled into view.
     */
    scrollRowIntoView(recordOrId, options = defaultScrollOptions) {
        const
            me            = this,
            blockPosition = options.block || 'nearest',
            rowManager    = me.rowManager;

        recordOrId = me.store.getById(recordOrId);

        if (recordOrId) {
            // check that record is "displayable", not filtered out or hidden by collapse
            if (me.store.indexOf(recordOrId) === -1) {
                return resolvedPromise;
            }

            let scroller   = me.scrollable,
                recordRect = me.getRecordCoords(recordOrId);

            const scrollerRect = Rectangle.from(scroller.element);

            // If it was calculated from the index, update the rendered rowScrollMode
            // and scroll to the actual element. Note that this should only be necessary
            // for variableRowHeight.
            // But to "make the tests green", this is a workaround for a buffered rendering
            // bug when teleporting scroll. It does not render the rows at their correct
            // positions. Please do not try to "fix" this. I will do it. NGW
            if (recordRect.virtual) {
                const virtualBlock = recordRect.block;

                // Scroll the calculated position to the center of the scrollingViewport
                // and then update the rendered block while asking the RowManager to
                // display the required recordOrId.
                scroller.scrollIntoView(recordRect, {
                    block : 'center'
                });
                rowManager.scrollTargetRecordId = recordOrId;
                rowManager.updateRenderedRows(scroller.y, true);
                recordRect = me.getRecordCoords(recordOrId);
                rowManager.lastScrollTop = scroller.y;

                if (recordRect.virtual) {
                    
                    // bail out to not get caught in infinite loop, since code above is cut out of bundle
                    // eslint-disable-next-line no-useless-return,no-unreachable
                    return resolvedPromise;
                }

                const innerOptions = blockPosition !== 'nearest' ? options : {
                    block : virtualBlock
                };

                // Scroll the target just less than append/prepend buffer height out of view so that the animation looks good
                if (options.animate) {
                    // Do not fire scroll events during this scroll sequence - it's a purely cosmetic operation.
                    // We are scrolling the desired row out of view merely to *animate scroll* it to the requested position.
                    scroller.suspendEvents();

                    // Scroll to its final position
                    if (blockPosition === 'end' || blockPosition === 'nearest' && virtualBlock === 'end') {
                        scroller.y -= (scrollerRect.bottom - recordRect.bottom);
                    }
                    else if (blockPosition === 'start' || blockPosition === 'nearest' && virtualBlock === 'start') {
                        scroller.y += (recordRect.y - scrollerRect.y);
                    }

                    // Ensure rendered block is correct at that position
                    rowManager.updateRenderedRows(scroller.y, false, true);

                    // Scroll away from final position to enable a cosmetic scroll to final position
                    if (virtualBlock === 'end') {
                        scroller.y -= (rowManager.appendRowBuffer * rowManager.rowHeight - 1);
                    }
                    else {
                        scroller.y += (rowManager.prependRowBuffer * rowManager.rowHeight - 1);
                    }

                    // The row will still be rendered, so scroll it using the scroller directly
                    const result = scroller.scrollIntoView(me.getRecordCoords(recordOrId), Object.assign({}, options, innerOptions));

                    // Now we're at the required position, resume events
                    result.then(() => scroller.resumeEvents());

                    return result;
                }
                else {
                    return me.scrollRowIntoView(recordOrId, Object.assign({}, options, innerOptions));
                }
            }
            else {
                let { column } = options;

                if (column) {
                    if (typeof column === 'string') {
                        column = me.columns.getById(column) || me.columns.get(column);
                    }

                    if (column) {
                        // If we are targetting a column, we must use the scroller of that column's SubGrid
                        scroller = me.getSubGridFromColumn(column).scrollable;

                        const cellRect = Rectangle.from(rowManager.getRowFor(recordOrId).getCell(column.id));

                        recordRect.x = cellRect.x;
                        recordRect.width = cellRect.width;
                    }
                }
                return scroller.scrollIntoView(recordRect, options);
            }
        }
    }

    /**
     * Scrolls a column into view (if it is not already)
     * @param {Grid.column.Column|String|Number} column Column name (data) or column index or actual column object.
     * @param {Object} [options] How to scroll.
     * @param {String} [options.block] How far to scroll the element: `start/end/center/nearest`.
     * @param {Number} [options.edgeOffset] edgeOffset A margin around the element or rectangle to bring into view.
     * @param {Object|Boolean|Number} [options.animate] Set to `true` to animate the scroll by 300ms,
     * or the number of milliseconds to animate over, or an animation config object.
     * @param {Number} [options.animate.duration] The number of milliseconds to animate over.
     * @param {String} [options.animate.easing] The name of an easing function.
     * @param {Boolean} [options.highlight] Set to `true` to highlight the element when it is in view.
     * @param {Boolean} [options.focus] Set to `true` to focus the element when it is in view.
     * @returns {Promise} If the column exists, a promise which is resolved when the column header element has been scrolled into view.
     * @category Scrolling
     */
    scrollColumnIntoView(column, options) {
        column = (column instanceof Column) ? column : this.columns.get(column) || this.columns.getById(column) || this.columns.getAt(column);

        return this.getSubGridFromColumn(column).scrollColumnIntoView(column, options);
    }

    // TODO The API { id: recordId, column: 'columnName' } is not clear: id has to be renamed to `record` or `recordId` to be self-explanatory;
    /**
     * Scrolls a cell into view (if it is not already)
     * @param {Object} cellContext Cell selector { id: recordId, column: 'columnName' }
     * @category Scrolling
     */
    scrollCellIntoView(cellContext, options) {
        return this.scrollRowIntoView(cellContext.id, Object.assign({
            column : cellContext.columnId
        }, options));
    }

    /**
     * Scroll all the way down
     * @category Scrolling
     */
    scrollToBottom(options) {
        // triggers scroll to last record. not using current scroller height because we do not know if it is correct
        this.scrollRowIntoView(this.store.last, options);
    }

    /**
     * Scroll all the way up
     * @category Scrolling
     */
    scrollToTop(options) {
        this.scrollable.scrollBy(0, -this.scrollable.y, options);
    }

    /**
     * Store scroll state (scrollTop for entire grid and scrollLeft per sub grid)
     * @returns {{scrollTop: (*|string|number), scrollLeft: {}}}
     * @category Scrolling
     */
    storeScroll() {
        const
            me    = this,
            state = me.storedScrollState = {
                scrollTop  : me.scrollable.y,
                scrollLeft : {}
            };

        // TODO: Implement special multi-element Scroller subclass for Grids which
        // encapsulates the x axis only Scrollers of all its SubGrids.
        me.eachSubGrid(subGrid => {
            state.scrollLeft[subGrid.region] = subGrid.scrollable.x;
        });

        return state;
    }

    /**
     * Restore scroll state. If state is not specified, restores the last stored state.
     * @param state Scroll state, optional
     * @category Scrolling
     */
    restoreScroll(state = this.storedScrollState) {
        const me = this;

        // TODO: Implement special multi-element Scroller subclass for Grids which
        // encapsulates the x axis only Scrollers of all its SubGrids.
        me.eachSubGrid(subGrid => {
            subGrid.scrollable.x = state.scrollLeft[subGrid.region];
        });

        me.scrollable.y = state.scrollTop;
    }

    //endregion

    //region Theme & measuring

    /**
     * Creates a fake subgrid with one row and mesaures its height. Result is used as rowHeight.
     * @private
     */
    measureRowHeight() {
        const
            me                = this,
            // Create a fake subgrid with one row, since styling for row is specified on .b-grid-subgrid .b-grid-row
            rowMeasureElement = DomHelper.createElement({
                tag       : 'div',
                // TODO: should either get correct widgetClassList or query features for measure classes
                className : 'b-grid ' + (me.features.stripe ? 'b-stripe' : ''),
                style     : 'position: absolute; visibility: hidden',
                html      : '<div class="b-grid-subgrid"><div class="b-grid-row"></div></div>',
                parent    : document.getElementById(me.appendTo) || document.body
            });

        // Use style height or default height from config.
        // Not using clientHeight since it will have some value even if no height specified in CSS
        const
            rowEl        = rowMeasureElement.firstElementChild.firstElementChild,
            styleHeight  = parseInt(DomHelper.getStyleValue(rowEl, 'height')),
            borderTop    = parseInt(DomHelper.getStyleValue(rowEl, 'border-top-width')),
            borderBottom = parseInt(DomHelper.getStyleValue(rowEl, 'border-bottom-width'));

        // Change rowHeight if specified in styling, also remember that value to replace later if theme changes and
        // user has not explicitly set some other height
        if (me.rowHeight == null || me.rowHeight === me._rowHeightFromStyle) {
            me.rowHeight = !isNaN(styleHeight) && styleHeight ? styleHeight : me.defaultRowHeight;
            me._rowHeightFromStyle = me.rowHeight;
        }

        // this measurement will be added to rowHeight during rendering, to get correct cell height
        me._rowBorderHeight = borderTop + borderBottom;

        me._isRowMeasured = true;

        rowMeasureElement.remove();

        // There is a ticket about measuring the actual first row instead:
        // https://app.assembla.com/spaces/bryntum/tickets/5735-measure-first-real-rendered-row-for-rowheight/details
    }

    /**
     * Handler for global theme change event (triggered by shared.js). Remeasures row height.
     * @private
     */
    onThemeChange({ theme }) {
        this.measureRowHeight();
        this.trigger('theme', { theme });
    }

    //endregion

    //region Rendering of rows

    /**
     * Triggers a render of records to all row elements. Call after changing order, grouping etc to reflect changes
     * visually. Preserves scroll.
     * @category Rendering
     */
    refreshRows(returnToTop = false) {
        this.element.classList.add('b-notransition');

        if (returnToTop) {
            this.rowManager.returnToTop();
        }
        else {
            this.rowManager.refresh();
        }

        this.element.classList.remove('b-notransition');
    }

    /**
     * Triggers a render of all the cells in a column.
     * @param {Grid.column.Column} column
     * @category Rendering
     */
    refreshColumn(column) {
        const field = column.field;

        this.rowManager.forEach(row => {
            const cell = row.getCell(field);

            row.renderCell(cell);
        });
    }
    //endregion

    //region Render the grid

    /**
     * Recalculates virtual scrollbars widths and scrollWidth
     * @private
     */
    refreshVirtualScrollbars() {
        const { scrollBarWidth } = DomHelper;

        if (scrollBarWidth) {
            const
                me                    = this,
                {
                    headerContainer,
                    footerContainer,
                    virtualScrollers
                }                     = me,
                hasVerticalOverflow   = me.virtualScrollHeight > me.bodyHeight, //bodyContainer.scrollHeight > bodyContainer.clientHeight,
                // We need to ask each subGrid if it has horizontal overflow.
                // If any do, we show the virtual scroller, otherwise we hide it.
                hasHorizontalOverflow = Object.values(me.subGrids).some(subGrid => subGrid.overflowingHorizontally);

            if (hasHorizontalOverflow) {
                virtualScrollers.classList.remove('b-hide-display');
            }
            else {
                virtualScrollers.classList.add('b-hide-display');
            }

            if (hasVerticalOverflow) {
                if (!headerContainer.classList.contains('b-grid-vertical-overflow')) {
                    headerContainer.classList.add('b-grid-vertical-overflow');
                }
            }
            else {
                headerContainer.classList.remove('b-grid-vertical-overflow');
            }

            // can get called before headers are rendered. headers might also be hidden (such as in docs)
            const lastHeaderScroller = headerContainer.querySelector('.b-grid-header-scroller:last-child');
            if (lastHeaderScroller && hasVerticalOverflow) {
                const headerBorderWidth = parseInt(DomHelper.getStyleValue(lastHeaderScroller, 'border-right-width'));
                // Add a style to pad the header-container to clear the grid's vertical scrollbar
                // TODO: regions part of calculation is to compensate for 1px off with third region, but it should be solvable using CSS. Not finding how though...
                headerContainer.style.paddingRight = (scrollBarWidth - headerBorderWidth + (me.regions.length > 2 ? 1 : 0)) + 'px';
                footerContainer.style.paddingRight = `${scrollBarWidth}px`;
            }
            else {
                headerContainer.style.paddingRight = '0';
                footerContainer.style.paddingRight = '0';
            }
        }
    }

    onContentChange() {
        const
            me         = this,
            rowManager = me.rowManager;

        if (me.isVisible) {
            const contentHeight = Math.max(rowManager.totalHeight, rowManager.bottomRow ? rowManager.bottomRow.bottom : 0);

            me.paintListener = null;
            // cache to avoid recalculations in the middle of rendering code (RowManger#getRecordCoords())
            me._bodyRectangle = Rectangle.client(me.bodyContainer);
            me._bodyHeight = me.autoHeight ? contentHeight : me.bodyContainer.offsetHeight;
            me.refreshTotalHeight(contentHeight);
            me.callEachSubGrid('refreshFakeScroll');
        }
        // If not visible, this operation MUST be done when we become visible.
        // This is announced by the paint event which is triggered when a Widget
        // really gains visibility, ie is shown or rendered, or it's not hidden,
        // and a hidden/non-rendered ancestor is shown or rendered.
        // See Widget#triggerPaint.
        else if (!me.paintListener) {
            me.paintListener = me.on({
                paint   : 'onContentChange',
                once    : true,
                thisObj : me
            });
        }
    }

    /**
     * Called after headers have been rendered to the headerContainer.
     * This does not do anything, it's just for Features to hook in to.
     * @param {HTMLElement} headerContainer DOM element which contains the headers.
     * @param {HTMLElement} element Grid element
     * @private
     * @category Rendering
     */
    renderHeader(headerContainer, element) {}

    /**
     * Called after footers have been rendered to the footerContainer.
     * This does not do anything, it's just for Features to hook in to.
     * @param {HTMLElement} footerContainer DOM element which contains the footers.
     * @param {HTMLElement} element Grid element
     * @private
     * @category Rendering
     */
    renderFooter(footerContainer, element) {}

    suspendRefresh() {
        this.refreshSuspended++;
    }

    resumeRefresh(trigger) {
        if (this.refreshSuspended && !--this.refreshSuspended) {
            if (trigger) {
                this.refreshRows();
            }
        }
    }

    /**
     * Rerenders all grid rows, completely replacing all row elements with new ones
     * @category Rendering
     */
    renderRows(keepScroll = true, returnToTop = false) {
        const
            me          = this,
            scrollState = keepScroll && me.storeScroll();

        if (me.refreshSuspended) {
            return;
        }

        /**
         * Grid rows are about to be rendered
         * @event beforeRenderRows
         * @param {Grid.view.Grid} grid
         */
        me.trigger('beforeRenderRows');
        me.renderingRows = true;

        // This allows us to do things like disable animations on a refresh
        me.element.classList.add('b-grid-refreshing');

        // remove all row elements from dom (sets innerHTML = '')
        me.callEachSubGrid('clearRows');

        if (returnToTop) {
            me.scrollable.y = 0;
        }
        me.rowManager.reinitialize(returnToTop);

        me.renderingRows = false;
        me.onContentChange();

        if (keepScroll) {
            me.restoreScroll(scrollState);
        }

        me.element.classList.remove('b-grid-refreshing');
    }

    /**
     * Rerenders the grids rows, headers and footers, completely replacing all row elements with new ones
     * @category Rendering
     */
    // TODO: Make render call this fn, not very DRY currently
    renderContents() {
        const
            me              = this,
            element         = me.appendTo,
            headerContainer = me.headerContainer,
            footerContainer = me.footerContainer;

        me.emptyCache();

        // columns will be "drawn" on render anyway, bail out
        if (!me.rendered) return;

        // reset measured header height, to make next call to get headerHeight measure it
        me._headerHeight = null;

        me.callEachSubGrid('refreshHeader', headerContainer);
        me.callEachSubGrid('refreshFooter', footerContainer);

        // Note that these are hook methods for features to plug in to. They do not do anything.
        me.renderHeader(headerContainer, element);
        me.renderFooter(footerContainer, element);

        me.fixSizes();
        me.renderRows(false);
    }

    // Render rows etc. on first paint, to make sure Grids element has been laid out
    onPaint() {
        const
            me = this,
            {
                element,
                headerContainer,
                bodyContainer,
                footerContainer
            } = me;

        if (me.rendered) {
            return;
        }

        // apply any responsive configs before rendering columns and rows
        me.updateResponsive(me.width, 0);

        let maxDepth = 0;

        // Cached, updated on resize. Used by RowManager and by the subgrids upon their render
        me._bodyRectangle = Rectangle.client(me.bodyContainer);

        me.eachSubGrid((subGrid) => {
            subGrid.render(me.verticalScroller);
        });

        // Note that these are hook methods for features to plug in to. They do not do anything.
        // SubGrids take care of their own rendering.
        me.renderHeader(headerContainer, element);
        me.renderFooter(footerContainer, element);

        if (me.autoHeight) {
            me._bodyHeight = me.rowManager.initWithHeight(element.offsetHeight - headerContainer.offsetHeight - footerContainer.offsetHeight, true);
            bodyContainer.style.height = me.bodyHeight + 'px';
        }
        else {
            me._bodyHeight = me.bodyContainer.offsetHeight;
            me.rowManager.initWithHeight(me._bodyHeight, true);
        }

        me.eachSubGrid(subGrid => {
            if (subGrid.header.maxDepth > maxDepth) {
                maxDepth = subGrid.header.maxDepth;
            }
        });

        headerContainer.dataset.maxDepth = maxDepth;

        me.fixSizes();

        me.renderRows(false);

        me.initScroll();

        me.initInternalEvents();

        me.rendered = true;
    }

    render(target) {
        const me = this;

        // When displayed inside one of our containers, require a size to be considered visible. Ensures it is painted
        // on display when for example in a tab
        me.requireSize = Boolean(me.owner);

        super.render(target);

        // Sanity check that main element has been given some sizing styles, unless autoHeight is used in which case
        // it will be sized programmatically instead
        if (!me.autoHeight && me.headerContainer.offsetHeight && !me.bodyContainer.offsetHeight) {
            console.warn('Grid element not sized correctly, please check your CSS styles and review how you size the widget');
        }
    }

    //endregion

    // region Masking

    /**
     * Show a load mask with a spinner and the specified message. When using an AjaxStore masking and unmasking is
     * handled automatically, but if you are loading data in other ways you can call this function manually when your
     * load starts.
     * ```
     * myLoadFunction() {
     *   // Show mask before initiating loading
     *   grid.maskBody('Loading data');
     *   // Your custom loading code
     *   load.then(() => {
     *      // Hide the mask when loading is finished
     *      grid.unmaskBody();
     *   });
     * }
     * ```
     * @param {String} loadMask Message to show next to the spinner
     * @returns {Common.widget.Mask}
     */
    maskBody(loadMask) {
        const me = this;

        if (!me.bodyContainer) {
            return;
        }

        // remove any existing mask
        me.unmaskBody();

        me.activeMask = WidgetHelper.mask(me.bodyContainer, loadMask);

        return me.activeMask;
    }

    /**
     * Hide the load mask.
     */
    unmaskBody() {
        const me = this;

        me.loadmaskHideTimer && me.clearTimeout(me.loadmaskHideTimer);
        me.loadmaskHideTimer = null;

        me.activeMask && me.activeMask.destroy();
        me.activeMask = null;
    }

    // endregion

    get isAnimating() {
        return this._animating;
    }

    set isAnimating(value) {
        const me = this;
        if (me.rendered && value !== me.isAnimating) {
            if (value) {
                me.element.classList.add('b-animating');
            }
            else {
                me.element.classList.remove('b-animating');
            }
            me._animating = value;
        }
    }
}

Grid._$name = 'Grid'; BryntumWidgetAdapterRegister.register('grid', Grid);

VersionHelper.setVersion('grid', '2.2.2');
