var productName = 'scheduler';// eslint-disable-next-line import/no-named-default
import { default as DH } from '../../Common/helper/DateHelper.js';
import ViewPreset from './ViewPreset.js';
import LocaleManager from '../../Common/localization/LocaleManager.js';
import Localizable from '../../Common/localization/Localizable.js';

//TODO: break presets out to own files

/**
 * @module Scheduler/preset/PresetManager
 */

/**
 * Provides a registry of the possible view presets that any instance of Scheduler can use.
 *
 * See the {@link Scheduler.preset.ViewPreset} and {@link Scheduler.preset.ViewPresetHeaderRow} classes for a description of the view preset properties.
 *
 * Available presets are:
 *
 * - `secondAndMinute` - creates 2 level header - minute and seconds within it:
 * {@inlineexample scheduler/viewpresets/secondAndMinute.js}
 * - `minuteAndHour` - creates 2 level header - hour and minutes within it:
 * {@inlineexample scheduler/viewpresets/minuteAndHour.js}
 * - `hourAndDay` - creates 2 level header - day and hours within it:
 * {@inlineexample scheduler/viewpresets/hourAndDay.js}
 * - `dayAndWeek` - creates 2 level header - week and days within it:
 * {@inlineexample scheduler/viewpresets/dayAndWeek.js}
 * - `weekAndDay` - just like `dayAndWeek` but with different formatting:
 * {@inlineexample scheduler/viewpresets/weekAndDay.js}
 * - `weekAndDayLetter` - creates 2 level header - with weeks and day letters within it:
 * {@inlineexample scheduler/viewpresets/weekAndDayLetter.js}
 * - `weekAndMonth` - creates 2 level header - month and weeks within it:
 * {@inlineexample scheduler/viewpresets/weekAndMonth.js}
 * - `weekDateAndMonth` - creates 2 level header - month and weeks within it (weeks shown by first day only):
 * {@inlineexample scheduler/viewpresets/weekDateAndMonth.js}
 * - `monthAndYear` - creates 2 level header - year and months within it:
 * {@inlineexample scheduler/viewpresets/monthAndYear.js}
 * - `year` - creates 2 level header - year and quarters within it:
 * {@inlineexample scheduler/viewpresets/year.js}
 * - `manyYears` - creates 2 level header - 5-years and year within it:
 * {@inlineexample scheduler/viewpresets/manyYears.js}
 *
 * You can register your own preset with the {@link #function-registerPreset-static} call or pass a preset configuration in the scheduler panel.
 * @singleton
 */
export default class PresetManager extends Localizable() {
    static get defaultPresets() {
        return {
            secondAndMinute : {
                tickWidth         : 30,   // Time column width
                tickHeight        : 40,
                displayDateFormat : 'll LTS', // Controls how dates will be displayed in tooltips etc
                shiftIncrement    : 10,     // Controls how much time to skip when calling shiftNext and shiftPrevious.
                shiftUnit         : 'minute', // Valid values are "millisecond", "second", "minute", "hour", "day", "week", "month", "quarter", "year".
                defaultSpan       : 24,    // By default, if no end date is supplied to a view it will show 24 hours
                timeResolution    : {      // Dates will be snapped to this resolution
                    unit      : 'second',  // Valid values are "millisecond", "second", "minute", "hour", "day", "week", "month", "quarter", "year".
                    increment : 5
                },
                // This defines your header, you must include a "middle" object, top/bottom are optional.
                // For each row you can define "unit", "increment", "dateFormat", "renderer", "align", and "thisObj"
                headerConfig : {
                    middle : {
                        unit       : 'second',
                        increment  : 10,
                        dateFormat : 'ss'
                    },
                    top : {
                        unit       : 'minute',
                        dateFormat : 'llll'
                    }
                }
            },
            minuteAndHour : {
                tickWidth         : 100,   // Time column width
                tickHeight        : 60,
                displayDateFormat : 'll LT', // Controls how dates will be displayed in tooltips etc
                shiftIncrement    : 1,     // Controls how much time to skip when calling shiftNext and shiftPrevious.
                shiftUnit         : 'hour', // Valid values are "MILLI", "SECOND", "minute", "HOUR", "DAY", "WEEK", "MONTH", "QUARTER", "YEAR".
                defaultSpan       : 24,    // By default, if no end date is supplied to a view it will show 24 hours
                timeResolution    : {      // Dates will be snapped to this resolution
                    unit      : 'minute',  // Valid values are "MILLI", "SECOND", "minute", "HOUR", "DAY", "WEEK", "MONTH", "QUARTER", "YEAR".
                    increment : 30
                },
                // This defines your header, you must include a "middle" object, top/bottom are optional.
                // For each row you can define "unit", "increment", "dateFormat", "renderer", "align", and "thisObj"
                headerConfig : {
                    middle : {
                        unit       : 'minute',
                        increment  : '30',
                        dateFormat : 'mm'
                    },
                    top : {
                        unit       : 'hour',
                        dateFormat : 'ddd MM/DD, hA'
                    }
                }
            },
            hourAndDay : {
                tickWidth         : 70,
                tickHeight        : 40,
                displayDateFormat : 'll LT',
                shiftIncrement    : 1,
                shiftUnit         : 'day',
                defaultSpan       : 24,
                timeResolution    : {
                    unit      : 'minute',
                    increment : 30
                },
                headerConfig : {
                    middle : {
                        unit       : 'hour',
                        dateFormat : 'LT'
                    },
                    top : {
                        unit       : 'day',
                        dateFormat : 'ddd DD/MM' //Mon 01/10
                    }
                }
            },
            dayAndWeek : {
                tickWidth         : 100,
                tickHeight        : 80,
                displayDateFormat : 'll LT',
                shiftUnit         : 'day',
                shiftIncrement    : 1,
                defaultSpan       : 5,
                timeResolution    : {
                    unit      : 'hour',
                    increment : 1
                },
                headerConfig : {
                    middle : {
                        unit       : 'day',
                        dateFormat : 'dd DD'
                    },
                    top : {
                        unit : 'week',
                        renderer(start) {
                            return DH.getShortNameOfUnit('week') + '.' + DH.format(start, 'WW MMM YYYY');
                        }
                    }
                }
            },
            weekAndDay : {
                tickWidth         : 100,
                tickHeight        : 80,
                displayDateFormat : 'll hh:mm A',
                shiftUnit         : 'week',
                shiftIncrement    : 1,
                defaultSpan       : 1,
                timeResolution    : {
                    unit      : 'day',
                    increment : 1
                },
                columnLinesFor : 'bottom',
                headerConfig   : {
                    bottom : {
                        unit       : 'day',
                        increment  : 1,
                        dateFormat : 'DD MMM'
                    },
                    middle : {
                        unit       : 'week',
                        dateFormat : 'YYYY MMMM DD' // 2017 January 01
                    }
                }
            },
            weekAndMonth : {
                tickWidth         : 100,
                tickHeight        : 105,
                displayDateFormat : 'll',
                shiftUnit         : 'week',
                shiftIncrement    : 5,
                defaultSpan       : 6,
                timeResolution    : {
                    unit      : 'day',
                    increment : 1
                },
                headerConfig : {
                    middle : {
                        unit       : 'week',
                        dateFormat : 'DD MMM'
                    },
                    top : {
                        unit       : 'month',
                        dateFormat : 'MMM YYYY' //Jan 2017
                    }
                }
            },
            monthAndYear : {
                tickWidth         : 110,
                tickHeight        : 110,
                displayDateFormat : 'll',
                shiftIncrement    : 3,
                shiftUnit         : 'month',
                defaultSpan       : 12,
                timeResolution    : {
                    unit      : 'day',
                    increment : 1
                },
                headerConfig : {
                    middle : {
                        unit       : 'month',
                        dateFormat : 'MMM YYYY' //Jan 2017
                    },
                    top : {
                        unit       : 'year',
                        dateFormat : 'YYYY' //2017
                    }
                }
            },
            year : {
                tickWidth           : 100,
                tickHeight          : 100,
                resourceColumnWidth : 100,
                displayDateFormat   : 'll',
                shiftUnit           : 'year',
                shiftIncrement      : 1,
                defaultSpan         : 1,
                timeResolution      : {
                    unit      : 'month',
                    increment : 1
                },
                headerConfig : {
                    middle : {
                        unit : 'quarter',
                        renderer(start, end, cfg) {
                            return DH.getShortNameOfUnit('quarter').toUpperCase() + (Math.floor(start.getMonth() / 3) + 1);
                        }
                    },
                    top : {
                        unit       : 'year',
                        dateFormat : 'YYYY'
                    }
                }
            },
            manyYears : {
                tickWidth         : 50,
                tickHeight        : 50,
                displayDateFormat : 'll',
                shiftUnit         : 'year',
                shiftIncrement    : 1,
                defaultSpan       : 1,
                timeResolution    : {
                    unit      : 'year',
                    increment : 1
                },
                columnLinesFor : 'bottom',
                headerConfig   : {
                    middle : {
                        unit       : 'year',
                        dateFormat : 'YYYY',
                        increment  : 5
                    },
                    // smallest zoom level looked back
                    // we have to specify increments here since 'increment' in zoomLevel affects only bottom header
                    bottom : {
                        unit       : 'year',
                        dateFormat : 'YY',
                        increment  : 1
                    }
                }
            },
            weekAndDayLetter : {
                tickWidth         : 20,
                tickHeight        : 50,
                displayDateFormat : 'll',
                shiftUnit         : 'week',
                shiftIncrement    : 1,
                defaultSpan       : 10,
                timeResolution    : {
                    unit      : 'day',
                    increment : 1
                },
                columnLinesFor : 'bottom',
                headerConfig   : {
                    bottom : {
                        unit : 'day',
                        renderer(start) {
                            return DH.format(start, 'dd').substring(0, 1);
                        },
                        verticalColumnWidth : 25
                    },
                    middle : {
                        unit                : 'week',
                        dateFormat          : 'ddd DD MMM YYYY', // Mon 01 Jan 2017
                        verticalColumnWidth : 115
                    }
                }
            },
            weekDateAndMonth : {
                tickWidth         : 30,
                tickHeight        : 40,
                displayDateFormat : 'll',
                shiftUnit         : 'week',
                shiftIncrement    : 1,
                defaultSpan       : 10,
                timeResolution    : {
                    unit      : 'day',
                    increment : 1
                },
                headerConfig : {
                    middle : {
                        unit       : 'week',
                        dateFormat : 'DD'
                    },
                    top : {
                        unit       : 'month',
                        dateFormat : 'YYYY MMMM'
                    }
                }
            },
            day : {
                displayDateFormat : 'LT',
                shiftIncrement    : 1,
                shiftUnit         : 'day',
                defaultSpan       : 1,
                timeResolution    : {
                    unit      : 'minute',
                    increment : 30
                },
                columnLinesFor : 'bottom',
                headerConfig   : {
                    bottom : {
                        unit : 'hour',
                        renderer(value) {
                            return `
                                <div class="b-sch-calendarcolumn-ct"><span class="b-sch-calendarcolumn-hours">${DH.format(value, 'HH')}</span>
                                <span class="b-sch-calendarcolumn-minutes">${DH.format(value, 'mm')}</span></div>
                            `;
                        }
                    },
                    middle : {
                        unit       : 'day',
                        dateFormat : 'ddd DD/MM', // Mon 01/02
                        splitUnit  : 'day'
                    }
                }
            },
            week : {
                displayDateFormat : 'LT',
                shiftIncrement    : 1,
                shiftUnit         : 'week',
                defaultSpan       : 24,
                timeResolution    : {
                    unit      : 'minute',
                    increment : 30
                },
                columnLinesFor : 'bottom',
                headerConfig   : {
                    bottom : {
                        unit       : 'hour',
                        dateFormat : 'LT',    // will be overridden by renderer
                        renderer(value) {
                            return `
                                <div class="sch-calendarcolumn-ct">
                                <span class="sch-calendarcolumn-hours">${DH.format(value, 'HH')}</span>
                                <span class="sch-calendarcolumn-minutes">${DH.format(value, 'mm')}</span>
                                </div>
                            `;
                        }
                    },
                    middle : {
                        unit       : 'week',
                        dateFormat : 'D d',
                        splitUnit  : 'day'
                    }
                }
            }
        };
    }

    static onLocalized() {
        const me = this;

        if (me.presets) {
            Object.values(me.presets).forEach((preset) => {
                const locale = me.L(preset.name);
                if (locale) {
                    locale.displayDateFormat && (preset.displayDateFormat = locale.displayDateFormat);
                    locale.middleDateFormat && (preset.headerConfig.middle.dateFormat = locale.middleDateFormat);
                    locale.topDateFormat && (preset.headerConfig.top.dateFormat = locale.topDateFormat);
                    locale.bottomDateFormat && (preset.headerConfig.bottom.dateFormat = locale.bottomDateFormat);
                }
            });
        }
    }

    /**
     * Registers a new view preset to be used by any scheduler grid or tree on the page.
     * @param {String} name The unique name identifying this preset
     * @param {Object} config The configuration properties of the view preset (see {@link Scheduler.preset.ViewPreset} for more information)
     */
    static registerPreset(name, config) {
        config.name = name;

        let preset = new ViewPreset(config);

        if (!this.presets) this.presets = {};

        if (preset.isValid) {
            //if (this.containsKey(name)) this.removeAtKey(name);
            //this.add(name, preset);
            this.presets[name] = preset;
        }
        else {
            throw new Error('Invalid preset, please check your configuration');
        }
    }

    /**
     * Fetches a view preset from the global cache
     * @param {String|Object} preset The preset of the preset or a preset config object
     * @return {Object} The view preset, see {@link Scheduler.preset.ViewPreset} for more information
     */
    static getPreset(preset) {
        if (typeof preset === 'string') {
            preset = this.presets[preset];
        }
        else if (!(preset instanceof ViewPreset)) {
            preset = new ViewPreset(preset);
        }
        return preset;
    }

    /**
     * Applies preset customizations or fetches a preset view preset using its name.
     * @param {String|Object} presetOrName Name of a predefined preset or a preset config object
     * @returns {Scheduler.preset.ViewPreset} Resulting ViewPreset instance
     */
    static normalizePreset(presetOrName) {
        let me     = this,
            preset = presetOrName;

        if (!(preset instanceof ViewPreset)) {
            if (typeof preset === 'string') {
                preset = me.getPreset(preset);
                if (!preset) {
                    throw new Error('You must define a valid view preset. See PresetManager for reference');
                }
            }
            else if (typeof preset === 'object') {
                let registeredPreset = preset.name && me.getPreset(preset.name);
                if (registeredPreset) {
                    // TODO: detangle this
                    preset = new ViewPreset(Object.assign(registeredPreset.config, preset));
                }
                else {
                    let name = preset.name || ('preset' + Object.keys(me.presets).length);
                    me.registerPreset(name, preset);
                    preset = me.getPreset(name);
                }
            }
            else {
                throw new Error('Invalid preset, specify a preset name or config object');
            }
        }

        return preset;
    }

    /**
     * Deletes a view preset
     * @param {String} name The name of the preset
     */
    static deletePreset(name) {
        delete this.presets[name];
        //this.removeAtKey(name);
    }

    static registerDefaults() {
        const me      = this,
            presets = me.defaultPresets;

        for (let presetName in presets) {
            me.registerPreset(presetName, presets[presetName]);
        }
    }
}

LocaleManager.on('locale', PresetManager.onLocalized.bind(PresetManager));

PresetManager.registerDefaults();
// Apply any already loaded locale
PresetManager.onLocalized();
PresetManager._$name = 'PresetManager';